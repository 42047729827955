import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate} from "react-router-dom";
import { ToolBar, Header, Footer } from './components/index';
import '../src/styles/scss/styles.scss';
import { 
    Home, 
    Login, 
    Logout, 
    EmailVerify, 
    Register, 
    Dashboard, 
    CompanyDetails,
    ContactDetails,
    BranchDetails,
    Products,
    Documents,
    ApprovalStatus,
    ForgotClientID,
    ForgotPassword,
    ResetPassword,
    Invite,
    MyList,
    PendingList,
    RequestList,
    ConnSearch,
    Employees,
    Aboutus,
    Services,
    ContactUs,
    ErfqSummary,
    ErfqAdd,
    ErfqView,
    EquoteSummary,
    EquoteView,
    Page404,
    Faq,
    ChangePassword,
    CompanyProfile,
    PoSummary,
    PoAdd,
    PoView,
    PoSellerSummary,
    PoSellerView,
    EquipmentSummary,
    Department,
    EquipmentAdd,
    AddressBook,
} from './pages/index';
import EquipmentView from './pages/Ems/EquipmentView';


const App = () => {

    const history = useNavigate();
    const [showLoader, setShowLoader] = useState(false);
    const renderLoader = () => {
        return showLoader ? <span class="loader"></span> : <></>;
    }

    return (
        <>
            {renderLoader()}
            <ToolBar/>
            <Header history={history} />
                {localStorage.getItem('accessToken', '') ? (
                    <Routes>
                        <Route path="/logout" element={<Logout />} />
                        <Route path="/employees" element={<Employees history={history}  rComp="summary"/>} />
                        <Route path="/employee-add" element={<Employees history={history}  rComp="add"/>} />
                        <Route path="/employee-edit/:id" element={<Employees history={history}  rComp="add"/>} />
                        <Route path="/profile" element={<CompanyDetails history={history} />} />
                        <Route path="/address-book" element={<AddressBook history={history} />} />
                        <Route path="/invite" element={<Invite history={history} />} />
                        <Route path="/contacts" element={<ContactDetails history={history} />} />
                        <Route path="/branches" element={<BranchDetails history={history} />} />
                        <Route path="/products" element={<Products history={history} />} />
                        <Route path="/documents" element={<Documents history={history} />} />
                        <Route path="/change-password" element={<ChangePassword history={history} />} />
                        <Route path="/approvals" element={<ApprovalStatus history={history} />} />
                        <Route path="/myconnect" element={<MyList history={history} />} />
                        <Route path="/connsearch" element={<ConnSearch history={history} />} />
                        <Route path="/requestlist" element={<RequestList history={history} />} />
                        <Route path="/erfq-summary" element={<ErfqSummary history={history} />} />
                        <Route path="/po-seller-summary" element={<PoSellerSummary history={history} />} />
                        <Route path="/po-summary" element={<PoSummary history={history} setShowLoader={setShowLoader}/>} />
                        <Route path="/po-add" element={<PoAdd history={history}  setShowLoader={setShowLoader} />} />
                        <Route path="/po-edit/:id" element={<PoAdd history={history}  setShowLoader={setShowLoader} />} />
                        <Route path="/po-view/:id" element={<PoView history={history}  setShowLoader={setShowLoader} />} />
                        <Route path="/po-seller-view/:id" element={<PoSellerView history={history}  setShowLoader={setShowLoader} />} />
                        <Route path="/company-profile/:id" element={<CompanyProfile history={history} />} />
                        <Route path="/erfq-add" element={<ErfqAdd history={history} />} />
                        <Route path="/erfq-view/:id" element={<ErfqView history={history} />} />
                        <Route path="/erfq-edit/:id" element={<ErfqAdd history={history} />} />
                        <Route path="/pendinglist" element={<PendingList history={history} />} />
                        <Route path="/equote-summary" element={<EquoteSummary history={history} />} />
                        <Route path="/equote-view/:id" element={<EquoteView history={history} />} />

                    {/* Equipment management system  */}
                        <Route path="/ems-summary" element={<EquipmentSummary history={history} />} />
                        <Route path="/ems-add" element={<EquipmentAdd   setShowLoader={setShowLoader} />} />
                        <Route path="/ems-add/:id" element={<EquipmentAdd   setShowLoader={setShowLoader} />} />
                        <Route path="/ems-view/:id" element={<EquipmentView   setShowLoader={setShowLoader} />} />


                        <Route path="/department-management" element={<Department history={history} rComp="summary"/>} />
                        <Route path="/department-management-add" element={<Department history={history} rComp="add"/>} />
                        <Route path="/department-management-add/:id" element={<Department history={history} rComp="add"/>} />


                        <Route path="/" exact element={<Dashboard history={history} />} />
                        <Route path="*" element={<Page404/>} />
                    </Routes>
                ) : (
                    <Routes>
                        <Route path="/about-us"  element={<Aboutus/>} />
                        <Route path="/services" element={<Services/>} />
                        <Route path="/contactus" element={<ContactUs/>} />
                        <Route path="/faq" element={<Faq/>} />
                        <Route path="/login" element={<Login/>} />
                        <Route path="/employees" element={<Login/>} />
                        <Route path="/profile" element={<Login/>} />
                        <Route path="/address-book" element={<Login/>} />
                        <Route path="/invite" element={<Login/>} />
                        <Route path="/contacts" element={<Login/>} />
                        <Route path="/branches" element={<Login/>} />
                        <Route path="/products" element={<Login/>} />
                        <Route path="/documents" element={<Login/>} />
                        <Route path="/change-password" element={<Login/>} />
                        <Route path="/approvals" element={<Login/>} />
                        <Route path="/myconnect" element={<Login/>} />
                        <Route path="/connsearch" element={<Login/>} />
                        <Route path="/requestlist" element={<Login/>} />
                        <Route path="/erfq-summary" element={<Login/>} />
                        <Route path="/po-summary" element={<Login/>} />
                        <Route path="/po-add" element={<Login/>} />
                        <Route path="/po-edit/:id" element={<Login/>} />
                        <Route path="/po-view/:id" element={<Login/>} />
                        <Route path="/po-seller-view/:id" element={<Login/>} />
                        <Route path="/company-profile/:id" element={<Login/>} />
                        <Route path="/erfq-add" element={<Login/>} />
                        <Route path="/erfq-view/:id" element={<Login/>} />
                        <Route path="/erfq-edit/:id" element={<Login/>} />
                        <Route path="/pendinglist" element={<Login/>} />
                        <Route path="/equote-summary" element={<Login/>} />
                        <Route path="/equote-view/:id" element={<Login/>} />
                        {/* Equipment management system */}
                        <Route path="/eqms-summary" element={<Login/>} />
                        <Route path="/ems-add" element={<EquipmentAdd   setShowLoader={setShowLoader} />} />
                        <Route path="/ems-add/:id" element={<EquipmentAdd   setShowLoader={setShowLoader} />} />
                        <Route path="/ems-view/:id" element={<EquipmentView   setShowLoader={setShowLoader} />} />


                        <Route path="/department-management" element={<Login/>}   />
                        <Route path="/department-management-add" element={<Login/>} />
                        <Route path="/department-management-add/:id" element={<Login/>} />

                        <Route path="/email-verify" element={<EmailVerify/>} />
                        <Route path="/register" element={<Register/>} />
                        <Route path="/forgot-clientid" element={<ForgotClientID/>} />
                        <Route path="/forgot-password" element={<ForgotPassword/>} />
                        <Route path="/reset-password" element={<ResetPassword/>} />
                        <Route path="/" exact element={<Home/>} />
                        <Route path="*" element={<Page404/>} />
                    </Routes>
                )}
            <Footer/>
        </>
    );
}

export default App;