const Invalid = ({message}) => {
    return (
        <section className="container">
            <div className="about-section mt-5 mb-5">
                <div className="secondary-title">
                    <h2>{message}</h2>
                </div>
            </div>
        </section>
    )
}

export default Invalid