import axios from 'axios'

const AuthTokenVerify = (history) => {

    let token = localStorage.getItem('accessToken', '');

    if(token){
      axios({
          method: 'post',
          url: "auth/token/verify/",
          data: {token: token}
      })
      .then(response => {
        //
      }).catch(e => {
        ClearToken(history);
      });
    }
}

const ClearToken = (navigate) => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userPermission');
    navigate('/');
    window.location.reload();
}

const UpdateBearer = (history, response) => {
  try{
      let bearer = response.headers.authorization.replace("Bearer", "").trim();
      if(bearer){
          localStorage.setItem('accessToken', bearer);
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken', '');
      }else{
          ErrorAction(history, response.status);
      }
  }catch(e){
      ErrorAction(history, response.status);
  }
};

const ErrorAction = (history, status) => {
  switch(status) {
      case 401:
        ClearToken(history);
        break;
  }
};


export { AuthTokenVerify, ClearToken, UpdateBearer, ErrorAction }