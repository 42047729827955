import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { AuthTokenVerify, UpdateBearer } from "../../reusable/AuthUtils";
import Notification, { errorBorder, errorText } from '../../reusable/Notification';
import { PageToTop, returnOnlyDefined } from "../../reusable/CommonUtils";
import { useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import AsyncSelect from 'react-select/async';
import ReactPaginate from 'react-paginate';
import { Link } from "react-router-dom";
import { noPermissionTemplate, isPermitted } from "../../reusable/PermissoinUtils";



const PoView = ({ history, setShowLoader }) => {
    let { id } = useParams();
    const [viewID, setViewID] = useState(id ?? false);
    const [alertMessage, setAlertMessage] = useState({});

    const fileInput = useRef([]);
    const fileInput1 = useRef(null);
    const fileInput2 = useRef(null);

    // bootsrap model for invite sellers
    const [showSeller, setShowSeller] = useState(false);
    const handleCloseSeller = () => setShowSeller(false);
    const handleShowSeller = () => setShowSeller(true);


    // bootsrap model for Extra Doc
    const [showExtraDocView, setShowExtraDocView] = useState(false);
    const handleCloseEDV = () => setShowExtraDocView(false);
    const handleShowEDV = () => setShowExtraDocView(true);

    const [refresh, setRefresh] = useState(true);

    // bootsrap model for document render
    const [fieldErrorSeller, setFieldErrorSeller] = useState({});
    const initFormSeller = {
        'erfq': id,
        'company': '',
        'advance_search': 0,
        'product': null,
        'brand': [],
        'business_type': {},
        'industry': [],
        'credential': [],
        'certificate': []
    };

    const [formDataSeller, setFormDataSeller] = useState(initFormSeller);
    const [refreshList, setrefreshList] = useState(false);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const recordsPerPage = 15;
    const [dataList, setDataList] = useState([]);
    const [dataListFetched, setDataListFetched] = useState(false);

    const [fieldErrorExtraDocRes, setFieldErrorExtraDocRes] = useState({});

    const initExtraDocResponse = {
        po_extra_is_agree: null,
        po_extra_desc: ''
    }
    const [extraDocRes, setExtraDocRes] = useState(initExtraDocResponse);

    const initExtraDocRender = {
        pk: 0,
        remarks: "",
        created_by_read: "",
        created_on: "",
        document: null,
        description: "",
        doc_type: 0,
        doc_type_read: "",
        is_accepted: "",
        is_accepted_read: "",
        approval_comments: "",
        respond_on: "",
        respond_by: "",
        created_by_company: null,
        reject_history: []
    }

    const [extraDocRender, setExtraDocRender] = useState(initExtraDocRender);
    const [extraDocRenderPk, setExtraDocRenderPk] = useState(0);

    const [termsConditions, setTermsConditions] = useState([]);
    const [termsConditionsResponse, setTermsConditionsResponse] = useState({});
    const [termsConditionsResponseErr, setTermsConditionsResponseErr] = useState({});

    const [docs, setDocs] = useState([]);
    const [docResponse, setDocResponse] = useState({});
    const [docResponseErr, setDocResponseErr] = useState({});


    const initExtraDocument = {
        remarks: '',
        document: '',
        description: '',
        doc_type: null,
    };

    const [extraForm, setExtraForm] = useState(initExtraDocument);
    const [extraFormErr, setExtraFormErr] = useState({});


    const initExtraRepostDocument = {
        document: '',
        description: ''
    };

    const [extraRepostForm, setExtraRepostForm] = useState(initExtraRepostDocument);
    const [extraRepostFormErr, setExtraRepostFormErr] = useState({});
    

    const clearSearch = () => {
        setFormDataSeller(initFormSeller);
    };

    const initItem = {
        item_code: '',
        item_qty: '',
        item_uom: '',
        description: '',
        item_price: '',
        item_hsn: 0,
        item_gst: 0,
        item_total: '',
    };

    const initDocument = {
        file_name: '',
        document: '',
    };

    const initTermsCondition = {
        terms_condition: ''
    };

    const initForm = {
        bidding_type: '',
        currency: '',
        delivery_place: '',
        qno: '',
        incoterms: '',
        subject: '',
        pr_number: '',
        pr_date: '',
        rfq_number: '',
        delivery_date: '',
        specific_end_use: '',
        requesting_dept: '',
        prepared_by: '',
        approved_by: '',
        items: [initItem],
        documents: [initDocument],
        terms_condition: [initTermsCondition],
        extra_doc: [],
        discount: 0,
        discount_type: 1,
        packing: 0,
        packing_type: 1,
        fright: 0,
        fright_type: 1,
        reference_no: '',
        auto_generate: 0,
        seller: null,
        status: 0,
        status_read: '',
        seller_company_name: '',
        seller_customer_id: '',
        addresses: [],
        company: null,
        current_company: null,
        extra_doc_category: [],
        reject_history: [],
    };


    const [formData, setFormData] = useState(initForm);

    const handleExtraForm = (e) => {
        let targetData = '';
        let name = e.target.name;
        switch (name) {
            case "document":
                targetData = e.target.files[0];
                break;
            default:
                targetData = e.target.value;
                break;
        }
        setExtraForm({...extraForm, [name]: targetData});
    }

    const handleExtraRepostForm = (e) => {
        let targetData = '';
        let name = e.target.name;
        switch (name) {
            case "document":
                targetData = e.target.files[0];
                break;
            default:
                targetData = e.target.value;
                break;
        }
        setExtraRepostForm({...extraRepostForm, [name]: targetData});
    }

    useEffect(() => {
        AuthTokenVerify(history);
        PageToTop();
    }, []);

    useEffect(() => {
        if (!refresh) return;
        setShowLoader(true);
        
        axios.get(`po/form-view/${viewID}/`).then((response) => {
            let data = response.data;

            data.discount = parseFloat(data.discount).toFixed(2);
            data.fright = parseFloat(data.fright).toFixed(2);
            data.packing = parseFloat(data.packing).toFixed(2);

            data.items.map((row, index) => {
                data.items[index]['item_price'] = parseFloat(row.item_price).toFixed(2);
            });

            data.addresses.map((row, index) => {
                if(row.address_type == 0) {
                    data.addresses['billing'] = row.address;
                } else {
                    data.addresses['delivery'] = row.address;
                }                
            });

            setFormData({ ...initForm, ...data });
            console.log(data);

            setTermsConditions(data.terms_condition);
            setDocs(data.documents);

            let termsans = {};
            data.terms_condition.map((row) => {
                termsans[row.pk] = { 'is_accepted': null, 'description': '' };
            });

            setTermsConditionsResponse(termsans);

            let docs = {};
            data.documents.map((row) => {
                docs[row.pk] = { 'is_accepted': null, 'document': null };
            });

            setDocResponse(docs);

            setShowLoader(false);
        }).catch((error) => {
            setShowLoader(false);
        });
        setRefresh(false);
    }, [refresh]);

    const renderExtraDocCat = () => {
        const content = [<option value="">-select-</option>];
        formData.extra_doc_category.map(item => {
            content.push(<option value={item.pk}>{item.meta_value}</option>);
        });
        return content;
    };


    const submitExtraRepostDoc = () => {
        setExtraRepostFormErr({});
        let fData = new FormData();
        let inData = {
            'type': 'repost',
            'document': extraRepostForm.document ?? '',
            'description': extraRepostForm.description
        }

        Object.entries(inData).forEach(([k,v]) => {
            fData.append(k, v);
        });

        axios.patch(`po/form-extradoc-view/${extraDocRenderPk}/`, fData).then((response) => {
            renderExtraDocView(extraDocRenderPk);
            setExtraRepostForm(initExtraRepostDocument);
            if(fileInput2.current){
                fileInput2.current.value = '';
            }
        }).catch((e) => {
            if (e.response.status === 400) {
                setExtraRepostFormErr(e.response.data);
            }
        });
    };

    const submitExtraDoc = () => {
       
        setExtraFormErr({});
        setShowLoader(true);
        setAlertMessage({});
        let fData = new FormData();
        let inData = {
            'type': 'extra',
            'remarks': extraForm.remarks,
            'document': extraForm.document ?? '',
            'doc_type': extraForm.doc_type,
            'description': extraForm.description
        }

        Object.entries(inData).forEach(([k,v]) => {
            fData.append(k, v);
        });

        axios.patch(`po/form-view/${id}/`, fData).then((response) => {
            setAlertMessage({ type: 'success', message: response.data.message });
            setShowLoader(false);
            UpdateBearer(history, response);
            setRefresh(true);
            PageToTop();
            setExtraForm(initExtraDocument);
            if(fileInput1.current){
                fileInput1.current.value = '';
            }
        }).catch((e) => {
            UpdateBearer(history, e.response);
            if (e.response.status === 404) {
                history('/po-summary');
            }
            if (e.response.status === 400) {
                setExtraFormErr(e.response.data);
            }
            setShowLoader(false);
        });
    };
    

    const renderAddressContent = (address) => {
        if(address === undefined) {
            return <span>-</span>;
        }
        return (
            <div className="App">
              <div styles={{ textAlign: "left" }}>
                {address.split(",").map((substring, idx) => {
                  return (
                    <div key={idx}>
                      <span>{substring}</span>
                      <br />
                    </div>
                  );
                })}
              </div>
            </div>
          );
    }

    const renderExtraDocuments = () => {
        if (formData.mutual_agree) {
            return <>
            <div className="row">
                <label className="subtitle">Extra Documents</label>
                <div className="form-panel">
                        <div className="row row-cols-1 row-cols-lg-2 mb-3 form-elements">
                            <div className="col-12 form-column form-address-panel">
                                <div className="row mb-2 row-cols-1 row-cols-lg-3 single-col">
                                    <div className="col">
                                        <label className="form-label">Document Type  </label>
                                        {/* <textarea className="form-control" name="remarks" onChange={handleExtraForm} maxLength="500" value={extraForm.remarks} cols="4"></textarea> */}
                                        <select className={"form-select " + errorBorder(extraFormErr.remarks) } name="remarks" value={extraForm.remarks} onChange={handleExtraForm}>
                                            { renderExtraDocCat() }
                                        </select>
                                        { errorText(returnOnlyDefined(extraFormErr, 'object.remarks')) }
                                    </div>
                                    <div className="col">
                                        <label className="form-label">Description </label>
                                        <textarea className="form-control" name="description" onChange={handleExtraForm} maxLength="500" value={extraForm.description} cols="4"></textarea>
                                        { errorText(returnOnlyDefined(extraFormErr, 'object.description')) }
                                    </div>
                                    <div className="col">
                                        <label className="form-label">Documents </label>
                                        <input type="file" className="form-control" onChange={handleExtraForm} name="document" ref={(element) => {fileInput1.current = element}}/>
                                            { errorText(returnOnlyDefined(extraFormErr, 'object.document')) }
                                    </div>
                                    <div className="col py-4">
                                        <div className="form-check form-check-inline">
                                            <label className="form-check-label text-success">
                                                <input className="form-check-input" value={0} type="radio" checked={extraForm.doc_type==0} onChange={handleExtraForm} name='doc_type' />
                                                <b>Reference</b>
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <label className="form-check-label text-danger">
                                                <input className="form-check-input" value={1} type="radio" checked={extraForm.doc_type==1} onChange={handleExtraForm} name='doc_type' />
                                                <b>Approval</b>
                                            </label>
                                        </div>
                                            { errorText(returnOnlyDefined(extraFormErr, 'object.doc_type')) }
                                    </div>
                                    <div className="col p-3">
                                        <button type="button" className="btn btn-success" onClick={submitExtraDoc}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>      
                </div>
                <div className="table-responsive row">
                    <div className="p-2">
                        <table className="table align-middle table-design inai-table table-striped table-hover table-borderless shadow mb-3 bg-white rounded">
                            <thead>
                                <tr>
                                    <td>Document Type</td>
                                    <td>Description</td>
                                    <td>Document</td>
                                    <td>For</td>
                                    <td>Posted By</td>
                                    <td>Posted On</td>
                                    <td>Action</td>
                                </tr>
                            </thead>
                            <tbody>
                                {(() => {
                                    let content = []
                                    formData.extra_doc.map((item, index) => {
                                        content.push(<tr>
                                            <td>{item.remarks_read}</td>
                                            <td>{item.description}</td>
                                            <td>
                                                { item.document ?
                                                <i onClick={() => downloadPoDocument(item.pk, '?type=extra')} className="btn btn-primary bi bi-cloud-download-fill cursor-pointer"></i>
                                                : '-'
                                                }
                                            </td>
                                            <td>{item.doc_type_read}</td>
                                            <td>{item.created_by_read}</td>
                                            <td>{item.created_on}</td>
                                            <td><i onClick={() => renderExtraDocView(item.pk)} className="btn btn-success bi bi-eye-fill cursor-pointer"></i></td>
                                        </tr>);
                                    })

                                    if (content.length < 1) {
                                        content.push(
                                            <tr className="text-center"><td colSpan={12}><i>No records found</i></td></tr>
                                        );
                                    }

                                    return content;
                                })()}
                                
                            </tbody>
                        </table>
                    </div>
                </div>
                
            </>
        }
    }



    const termsResponse = (terms_pk) => {

        setTermsConditionsResponseErr({});
        axios.patch(`po/form-view/${viewID}/`, {
            'type': 'terms_response',
            'terms_pk': terms_pk,
            'condition_response': termsConditionsResponse[terms_pk]
        }).then((response) => {
            setAlertMessage({ type: 'success', message: response.data.message });
            UpdateBearer(history, response);
            setRefresh(true);
            PageToTop();
        }).catch((e) => {
            UpdateBearer(history, e.response);
            if (e.response.status === 404) {
                history('/po-summary');
            }
            if (e.response.status === 400) {
                setTermsConditionsResponseErr(e.response.data);
            }
            PageToTop();
        });
    };


    const docResponseSub = (doc_pk) => {

        setTermsConditionsResponseErr({});
        setDocResponseErr({});
        let fData = new FormData();
        let inData = {
            'type': 'doc_response',
            'doc_pk': doc_pk,
            'doc_response': docResponse[doc_pk].is_accepted,
            'doc': docResponse[doc_pk].document ?? '',
        }

        Object.entries(inData).forEach(([k,v]) => {
            fData.append(k, v);
        });

        axios.patch(`po/form-view/${viewID}/`, fData).then((response) => {
            setAlertMessage({ type: 'success', message: response.data.message });
            UpdateBearer(history, response);
            setRefresh(true);
            PageToTop();
        }).catch((e) => {
            UpdateBearer(history, e.response);
            if (e.response.status === 404) {
                history('/po-summary');
            }
            if (e.response.status === 400) {
                setDocResponseErr(e.response.data);
            }
            PageToTop();
        });
    };



    useEffect(() => {
        if (!refreshList) return;
        axios.get("po/form-invite-supplier/", {
            params: {
                page: page,
                company: formDataSeller.company,
                advance_search: formDataSeller.advance_search ? 1 : 0,
                product: JSON.stringify(formDataSeller.product),
                brand: JSON.stringify(formDataSeller.brand),
                business_type: JSON.stringify(formDataSeller.business_type),
                industry: JSON.stringify(formDataSeller.industry),
                credential: JSON.stringify(formDataSeller.credential),
                certificate: JSON.stringify(formDataSeller.certificate)
            }
        }).then((response) => {
            setDataList(response.data.results);
            setPageCount(Math.ceil(response.data.count / recordsPerPage));
            UpdateBearer(history, response);
            setDataListFetched(true);
        }).catch((e) => {
                if (e.response.status === 400) {
                    setAlertMessage({ type: 'danger', message: "Error Occured While Submit!" });
                    const dict = {};
                    for (const [key, value] of Object.entries(e.response.data)) {
                        dict[key] = value.join(', ');
                    }
                    setFieldErrorSeller(dict);
                } else if (e.response.status === 404) {
                    setPage(1);
                    setrefreshList(true);
                }
                else if (e.response.status === 403) {
                    //permission denied
                    history('/');
                }
            });
        setrefreshList(false);
    }, [refreshList]);



    const handlePageClick = (data) => {
        setPage(data.selected + 1);
        setrefreshList(true);
    };

    const handleTermsCondtionResponse = (e, name, pk) => {
        let temp = termsConditionsResponse[pk];
        temp[name] = e.target.value;
        setTermsConditionsResponse({ ...termsConditionsResponse, [pk]: temp });
    };

    const handlePoExtraRes = (e) => {
        let data = e.target.value;
        if (e.target.name == 'po_extra_is_agree') {
            data = parseInt(data);
        }
        setExtraDocRes({...extraDocRes, [e.target.name]: data})
    }

    const handlePoExtraResSub = () => {
        setFieldErrorExtraDocRes({});
        axios.patch(`po/form-extradoc-view/${extraDocRenderPk}/`, {
            type: 'response',
            is_accepted: extraDocRes.po_extra_is_agree,
            description: extraDocRes.po_extra_desc
        }).then((response) => {
            renderExtraDocView(extraDocRenderPk);
            setExtraDocRes(initExtraDocResponse);
        }).catch((e) => {
            if (e.response.status === 400) {
                setFieldErrorExtraDocRes(e.response.data);
            }
        });
    }

    const showPagination = () => {
        if (pageCount <= 1) return;
        return <nav>
            <ReactPaginate
                pageClassName={'page-item'}
                previousClassName={'page-item active'}
                nextClassName={'page-item active'}
                pageLinkClassName={'page-link'}
                previousLinkClassName={'page-link'}
                nextLinkClassName={'page-link'}
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageCount}
                forcePage={page - 1}
                pageRangeDisplayed={recordsPerPage}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                activeClassName={'active'}
            />
        </nav>;
    };

    const InvitePo = () => {
        axios.post(`po/form-view/${viewID}/`, {
            type: 'invite'
        }).then((response) => {
            setAlertMessage({ type: 'success', message: response.data.message });
            setRefresh(true);
            setFormData({ ...formData, ...response.data.status });
            PageToTop();
        }).catch((e) => {
            setAlertMessage({ type: 'danger', message: e.response.data.message });
            UpdateBearer(history, e.response);
            PageToTop();
        });
    };

    const sendRequest = (key) => {

        setAlertMessage({});

        const newDataList = [...dataList];

        axios.post("po/form-invite-supplier/", {
            company: newDataList[key].pk,
            po: id
        }).then((response) => {
            setDataList(newDataList);
            UpdateBearer(history, response);
            setFormData({ ...formData, ...response.data.seller });
            handleCloseSeller();
        }).catch((e) => {
            setAlertMessage({ type: 'danger', message: e.response.data.message });
            UpdateBearer(history, e.response);
            handleCloseSeller();
            PageToTop();
        });
    };

    const sellerSearchList = () => {
        const content = [];

        if (dataListFetched && dataList.length < 1) {
            content.push(
                <a className="list-group-item list-group-item-action flex-column align-items-start">
                    <h5 className="mb-1 text-center">No Records Found</h5>
                </a>
            );
            return content;
        }

        dataList.map((item, key) => {
            let button = <span>
                <button
                    type="button"
                    onClick={() => { sendRequest(key) }}
                    className="btn btn-primary "
                >Request</button>
            </span>;

            if (item.requested) {
                button = <span>
                    <button
                        type="button"
                        className="btn btn-warning "
                    >Requested</button>
                </span>;
            }

            content.push(
                <a className="list-group-item list-group-item-action flex-column align-items-start">
                    <div className="d-flex w-100 justify-content-between">
                        <Link to={"/company-profile/" + item.customer_id} className="mb-1"><b><span className="badge  bg-primary">{item.customer_id}</span></b> &nbsp;{item.company_name}</Link>
                        {button}
                    </div>
                </a>
            );
        });

        return content;
    };

    const productOptions = (inputValue) => {
        return new Promise(resolve => {
            axios.get("products/product-search/?q=" + inputValue).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                //
            });
        });
    };

    const brandsOptions = (inputValue) => {
        let data = formDataSeller.brand.map(brands => { if (!brands.__isNew__) return brands.value }).filter(e => e).join(',');
        return new Promise(resolve => {
            axios.get("products/brands-search/?q=" + inputValue + "&skip=" + data).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                //
            });
        });
    };

    const industriesOptions = (inputValue) => {
        let data = formDataSeller.industry.map(industries => { if (!industries.__isNew__) return industries.value }).filter(e => e).join(',');
        return new Promise(resolve => {
            axios.get("products/industries-search/?q=" + inputValue + "&skip=" + data).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                //
            });
        });
    };

    const credentialsOptions = (inputValue) => {
        let data = formDataSeller.credential.map(credentials => { if (!credentials.__isNew__) return credentials.value }).filter(e => e).join(',');
        return new Promise(resolve => {
            axios.get("products/credentials-search/?q=" + inputValue + "&skip=" + data).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                //
            });
        });
    };

    const certificatesOptions = (inputValue) => {
        let data = formDataSeller.certificate.map(certificates => { if (!certificates.__isNew__) return certificates.value }).filter(e => e).join(',');
        return new Promise(resolve => {
            axios.get("products/certificates-search/?q=" + inputValue + "&skip=" + data).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                //
            });
        });
    };

    const handleChangeSeller = (newValue, actionMeta) => {
        if (typeof (actionMeta) !== 'undefined') {
            setFormDataSeller({
                ...formDataSeller,
                [actionMeta.name]: newValue
            });
        } else {
            let targetData = '';
            switch (newValue.target.name) {
                case "advance_search":
                    targetData = newValue.target.checked;
                    break;
                case "business_type":
                    targetData = {
                        ...formDataSeller.business_type,
                        [newValue.target.value]: newValue.target.checked
                    };
                    break;
                default:
                    targetData = newValue.target.value;
                    break;
            }
            setFormDataSeller({
                ...formDataSeller,
                [newValue.target.name]: targetData
            });
        }
    };

    const onSellerSearch = (e) => {
        e.preventDefault();
        setFieldErrorSeller({});
        setAlertMessage({});
        setDataList([]);
        setrefreshList(true);
        setPageCount(0);
        setDataListFetched(false);
    };


    const handleTermsConditionChange = (e, index) => {
        var targetData = '';

        switch (e.target.name) {
            default:
                targetData = e.target.value;
                break;
        }

        var terms_condition = formData.terms_condition;
        terms_condition[index][e.target.name] = targetData;
        setFormData({ ...formData, ['terms_condition']: terms_condition });
    };

    const renderItems = () => {
        const content = [];

        let inc = 1;
        formData.items.map((item, index) => {
            content.push(<tr index={index} >
                <td>{(index + 1)}</td>
                <td>{item.item_code}</td>
                <td>{item.item_qty}</td>
                <td>{item.item_uom_read}</td>
                <td style={{whiteSpace: "pre-wrap"}}>{item.description}</td>
                <td className="input-small">
                    <input type="number" className="form-control" name="item_hsn" value={item.item_hsn} disabled />
                </td>
                <td className="input-small">
                    <input type="number" className="form-control" name="item_gst" value={item.item_gst} disabled />
                </td>
                <td className="input-small">
                    <input type="number" className="form-control" name="item_price" value={item.item_price} disabled />
                </td>
                <td>{Number(item.item_total).toFixed(2)}</td>
            </tr>);
            inc++;
        });

        content.push(<tr>
            <td colSpan="8" className="text-end">Total :</td>
            <td>{Number(formData.total).toFixed(2)}</td>
        </tr>);

        content.push(<tr>
            <td colSpan="8" className="text-end">Packing : &nbsp;&nbsp;&nbsp;
                <label className="cursor-pointer">% <input type="radio" value={1} checked={formData.packing_type == 1} name="packing_type" disabled /></label> &nbsp;&nbsp;&nbsp;
                <label className="cursor-pointer">fixed <input type="radio" value={2} checked={formData.packing_type == 2} name="packing_type" disabled /></label>
            </td>
            <td>
                <input type="number" className="form-control input-small" name="packing" value={formData.packing >= 0 ? formData.packing : ''} disabled />

            </td>
        </tr>);

        content.push(<tr>
            <td colSpan="8" className="text-end">Freight : &nbsp;&nbsp;&nbsp;
                <label className="cursor-pointer">% <input type="radio" value={1} checked={formData.fright_type == 1} name="fright_type" disabled /></label> &nbsp;&nbsp;&nbsp;
                <label className="cursor-pointer">fixed <input type="radio" value={2} checked={formData.fright_type == 2} name="fright_type" disabled /></label>
            </td>
            <td>
                <input type="number" className="form-control input-small" name="fright" value={formData.fright >= 0 ? formData.fright : ''} disabled />
            </td>
        </tr>);

        content.push(<tr>
            <td colSpan="8" className="text-end">Discount : &nbsp;&nbsp;&nbsp;
                <label className="cursor-pointer">% <input type="radio" value={1} checked={formData.discount_type == 1} name="discount_type" disabled /></label> &nbsp;&nbsp;&nbsp;
                <label className="cursor-pointer">fixed <input type="radio" value={2} checked={formData.discount_type == 2} name="discount_type" disabled /></label>
            </td>
            <td>
                <input type="number" className="form-control input-small" name="discount" value={formData.discount >= 0 ? formData.discount : ''} disabled />
            </td>
        </tr>);

        content.push(<tr>
            <td colSpan="8" className="text-end">Net Total :</td>
            <td>{Number(formData.net_total).toFixed(2)}</td>
        </tr>);

        return content;
    };

    const handleDocResponse = (e, name, pk) => {

        var targetData = '';

        switch (name) {
            case "document":
                targetData = e.target.files[0];
                break;
            default:
                targetData = e.target.value;
                break;
        }

        let temp = docResponse[pk];
        temp[name] = targetData;
        setDocResponse({ ...docResponse, [pk]: temp });
    };


    const renderExtraDocView = (key) => {
        setExtraDocRender(initExtraDocRender);
        setExtraDocRenderPk(key);
        handleShowEDV();
        setShowLoader(true);
        axios.get(`po/form-extradoc-view/${key}/`).then((response) => {
            setExtraDocRender(response.data);
            setShowLoader(false);
        }).catch((e) => {
            setShowLoader(false);
        });
    };

    const downloadPoDocument = (key, param='') => {
        axios({
            url: "po/document-download/" + key  + "/" + param,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            const filename = response.headers.filename;
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });
    };

    const renderDocuments = () => {
        const content = [];
        docs.map((row, index) => {
            let responded_by;
            let historyOccures = false;
            content.push(<div className="accordion-item">
                <h2 className="accordion-header" id={"docs" + index}>
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#docscollapse" + index} aria-expanded="true" aria-controls={"#collapse" + index}>
                    {row.file_name} <i onClick={() => downloadPoDocument(row.pk)} className="btn btn-primary bi bi-cloud-download-fill cursor-pointer ms-3"></i>
                    </button>
                </h2>
                <div id={"docscollapse" + index} class="accordion-collapse collapse show" aria-labelledby={"docs" + index}>
                    <div className="accordion-body">
                        <div className="table-responsive row">
                            <div className="p-2">
                                <table className="table align-middle table-design inai-table table-striped table-hover table-borderless shadow mb-3 bg-white rounded">
                            
                                    <tbody>
                                        {(() => {
                                            let contents = [];
                                            row.response.map((crow, cindex) => {
                                                responded_by = crow.responded_by;
                                                historyOccures = true;
                                                contents.push(
                                                    <tr>
                                                        <td className="text-left">{
                                                            crow.is_accepted ? 
                                                            <label className="form-check-label text-success fw-bold">Agreed</label>
                                                            :
                                                            <label className="form-check-label text-danger fw-bold">Disagreed</label>
                                                        } : {crow.created_by_read} {crow.created_on}</td>
                                                        <td>
                                                            {
                                                                crow.document ?
                                                                <i onClick={() => downloadPoDocument(crow.pk, '?type=res')} className="btn btn-primary bi bi-cloud-download-fill cursor-pointer ms-3"></i>
                                                                :
                                                                'NA'
                                                            }
                                                        </td>
                                                    </tr>
                                                );
                                            });

                                            return contents;
                                        })()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {(() => {
                            if(!row.is_accepted && responded_by==1) {
                                let termsSubmit;

                                if(historyOccures) {
                                    termsSubmit = <div className="row mt-2">
                                        <div className="col-md-12 text-center">
                                            <button type="button" onClick={(e) => {docResponseSub(row.pk)}} className="btn btn-success">Submit</button>
                                        </div>
                                    </div>
                                }

                                return (
                                    <>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-check form-check-inline ">
                                                <label className="form-check-label text-success">
                                                    <input className="form-check-input"  checked={docResponse[row.pk] && docResponse[row.pk].is_accepted == 1} value={1} type="radio" onClick={(e) => handleDocResponse(e, 'is_accepted', row.pk)} name={'is_accepted_doc' + index}/>
                                                    <b>Agree</b>
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline ">
                                                <label className="form-check-label text-danger">
                                                    <input className="form-check-input" checked={docResponse[row.pk] && docResponse[row.pk].is_accepted == 0} value={0} type="radio" onClick={(e) => handleDocResponse(e, 'is_accepted', row.pk)} name={'is_accepted_doc' + index}/>
                                                    <b>Disagree</b>
                                                </label>
                                            </div>
                                            <br />{errorText(returnOnlyDefined(docResponseErr, 'object[' + row.pk + '].is_accepted.join(",")'))}
                                        </div>
                                        <div className="col-md-6">
                                            <input type="file" className="form-control" name="document" ref={(element) => {fileInput.current[row.pk] = element}} onChange={(e) => handleDocResponse(e, 'document', row.pk)}  />
                                            { errorText(returnOnlyDefined(docResponseErr, 'object[' + row.pk + '].document.join(",")')) }
                                        </div>
                                    </div>
                                    {termsSubmit}
                                    </>
                                )
                            }

                            return null;

                        })()}                
                    </div>
                </div>
            </div>)
        });

        return content;
    };


    const getTermsList = () => {
        const content = [];
        termsConditions.map((row, index) => {
            let responded_by;
            content.push(<div className="accordion-item">
                <h2 className="accordion-header" id={"terms" + index}>
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + index} aria-expanded="true" aria-controls={"#collapse" + index}>
                        {row.terms_condition}
                    </button>
                </h2>
                <div id={"collapse" + index} class="accordion-collapse collapse show" aria-labelledby={"terms" + index}>
                    <div className="accordion-body">
                        <div className="table-responsive row">
                            <div className="p-2">
                                <table className="table align-middle table-design inai-table table-striped table-hover table-borderless shadow mb-3 bg-white rounded">
                                    <tbody>
                                        {(() => {
                                            let contents = [];
                                            row.response.map((crow, cindex) => {
                                                responded_by = crow.responded_by;
                                                contents.push(
                                                    <tr>
                                                        <td className="text-left">{
                                                            crow.is_accepted ? 
                                                            <label className="form-check-label text-success fw-bold">Agreed</label>
                                                            :
                                                            <label className="form-check-label text-danger fw-bold">Disagreed</label>
                                                        } : {crow.created_by_read} {crow.created_on}</td>
                                                        <td>{crow.description}</td>
                                                    </tr>
                                                );
                                            });

                                            return contents;
                                        })()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {(() => {
                            if(!row.is_accepted && responded_by==1) {
                                return (
                                    <>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-check form-check-inline ">
                                                <label className="form-check-label text-success">
                                                    <input className="form-check-input"  checked={termsConditionsResponse[row.pk] && termsConditionsResponse[row.pk].is_accepted == 1} value={1} type="radio" onClick={(e) => handleTermsCondtionResponse(e, 'is_accepted', row.pk)} name={'is_accepted_' + index}/>
                                                    <b>Agree</b>
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline ">
                                                <label className="form-check-label text-danger">
                                                    <input className="form-check-input" checked={termsConditionsResponse[row.pk] && termsConditionsResponse[row.pk].is_accepted == 0} value={0} type="radio" onClick={(e) => handleTermsCondtionResponse(e, 'is_accepted', row.pk)} name={'is_accepted_' + index}/>
                                                    <b>Disagree</b>
                                                </label>
                                            </div>
                                            <br />{errorText(returnOnlyDefined(termsConditionsResponseErr, 'object[' + row.pk + '].is_accepted.join(",")'))}
                                        </div>
                                        <div className="col-md-6">
                                            <textarea className="form-control" placeholder="Remarks" name="description" cols="4" maxLength="500" value={row.description} onChange={(e) => handleTermsCondtionResponse(e, 'description', row.pk)}></textarea>
                                            {errorText(returnOnlyDefined(termsConditionsResponseErr, 'object[' + row.pk + '].description.join(",")'))}
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-12 text-center">
                                            <button type="button" onClick={(e) => {termsResponse(row.pk)}} className="btn btn-success">Submit</button>
                                        </div>
                                    </div>
                                    </>
                                )
                            }

                            return null;

                        })()}                
                        
                    </div>
                </div>
            </div>);
        });

        return content;
    };


    return (
        <div className="container">


            <Modal dialogClassName="modal-80" show={showExtraDocView} onHide={handleCloseEDV}>
                <Modal.Body>
                <div className="form-wrapper m-0">
                    <div className="card">
                        <div className="card-header">
                            {formData.reference_no} {formData.pr_number} {formData.rfq_number} {formData.subject}

                            {(() => {
                                if(isPermitted('site_purchase_order_modify') && formData.status==0){
                                    return <Link to={`/po-edit/${viewID}`} className="btn btn-primary float-end">
                                        <i className="bi bi-pencil-fill cursor-pointer"></i>
                                    </Link>
                                }
                            })()}
                        </div>
                        <div className="card-body form-panel-wrapper">
                            <div className="row inai-info-background">
                                <div className="col-md-4 inai-border-right ps-md-0">
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <label className="form-label"><b>Document Type : </b>{extraDocRender.remarks_read}</label>
                                        </div>
                                        <div className="col-12">
                                            <label className="form-label"><b>Description : </b>{extraDocRender.description}</label>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-4 inai-border-right ps-md-5">
                                        <div className="col-12">
                                            <label className="form-label"><b>Posted By : </b>{extraDocRender.created_by_read}</label>
                                        </div>
                                        <div className="col-12">
                                            <label className="form-label"><b>Posted On : </b>{extraDocRender.created_on}</label>
                                        </div>
                                        <div className="col-12">
                                        <label className="form-label"><b>Document : </b>
                                            { extraDocRender.document ?
                                                <i onClick={() => downloadPoDocument(extraDocRender.pk, '?type=extra')} className="btn btn-primary bi bi-cloud-download-fill cursor-pointer ms-3"></i>
                                                : '-'
                                            }
                                        </label>
                                        </div>
                                </div>
                                <div className="col-md-4 ps-md-5 pe-md-0">
                                        <div className="col-12">
                                            <label className="form-label"><b>For : </b>{extraDocRender.doc_type_read}</label>
                                        </div>
                                        <div className="col-12">
                                            <label className="form-label"><b>Acceptance Status : </b>{extraDocRender.is_accepted_read}</label>
                                        </div>
                                        <div className="col-12">
                                            <label className="form-label"><b>Acceptance Comments : </b>{extraDocRender.approval_comments}</label>
                                        </div>
                                </div>
                            </div>
                            {(() => {
                                if(extraDocRender.is_accepted == 0 && extraDocRender.created_by_company == formData.current_company) {
                                    return <div className="form-panel mt-4">
                                        <div className="row row-cols-1 row-cols-lg-2 mb-3 form-elements">
                                            <div className="col-12 form-column form-address-panel">
                                                <div className="row mb-2 row-cols-1 row-cols-lg-3 single-col">
                                                    
                                                    <div className="col">
                                                        <label className="form-label">Description </label>
                                                        <textarea className="form-control" name="description" onChange={handleExtraRepostForm} maxLength="500" value={extraRepostForm.description} cols="4"></textarea>
                                                        { errorText(returnOnlyDefined(extraRepostFormErr, 'object.description')) }
                                                    </div>
                                                    <div className="col">
                                                        <label className="form-label">Documents </label>
                                                        <input type="file" className="form-control" onChange={handleExtraRepostForm} name="document" ref={(element) => {fileInput2.current = element}}/>
                                                            { errorText(returnOnlyDefined(extraRepostFormErr, 'object.document')) }
                                                    </div>
                                                    <div className="col p-3 mt-3">
                                                        <button type="button" className="btn btn-success" onClick={submitExtraRepostDoc}>Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>     
                                }
                            })()}
                            {(() => {
                                if (extraDocRender.doc_type == 1 && extraDocRender.is_accepted == null && extraDocRender.created_by_company != formData.current_company) {
                                    return  <div className="row inai-info-background mt-4">
                                        <div className="col-md-2">
                                            <div className="form-check form-check-inline ">
                                                <label className="form-check-label text-success">
                                                    <input className="form-check-input" value={1} name="po_extra_is_agree" onClick={handlePoExtraRes} checked={extraDocRes.po_extra_is_agree == 1} type="radio" />
                                                    <b>Agree</b>
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline ">
                                                <label className="form-check-label text-danger">
                                                    <input className="form-check-input" value={0} name="po_extra_is_agree" onClick={handlePoExtraRes} checked={extraDocRes.po_extra_is_agree == 0} type="radio"/>
                                                    <b>Disagree</b>
                                                </label>
                                            </div>
                                            <br />{errorText(returnOnlyDefined(fieldErrorExtraDocRes, 'object.is_accepted.join(",")'))}
                                        </div>
                                        <div className="col-md-4">
                                            <textarea className="form-control" placeholder="Remarks" name="po_extra_desc" onChange={handlePoExtraRes} value={extraDocRes.po_extra_desc} cols="4" maxLength="500" ></textarea>
                                            {errorText(returnOnlyDefined(fieldErrorExtraDocRes, 'object.approval_comments.join(",")'))}
                                        </div>
                                        <div className="col-md-4">
                                            <button type="button" onClick={handlePoExtraResSub} className="btn btn-success mt-2">Submit</button>
                                        </div>
                                    </div>
                                }
                            })()}
                            {/* <div className="table-responsive row">
                            <label className="subtitle">reply </label>
                            <div className="p-2">
                                <table className="table align-middle table-design inai-table table-striped table-hover table-borderless shadow mb-3 bg-white rounded">
                                    <thead>
                                        <tr>
                                            <td>Remarks</td>
                                            <td>Description</td>
                                            <td>Action</td>
                                            <td>Documents</td>
                                            <td></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                            
                                {(() => {
                                    let content = []
                                    formData.extra_doc.map((item, index) => {
                                        content.push(<tr>
                                            <td> <textarea className="form-control" name="remarks" onChange={handleExtraForm} maxLength="500" value={extraForm.remarks}></textarea>
                                        { errorText(returnOnlyDefined(extraFormErr, 'object.remarks')) }</td>
                                            <td> <textarea className="form-control" name="description" onChange={handleExtraForm} maxLength="500" value={extraForm.description}></textarea>
                                        { errorText(returnOnlyDefined(extraFormErr, 'object.description')) }</td>
                                        <td className="text-left">
                                            <div className="form-check form-check-inline ">
                                                 <label className="form-check-label text-success">
                                                     <input className="form-check-input" type="radio"/>
                                                     <b>Agree</b>
                                                 </label>
                                            </div>
                                            <div className="form-check form-check-inline ">
                                                <label className="form-check-label text-danger">
                                                    <input className="form-check-input" type="radio"/>
                                                    <b>Disagree</b>
                                                 </label>
                                            </div>
                        
                                         </td>
                                         <td>
                                         <label className="form-label">Documents </label>
                                                <input type="file" className="form-control" onChange={handleExtraForm} name="document" ref={(element) => {fileInput1.current = element}}/>
                                                    { errorText(returnOnlyDefined(extraFormErr, 'object.document')) }
                                         </td>
                                         <td><button type="button" className="btn btn-success" onClick={submitExtraDoc}>Submit</button></td>
                                          
                                        </tr>);
                                    })
                                    return content;
                                })()}
                                    </tbody>
                                </table>
                            </div>
                            </div> */}
                            <div className="table-responsive row">
                            <label className="subtitle">History</label>
                                <div className="p-2">
                                    <table className="table align-middle table-design inai-table table-striped table-hover table-borderless shadow mb-3 bg-white rounded">
                                        <thead>
                                            <tr>
                                                <td>Remarks</td>
                                                <td>Description</td>
                                                <td>Document</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                                {(() => {
                                                            let content = []
                                                            extraDocRender.reject_history.map((item, index) => {
                                                                content.push(<tr>
                                                                <td>{item.ed_approval_comments}</td>
                                                                <td>{item.ed_description}</td>
                                                                <td>
                                                                { item.ed_document ?
                                                                    <i onClick={() => downloadPoDocument(item.pk, '?type=extra_history')} className="btn btn-primary bi bi-cloud-download-fill cursor-pointer"></i>
                                                                    : '-'
                                                                }
                                                                </td>
                                                                </tr>);
                                                            })

                                                            if (content.length < 1) {
                                                                content.push(
                                                                    <tr className="text-center"><td colSpan={12}><i>No records found</i></td></tr>
                                                                );
                                                            }

                                                            return content;
                                                })()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                           
                        </div>
                        
                    </div>
                </div>
                </Modal.Body>
            </Modal>


            <Modal dialogClassName="modal-80" show={showSeller} onHide={handleCloseSeller}>
                <Modal.Body>
                    <form onSubmit={onSellerSearch}>
                        <div className="row mb-4 row-cols-1 row-cols-lg-3">
                            <div className="col"></div>
                            <div className="col">
                                <label className="form-label">Company Name / Company ID</label>
                                <input type="text" className={"form-control " + errorBorder(fieldErrorSeller.company)} name="company" onChange={handleChangeSeller} value={formDataSeller.company} />
                                {errorText(fieldErrorSeller.company)}
                            </div>
                            <div className="col"></div>
                        </div>
                        <div className="row mb-4 row-cols-1 row-cols-lg-3">
                            <div className="col">
                                <div className="form-group form-check">
                                    <input type="checkbox" checked={formDataSeller.advance_search} className="form-check-input" id="advance_search" name="advance_search" onClick={handleChangeSeller} />
                                    <label className="form-check-label" for="advance_search"><b>Advance Search</b></label>
                                </div>
                            </div>
                        </div>
                        {(() => {
                            if (formDataSeller.advance_search) {
                                return (
                                    <>
                                        <div className="row mb-4 row-cols-1 row-cols-lg-3">
                                            <div className="col">
                                                <label className="form-label">Product Name <sup className="text-danger">*</sup></label>
                                                <AsyncSelect
                                                    onChange={handleChangeSeller}
                                                    loadOptions={productOptions}
                                                    name="product"
                                                    isClearable={true}
                                                    value={formDataSeller.product}
                                                    className={errorBorder(fieldErrorSeller.product)}
                                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                />
                                                {errorText(fieldErrorSeller.product)}
                                            </div>
                                            <div className="col">
                                                <label className="form-label">Brand / Make</label>
                                                <AsyncSelect
                                                    onChange={handleChangeSeller}
                                                    loadOptions={brandsOptions}
                                                    name="brand"
                                                    isMulti
                                                    value={formDataSeller.brand}
                                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                />
                                            </div>
                                            <div className="col">
                                                <label className="form-label">Type of Business</label>
                                                <div className="mb-3">
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" name="business_type" type="checkbox" checked={formDataSeller.business_type["1"] ? true : false} id="ManufacInfo" onChange={handleChangeSeller} value="1" />
                                                        <label className="form-check-label" for="ManufacInfo">Manufacturer</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" name="business_type" type="checkbox" checked={formDataSeller.business_type["2"] ? true : false} id="DealerInfo" onChange={handleChangeSeller} value="2" />
                                                        <label className="form-check-label" for="DealerInfo">Dealer</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-4 row-cols-1 row-cols-lg-3">
                                            <div className="col">
                                                <label className="form-label">Industry</label>
                                                <AsyncSelect
                                                    onChange={handleChangeSeller}
                                                    loadOptions={industriesOptions}
                                                    name="industry"
                                                    value={formDataSeller.industry}
                                                    isMulti
                                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                />
                                            </div>
                                            <div className="col">
                                                <label className="form-label">Key Clients</label>
                                                <AsyncSelect
                                                    onChange={handleChangeSeller}
                                                    loadOptions={credentialsOptions}
                                                    name="credential"
                                                    value={formDataSeller.credential}
                                                    isMulti
                                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                />
                                            </div>
                                            <div className="col">
                                                <label className="form-label">Certificates</label>
                                                <AsyncSelect
                                                    onChange={handleChangeSeller}
                                                    loadOptions={certificatesOptions}
                                                    name="certificate"
                                                    value={formDataSeller.certificate}
                                                    isMulti
                                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                            return null;
                        })()}
                        <div className="cta-btn text-center">
                            <button type="button" onClick={clearSearch} className="btn btn-warning button-secondary">Clear</button>&nbsp;
                            <button type="submit" className="btn btn-primary button-style" >Search</button>
                        </div>
                    </form>
                    <div className="list-group mt-5">
                        {sellerSearchList()}
                    </div>
                    <br />
                    {showPagination()}
                </Modal.Body>
            </Modal>
            <div className="form-wrapper">
                <div className="card">
                    <div className="card-header">
                        {formData.reference_no} {formData.pr_number} {formData.rfq_number} {formData.subject}

                        {(() => {
                            if(isPermitted('site_purchase_order_modify') && formData.status==0){
                                return <Link to={`/po-edit/${viewID}`} className="btn btn-primary float-end">
                                    <i className="bi bi-pencil-fill cursor-pointer"></i>
                                </Link>
                            }
                        })()}
                    </div>
                    <div className="card-body form-panel-wrapper">
                        <Notification {...alertMessage} />
                        <div className="row inai-info-background">
                            <div className="col-md-4 inai-border-right ps-md-0">
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Purchase Order Number : </b>
                                        {(() => {
                                            if (formData.reference_no) {
                                                return <>{ formData.reference_no} </>
                                            } else {
                                                return <i>Yet to generate</i>
                                            }
                                        })()}
                                        </label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Currency : </b>{formData.currency_read} </label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Place of Delivery : </b>{formData.delivery_place} </label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Incoterms : </b>{formData.incoterms_read} </label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Quotation Number : </b>{formData.qno} </label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>PO Date : </b>{formData.po_on_date} </label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Billing Address : </b>{renderAddressContent(formData.addresses['billing'])}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 inai-border-right ps-md-5">
                            <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Subject : </b>{formData.subject} </label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>PR No : </b>{formData.pr_number} </label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>PR Date : </b>{formData.pr_date} </label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>RFQ No : </b>{formData.rfq_number} </label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Target Delivery Date : </b>{formData.delivery_date} </label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Specific End Use : </b>{formData.specific_end_use} </label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Delivery Address : </b>{renderAddressContent(formData.addresses['delivery'])} </label>
                                    </div>
                                </div>
                        </div>
                        <div className="col-md-4 ps-md-5 pe-md-0">
                            <div className="row mb-3">
                                <div className="col-12">
                                    <label className="form-label"><b>Requesting Department : </b>{formData.requesting_dept} </label>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12">
                                    <label className="form-label"><b>Prepared by : </b>{formData.prepared_by} </label>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12">
                                    <label className="form-label"><b>Approved by : </b>{formData.approved_by} </label>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12">
                                    <label className="form-label"><b>PO Status : </b>{formData.status_read} </label>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12">
                                    <label className="form-label"><b>Payment Term : </b>{formData.pay_term} </label>
                                </div>
                            </div>

                            {(() => {
                                if (formData.seller && formData.status) {
                                    return <div className="row mb-3">
                                                <div className="col-12">
                                                    <label className="form-label"><b>Purchase Order : </b>
                                                        <i onClick={() => downloadPoDocument(formData.pk, '?type=pdf')} className="btn btn-primary bi bi-cloud-download-fill cursor-pointer ms-3"></i>
                                                    </label>
                                                </div>
                                            </div>
                                }
                            })()}

                        </div>
                        </div>

                        <div className="table-responsive row">
                            <label className="subtitle">Items </label>
                            <div className="p-2">
                                <table className="table align-middle table-design inai-table table-striped table-hover table-borderless shadow mb-3 bg-white rounded">
                                    <thead>
                                        <tr>
                                            <td width="40">S.No</td>
                                            <td>Item Code </td>
                                            <td>Quantity </td>
                                            <td>UOM </td>
                                            <td>Description </td>
                                            <td>HSN </td>
                                            <td>GST (%) </td>
                                            <td>Item Price</td>
                                            <td>Total Price</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderItems()}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="row">
                            <label className="subtitle">Documents</label>
                            <div className="accordion">
                                {renderDocuments()}
                            </div>
                        </div>
                       
                        <div className="row">
                            <label className="subtitle">Terms & Conditions </label>
                            <div className="accordion" id="accordionPanelsStayOpenExample">
                                {getTermsList()}
                            </div>
                        </div>

                        <div className="table-responsive row">
                            <label className="subtitle">Invited Seller</label>
                            <div className="p-2">
                                <table className="table align-middle table-design inai-table table-striped table-hover table-borderless shadow mb-3 bg-white rounded">
                                    <thead>
                                        <tr>
                                            <td>Seller Name</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(() => {
                                            if (formData.seller) {
                                                return <tr>
                                                    <td><Link to={"/company-profile/" + formData.seller_customer_id}><b><span className="badge  bg-primary">{formData.seller_customer_id}</span></b> {formData.seller_company_name}</Link></td>
                                                </tr>
                                            }
                                            return <tr><td ><i>No records found</i></td></tr>;
                                        })()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="cta-btn mb-4">
                            {(() => {
                                return <button type="button" disabled={formData.status} className="btn btn-primary button-style" onClick={() => {handleShowSeller(); clearSearch(); setDataList([]);}}>
                                    {formData.seller ? 'Change Seller' : 'Select Seller'}
                                </button>;
                            })()}

                            {(() => {
                                return <button type="button"  disabled={!formData.seller || formData.status} onClick={InvitePo} className="btn btn-primary button-style ms-2">Invite</button>;
                            })()}
                        </div>

                        {renderExtraDocuments()}
                    </div>

                </div>
            </div>
        </div>
    );
};

export default PoView;