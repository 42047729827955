import React, { useState, useEffect, useRef } from "react";
import NavigationMenu from "./NavigationMenu";
import axios from "axios";
import Notification, { errorText, errorBorder } from "../../reusable/Notification";
import { AuthTokenVerify, UpdateBearer } from "../../reusable/AuthUtils";
import { PageToTop } from "../../reusable/CommonUtils";

const ChangePassword = ({history}) => {

    const initForm = {
        'old_password': '',
        'new_password': '',
        'confirm_new_password': '',
    };

    const [ alertMessage, setAlertMessage ] = useState({});
    const [ disableSubmit, setDisableSubmit ] = useState(false);
    const [ filedError, setFieldError ] = useState({});
    const [ form, setForm ] = useState(initForm);

    useEffect(() => {
        AuthTokenVerify(history);
        PageToTop();
    }, []);

    const submitForm = (e) => {
        e.preventDefault();
        setAlertMessage({});
        setFieldError({});
        setDisableSubmit(true);

        axios.post("user/update-password/", form).then((response) => {
            setDisableSubmit(false);
            setAlertMessage({type:'success', message:response.data.message});
            setForm(initForm);
        }).catch((e) => {
            if(e.response.status === 400) {
                setAlertMessage({type:'danger', message:"Error Occured While Submit!"});
                let dict = {};
                for (const [key, value] of Object.entries(e.response.data)) {
                    dict[key] = value.join(', ');
                }
                setFieldError(dict);
            }
            setDisableSubmit(false);
        });
    };

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="container">
            <div className="form-wrapper">
                <NavigationMenu item={ 8 }  />
                <div className="form-panel-wrapper">
                    <Notification {...alertMessage}/>
                    <form>
                        <div className="row mb-4 row-cols-1 row-cols-lg-3 otp-verify">
                            <div className="col">
                                <label className="form-label">Current Password</label>
                                <input type="password" onChange={handleChange} value={ form.old_password } name="old_password"  className={"form-control " + errorBorder(filedError.old_password) } />
                                { errorText(filedError.old_password) }
                            </div>
                            <div className="col">
                                <label className="form-label">New Password</label>
                                <input type="password" onChange={handleChange} value={ form.new_password } name="new_password" className={"form-control " + errorBorder(filedError.new_password) } />
                                { errorText(filedError.new_password) }
                            </div>
                            <div className="col">
                                <label className="form-label">Confirm Password</label>
                                <input type="password" onChange={handleChange} value={ form.confirm_new_password }  name="confirm_new_password" className={"form-control " + errorBorder(filedError.confirm_new_password) } />
                                { errorText(filedError.confirm_new_password) }
                            </div>
                        </div>

                        <div className="cta-btn">
                            <button type="submit" disabled={disableSubmit} onClick={submitForm} className="btn btn-primary button-style">Update</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ChangePassword;