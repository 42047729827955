import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { ClearToken } from '../reusable/AuthUtils';

const Logout = () => {

    const navigate = useNavigate();

    useEffect(() => {
        ClearToken(navigate);
    }, [])

    return (
        <span>Logout</span>
    )
}

export default Logout