import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios'

function RolesMap(props) {

    const [permissionData, setPermissionData] = useState([]);
    const [groupData, setGroupData] = useState([]);
    const [groupPermission, setGroupPermission] = useState([]);

    useEffect(() => {
        axios.get(`roles/permission-map/`)
        .then(response => {
            setPermissionData(response.data.permissions);
            setGroupData(response.data.groups);
            setGroupPermission(response.data.group_permission);
        }).catch(error => {
            //
        });
    }, []);

    const renderTableData = (gpk, ppk) => {
        let key = `${gpk}${ppk}`;
        let html = <i className="bi bi-x-circle-fill text-danger permission-icon"></i>;
        
        if(groupPermission.includes(key)){
            html = <i className="bi bi-check-circle-fill text-success permission-icon"></i>;
        }

        return html;
    }

    const renderPermissionData = () => {
        return permissionData.map(idata => {
            let html = new Array();
            html.push(<tr className='permission-cat'><td scope="row">{idata.name}</td><td colSpan={groupData.length}></td></tr>);
            html.push((idata.permissions).map(pdata => {
                let cHtml = new Array();
                cHtml.push(groupData.map(gData => {
                    return <td className='text-center'>{renderTableData(gData.pk, pdata.pk)}</td>;
                }));
                return <tr><td scope="row">{pdata.name}</td>{cHtml}</tr>;
            }));
            return html;
        });
    };

    const renderGroupData = () => {
        return groupData.map(data => {
            return <td className='text-center' scope="col">{data.name}</td>;
        });
    };

    return (
        <Modal fullscreen
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Roles default permission
                </Modal.Title>
                </Modal.Header>
            <Modal.Body>
                <table class="table permission-table table-bordered table-design">
                    <thead class="thead-dark">
                        <tr>
                            <td scope="col">Permission</td>
                            { renderGroupData() }
                        </tr>
                    </thead>
                    <tbody>
                        { renderPermissionData() }
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default RolesMap;