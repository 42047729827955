import React, { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import axios from 'axios'
import { Invalid, Register as DoRegister } from './Register/index';
import { PageToTop } from '../reusable/CommonUtils';

const Register = () => {
    const search = useLocation().search;
    const [ verifyCode, setVerifyCode ] = useState('');
    const [ isValidCode, setIsValidCode ] = useState(0); //0: initiated, 1:valid, 2:invalid
    const [ email, setEmail ] = useState('');
    const [ message, setMessage ] = useState('');

    useEffect(() => {
        const vrCode = new URLSearchParams(search).get('verify_code');
        setVerifyCode(vrCode);
        axios.post("user/email-verify-confirm/", {
            verify_code: vrCode
        })
        .then((response) => {
            if(response.data.is_verified === 1){
                setIsValidCode(2);
                setMessage('Invalid (or) Expired Email Verification Code');
            }else{
                setEmail(response.data.email);
                setIsValidCode(1);
            }
        })
        .catch((e) => {
            setIsValidCode(2);
            setMessage('Invalid (or) Expired Email Verification Code');
        });

        PageToTop();
    }, []);

    const renderElement = () => {
        switch (isValidCode) {
            case 1:
                return <DoRegister email={email} verifyCode={verifyCode} />;
                break;
            case 2:
                return <Invalid message={message}/>;
                break;
            default:
                return null
                break;
        }
        
    };

    return (
        <>
            { renderElement() }
        </>
    )
};

export default Register