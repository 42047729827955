import { useEffect } from "react";
import { PageToTop } from "../reusable/CommonUtils";

const ContactUs = () => {

    useEffect(() => {
        PageToTop();
    }, []);

    return (
        <>
            <section className="container">
                <div className="about-section">
                    <div className="section-title">
                        <h2><img src="/assets/images/title-divider.png" /> Contact Us <img src="/assets/images/title-divider.png" /></h2>
                    </div>
                    <div className="contact-section">
                        <div className="contact-address">
                            <h4>INAI Procure Private Limited</h4>
                            <p>Flat No.G-2, Plot No.19,<br />
                            Mullai Street, Sakthi Nagar<br />
                            Adambakkam, Chennai - 600088,<br />
                            Tamil Nadu.</p>
                            <br />
                            <br />
                            <div className="Contact-details">
                                <div className="contact-info">
                                    <h5>Customer Care</h5>
                                    <b>Bojan Rajendran</b>	<br />
                                    +91 9976542758<br />
                                    <a href="mailto:inai@inaippl.com">inai@inaippl.com</a>
                                </div>
                                <div className="contact-info">
                                    <h5>Accounts</h5>
                                    <b>Vidhya Bojan</b>	<br />
                                    +91 9894458191<br />
                                    <a href="mailto:vidhya@inaippl.com">vidhya@inaippl.com</a>
                                </div>
                                <div className="contact-info">
                                    <h5>Quality Engineer</h5>
                                    <b>Kannan.R</b> <br />
                                     +91 4442155329<br />
                                    <a href="mailto:po@inaippl.com">po@inaippl.com</a>
                                </div>
                            </div>
                            <br />
                            <div className="Contact-details">
                                <div>
                                    <h5 className="">Business Development Team</h5>
                                    <div className="businessdev1">
                                        <b>Ramya L</b>	<br />
                                        +91 7305777308<br />
                                        <a href="mailto:support@inaippl.com">support@inaippl.com</a>
                                    </div>
                                    <div className="contact-info businessdev2">
                                        <b>Anitha.P</b>	<br />
                                        +91 7305977308<br />
                                        <a href="mailto:support@inaippl.com">support@inaippl.com</a>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div className="contact-map">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.9133899119934!2d80.20229051492576!3d12.97739119085211!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525d66d2e72345%3A0x14db2fa96132697b!2sINAI%20Procure%20Private%20Limited!5e0!3m2!1sen!2sin!4v1643350397969!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy"></iframe>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default ContactUs