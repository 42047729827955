import React, { useEffect, useState } from "react";
import axios from "axios";
import _ from "lodash"
import Notification, { errorText, errorBorder } from "../../reusable/Notification";
import NavigationMenu from "./NavigationMenu";
import { AuthTokenVerify, UpdateBearer } from "../../reusable/AuthUtils";
import { PageToTop } from "../../reusable/CommonUtils";
import { isPermitted } from '../../reusable/PermissoinUtils';
import { Link } from "react-router-dom";
import FileUploader from "../../reusable/FileUploader"

const CompanyDetails = ({history}) => {

    const [ countries, setCountries ] = useState([]);
    const [ getLegalStatus, setLegalStatus ] = useState([]);
    const [ getCompanyInfo, setCompanyInfo ] = useState({});
    const [ businessNatureCatDict, setBusinessNatureCatDict ] = useState({});
    const [ businessNaturesDict, setBusinessNaturesDict ] = useState({});
    const [ FactoryStates, setFactoryStates ] = useState([]);
    const [ RegStates, setRegStates ] = useState([]);
    const [ factoryAddrDisable, setFactoryAddrDisable ] = useState(0);
    const [ alertMessage, setAlertMessage ] = useState({});
    const [ filedError, setFieldError ] = useState({});
    const [ pageRefresh, setPageRefresh ] = useState(true);
    const [ logoPath, setLogoPath ] = useState('/assets/images/profile-icon.png');

    // Form Data
    const initialFormData = Object.freeze({
        anual_turnover: "",
        business_nature: new Map(),
        company_name: "",
        pan: "",
        tan: "",
        cin: "",
        email: "",
        country: "",
        established_on: "",
        fax: "",
        gstin_vat: "",
        legal_status: "",
        nearest_airport: "",
        nearest_seaport: "",
        no_of_emp: "",
        phone: "",
        web_site: "",
        fact_street_name: "",
        factory_city: "",
        factory_country: "",
        factory_pincode: "",
        factory_state: "",
        reg_city: "",
        reg_country: "",
        reg_pincode: "",
        reg_state: "",
        reg_street_name: "",
        same_address: 0,
        document: ""
    });

    const [ formData, setFormData ] = useState(initialFormData);
    const [ companyId, setCompanyId ] = useState(0);

    const handleDocumentChange = (e, index) => {
        setFormData({...formData, 'document' : e.target.files[0]});
    };

    const arrayBufferToBase64 = (buffer) => {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    };

    useEffect(() => {
        if(!pageRefresh) return;
        AuthTokenVerify(history);
        
        axios.get("user/company-reg/").then((response) => {

            let data = response.data;
            let dictBNC = {};
            let dictBN = {};
            let updatedFormData = {};

            setCompanyId(data.company_info.customer_id);

            axios.get("user/company-logo/" + data.company_info.customer_id, {responseType: 'arraybuffer'}).then((response) => {
                try{
                    if(response.data && response.data != '') {
                        let base64Image = arrayBufferToBase64(response.data);
                        let imageUrl = `data:image/png;base64,${base64Image}`;
                        setLogoPath(imageUrl);
                    }
                }
                catch(err){
                    console.log(err);
                }
            }).catch((error) => {}
            );

            let legalStatus =  Object.entries(data.legal_status).map((item, index) => {
                return <option key={item[0]} value={item[0]}>{item[1]}</option>
            });
            
            for (const [key, value] of Object.entries(data.business_nature)) {
                dictBNC[value.id] = value.category;
                dictBN[value.id] = {};
                for (const [k, v] of Object.entries(value.business_natures)) {
                    dictBN[value.id][v.id] = v.business_nature;
                }
            }

            for (const [key, value] of Object.entries(data.company_info)) {
                let keyTrimmed =  String(key.trim());
                let valueTrimmed = '';
                if (keyTrimmed in formData) {
                    switch (keyTrimmed) {
                        case "business_nature":
                            let dictBNCInit = new Map();
                            for (const [k, v] of Object.entries(value)) {
                                dictBNCInit.set(parseInt(v.pk), true);
                            }
                            valueTrimmed = dictBNCInit;
                            break;
                        case "same_address":
                            valueTrimmed = value ? 1 : 0;
                            break;
                    
                        default:
                            valueTrimmed =  value ? String(value).trim() : '';
                            break;
                    }
                    updatedFormData[keyTrimmed] = valueTrimmed;
                }
            }

            setFormData({...formData, ...updatedFormData});
            setLegalStatus(legalStatus);
            setBusinessNatureCatDict(dictBNC);
            setBusinessNaturesDict(dictBN);
            setCompanyInfo(data.company_info);

            UpdateBearer(history, response);
        }).catch((e) => {
            if(e.response.status === 403) {
                //permission denied
                //history('/');
            }
        });

        axios.get("common/countries/").then((response) => {
            setCountries(response.data);
        }).catch((error) => {
            //
        });

        setPageRefresh(false)
        PageToTop();


    }, [pageRefresh]);
    

    useEffect(() => {
        setFactoryAddrDisable(formData.same_address);
        copyOfficeAddrToFactAddr();
    }, [formData.same_address, formData.reg_street_name, formData.reg_city, formData.reg_state, formData.reg_country, formData.reg_pincode]);

    useEffect(() => {
        setFactoryStates([]);
        if(!formData.factory_country) return;
        axios.get("common/states/" + formData.factory_country + "/").then((response) => {
            setFactoryStates(response.data);
        }).catch((error) => {
            //
         });
    }, [formData.factory_country]);

    useEffect(() => {
        setRegStates([]);
        if(!formData.reg_country) return;
        axios.get("common/states/" + formData.reg_country + "/").then((response) => {
            setRegStates(response.data);
        }).catch((error) => {
            //
         });
    }, [formData.reg_country]);

    const getCountries = () => {
        const content = [<option value="0">-select-</option>];
        countries.map(item => {
            content.push(<option value={item.id}>{item.country}</option>);
        });
        return content;
    };

    const copyOfficeAddrToFactAddr = () => {
        if(formData.same_address){
            setFormData({
                ...formData,
                ...{
                    'fact_street_name': formData.reg_street_name,
                    'factory_city': formData.reg_city,
                    'factory_state': formData.reg_state,
                    'factory_country': formData.reg_country,
                    'factory_pincode': formData.reg_pincode
                }
            });
        }
    };

    const onSubmit = (e) => {
        setAlertMessage({});
        setFieldError({});
        
        let formDataClone = _.clone(formData);
        let business_nature_list = [];
        for (const [key, value] of formDataClone.business_nature) {
            if(value) business_nature_list.push(key);
        }
        formDataClone.business_nature = business_nature_list;
        formDataClone.logoonly = e.currentTarget.classList.contains('logo-only') ? 1 : 0;
        if(formDataClone.document == undefined){
            formDataClone.document = '';
        }

        if(formDataClone.document == '') {
            var my_post = axios.post("user/company-reg/", formDataClone);
        } else {
            let fData = new FormData();
            Object.entries(formDataClone).forEach(([k,v]) => {
                if(k == 'business_nature'){
                    fData.append(k, JSON.stringify(v));
                } else {
                    fData.append(k, v);
                }
            });
            var my_post = axios({
                method: "post",
                url: "user/company-reg/",
                data: fData,
                headers: { "Content-Type": "multipart/form-data" },
            });
        }
        my_post.then((response) => {
            if(response.status === 200){
                setAlertMessage({type:'success', message:response.data.message});
                setFormData({...formData, ...{'document' : ""}});
                setPageRefresh(true)
            }
        })
        .catch((e) => {
            if(e.response.status === 400) {
                setAlertMessage({type:'danger', message:"Error Occured While Submit!"});
                let dict = {};
                for (const [key, value] of Object.entries(e.response.data)) {
                    dict[key] = value.join(', ');
                }
                setFieldError(dict);
            }
        });

        PageToTop();
    };

    const getEstaYears = () => {
        let currentYear = new Date().getFullYear();
        let fromYear = 1800;
        let rows = [];

        for ( currentYear; fromYear <= currentYear; currentYear-- ){
            rows.push(<option key={currentYear} value={currentYear}>{currentYear}</option>);
        }
        return rows;
    };

    const getRegStates = () => {
        const content = [<option value="0">-select-</option>];
        RegStates.map(item => {
            content.push(<option value={item.id}>{item.state}</option>);
        });
        return content;
    };

    const getFactoryStates = () => {
        const content = [<option value="0">-select-</option>];
        FactoryStates.map(item => {
            content.push(<option value={item.id}>{item.state}</option>);
        });
        return content;
    };

    const getBusinessNatures = (cat) => {

        if (cat in businessNaturesDict){
            return Object.entries(businessNaturesDict[cat]).map((bnObj, bnIndex) => ( 
                <div className="form-check form-check-inline">
                    <label className="form-check-label"><input 
                        className="form-check-input"
                        key={bnIndex}
                        checked={formData.business_nature.get(parseInt(bnObj[0]))}
                        value={bnObj[0]} 
                        name="business_nature" 
                        type="checkbox"
                        onChange={handleChange}
                    />
                    {bnObj[1]}</label>
                </div>
            ))
        }
    };

    const handleChange = (e) => {
 
        let targetData = '';

        switch (e.target.name) {
            case "business_nature":
                targetData = formData.business_nature.set(parseInt(e.target.value), e.target.checked)
                break;
            case "same_address":
                targetData = e.target.checked ? 1 : 0;
                break;
            case "country":
                targetData = parseInt(e.target.value) ? parseInt(e.target.value) : '';
                break;
            case "factory_country":
                targetData = parseInt(e.target.value) ? parseInt(e.target.value) : '';
                break;
            case "reg_country":
                targetData = parseInt(e.target.value) ? parseInt(e.target.value) : '';
                break;
            case "factory_state":
                targetData = parseInt(e.target.value) ? parseInt(e.target.value) : '';
                break;
            case "reg_state":
                targetData = parseInt(e.target.value) ? parseInt(e.target.value) : '';
                break;
            default:
                targetData = e.target.value;
                break;
        }

        setFormData({
            ...formData,
        
            // Trimming any whitespace
            [e.target.name]: targetData
        });
    };


    return (
        <div className="container">
            <div className="form-wrapper">
                <NavigationMenu item={ 1 } />
                    <div className="form-panel-wrapper">
                        <Notification {...alertMessage}/>
                        <fieldset disabled={!isPermitted('site_my_profile_company_details_modify')}>
                            <form>
                                <div className="row row-cols-1 row-cols-md-3 ">
                                    <div className="col mb-4 ">
                                        <label className="form-label">Company Name  <sup className="text-danger">*</sup><Link to={"/company-profile/" + companyId}><b><span className="badge  bg-primary">{ companyId }</span></b></Link> </label>
                                        <input type="text" disabled={getCompanyInfo.lock_company_info} name="company_name" onChange={handleChange} value={formData.company_name} className={"form-control " + errorBorder(filedError.company_name) }/>
                                        { errorText(filedError.company_name) }
                                    </div>
                                    <div className="col mb-4 ">
                                        <label className="form-label">PAN <sup className="text-danger">*</sup></label>
                                        <input type="text" disabled={getCompanyInfo.lock_company_info} name="pan" onChange={handleChange} value={formData.pan} className={"form-control " + errorBorder(filedError.pan) }/>
                                        { errorText(filedError.pan) }
                                    </div>
                                    <div className="col mb-4 ">
                                        <label className="form-label">TAN</label>
                                        <input type="text" disabled={getCompanyInfo.lock_company_info} name="tan" onChange={handleChange} value={formData.tan} className={"form-control " + errorBorder(filedError.tan) }/>
                                        { errorText(filedError.tan) }
                                    </div>
                                    <div className="col mb-4 ">
                                        <label className="form-label">CIN</label>
                                        <input type="text" disabled={getCompanyInfo.lock_company_info} name="cin" onChange={handleChange} value={formData.cin} className={"form-control " + errorBorder(filedError.cin) }/>
                                        { errorText(filedError.cin) }
                                    </div>
                                    <div className="col mb-4 ">
                                        <label className="form-label">GSTIN / VAT</label>
                                        <input type="text" disabled={getCompanyInfo.lock_company_info} name="gstin_vat" onChange={handleChange} value={formData.gstin_vat} className={"form-control " + errorBorder(filedError.gstin_vat) }/>
                                        { errorText(filedError.gstin_vat) }
                                    </div>
                                    <div className="col mb-4 ">
                                        <label className="form-label">Country <sup className="text-danger">*</sup></label>
                                        <select name="country" disabled={getCompanyInfo.lock_company_info}  onChange={handleChange} value={formData.country} className={"form-select " + errorBorder(filedError.country) }>
                                            
                                            { getCountries() }
                                        </select>
                                        { errorText(filedError.country) }
                                    </div>
                                    <div className="col mb-4 ">
                                        <label className="form-label">Year of Establishment <sup className="text-danger">*</sup></label>
                                        <select name="established_on" disabled={getCompanyInfo.lock_company_info}  value={formData.established_on} onChange={handleChange} className={"form-select " + errorBorder(filedError.established_on) }>
                                            <option value="">Select</option>
                                            { getEstaYears() }
                                        </select>
                                        { errorText(filedError.established_on) }
                                    </div>
                                    <div className="col mb-4 ">
                                        <label className="form-label">Legal Status of Firm <sup className="text-danger">*</sup></label>
                                        <select name="legal_status" disabled={getCompanyInfo.lock_company_info}  value={formData.legal_status} onChange={handleChange}  className={"form-select " + errorBorder(filedError.legal_status) }>
                                            <option value="">Select</option>
                                            { getLegalStatus }
                                        </select>
                                        { errorText(filedError.legal_status) }
                                    </div>
                                    <div className="col mb-4">
                                        <label className="form-label">Nature of Business <sup className="text-danger">*</sup></label><br/>{ errorText(filedError.business_nature) }
                                    
                                    {
                                        Object.entries(businessNatureCatDict).map((obj, index) => ( 
                                            <>
                                            <div className="row-cols-1 mb-2">
                                                <label className="form-check-label" for="flexCheckDefault">
                                                    { obj[1] }
                                                </label>
                                            </div>
                                            <div className="mb-4">
                                            { getBusinessNatures(obj[0]) }
                                            </div>
                                            </>
                                        ))
                                    }
                                    </div>
                                    
                                </div>
                                <div className="row row-cols-1 row-cols-md-4 ">
                                    <div className="col mb-4 ">
                                        <label className="form-label">Logo (.jpg, .jpeg, .png, .gif)</label>
                                        <FileUploader name="document"
                                            onUpload={handleDocumentChange}
                                            fileName={formData.document ? formData.document.name : null}/>
                                    </div>
                                    <div className="cta-btn mt-4">
                                            <button type="button" onClick={ onSubmit } className="btn btn-primary button-style logo-only"><i class="bi bi-upload p-2"></i>upload</button>
                                            { errorText(filedError.document) }
                                    </div>
                                    <div className="col">
                                        <div>
                                            <img src={ logoPath } alt="Profile" width={100} />
                                        </div>
                                    </div>
                                </div>

                                <div className="form-address-panel mb-4">
                                    <h2>Registered Office Address</h2>
                                    <div className="row mb-4 row-cols-1 row-cols-lg-3">
                                        <div className="col">
                                            <label className="form-label">Street Name <sup className="text-danger">*</sup></label>
                                            <input type="text" onChange={handleChange} value={formData.reg_street_name} name="reg_street_name" className={"form-control " + errorBorder(filedError.reg_street_name) }/>
                                            { errorText(filedError.reg_street_name) }
                                        </div>
                                        <div className="col">
                                            <label className="form-label">City <sup className="text-danger">*</sup></label>
                                            <input type="text" onChange={handleChange} value={formData.reg_city} name="reg_city" className={"form-control " + errorBorder(filedError.reg_city) }/>
                                            { errorText(filedError.reg_city) }
                                        </div>
                                        <div className="col">
                                            <label className="form-label">Country <sup className="text-danger">*</sup></label>
                                            <select name="reg_country" value={ formData.reg_country } onChange={handleChange} className={"form-select " + errorBorder(filedError.reg_country) }>
                                                
                                                { getCountries() }
                                            </select>
                                            { errorText(filedError.reg_country) }
                                        </div>
                                    </div>
                                    <div className="row row-cols-1 row-cols-lg-3">
                                        <div className="col">
                                            <label className="form-label">State <sup className="text-danger">*</sup></label>
                                            <select name="reg_state" value={ formData.reg_state } onChange={handleChange} className={"form-select " + errorBorder(filedError.reg_state) }>
                                                
                                                { getRegStates() }
                                            </select>
                                            { errorText(filedError.reg_state) }
                                        </div>
                                        <div className="col">
                                            <label className="form-label">Pincode <sup className="text-danger">*</sup></label>
                                            <input name="reg_pincode" value={formData.reg_pincode} onChange={handleChange} type="text" className={"form-control " + errorBorder(filedError.reg_pincode) }/>
                                            { errorText(filedError.reg_pincode) }
                                        </div>
                                        <div className="col">
                                            &nbsp;
                                        </div>
                                    </div>
                                </div>

                                <div className="form-check row-cols-1 row-cols-lg-3">
                                    <input className={"form-check-input " + errorBorder(filedError.phone) } name="same_address" checked={formData.same_address} onChange={handleChange} type="checkbox" value="" id="flexCheckDefault" />
                                    <label className="form-check-label" for="flexCheckDefault">
                                        Factory Address same as office address
                                    </label>
                                </div>

                                <div className="form-address-panel">
                                    <h2>Factory Address</h2>
                                    <div className="row mb-4 row-cols-1 row-cols-lg-3">
                                        <div className="col">
                                            <label className="form-label">Street Name <sup className="text-danger">*</sup></label>
                                            <input type="text" name="fact_street_name" disabled={factoryAddrDisable} value={ formData.fact_street_name } onChange={handleChange} className={"form-control " + errorBorder(filedError.fact_street_name) }/>
                                            { errorText(filedError.fact_street_name) }
                                        </div>
                                        <div className="col">
                                            <label className="form-label">City <sup className="text-danger">*</sup></label>
                                            <input type="text" name="factory_city" disabled={factoryAddrDisable} value={ formData.factory_city } onChange={handleChange} className={"form-control " + errorBorder(filedError.factory_city) }/>
                                            { errorText(filedError.factory_city) }
                                        </div>
                                        <div className="col">
                                            <label className="form-label">Country <sup className="text-danger">*</sup></label>
                                            <select name="factory_country" disabled={factoryAddrDisable} value={ formData.factory_country }  onChange={handleChange} className={"form-select " + errorBorder(filedError.factory_country) }>
                                                { getCountries() }
                                            </select>
                                            { errorText(filedError.factory_country) }
                                        </div>
                                    </div>
                                    <div className="row row-cols-1 row-cols-lg-3">
                                        <div className="col">
                                            <label className="form-label">State <sup className="text-danger">*</sup></label>
                                            <select name="factory_state" disabled={factoryAddrDisable} value={ formData.factory_state } onChange={handleChange} className={"form-select " + errorBorder(filedError.factory_state) }>
                                                
                                                { getFactoryStates() }
                                            </select>
                                            { errorText(filedError.factory_state) }
                                        </div>
                                        
                                        <div className="col">
                                            <label className="form-label">Pincode <sup className="text-danger">*</sup></label>
                                            <input name="factory_pincode" disabled={factoryAddrDisable} value={ formData.factory_pincode } onChange={handleChange} type="text" className={"form-control " + errorBorder(filedError.factory_pincode) }/>
                                            { errorText(filedError.factory_pincode) }
                                        </div>
                                        <div className="col">
                                            &nbsp;
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-4 mt-4 row-cols-1 row-cols-lg-3">
                                    <div className="col">
                                        <label className="form-label">Phone Number <sup className="text-danger">*</sup></label>
                                        <input type="text" name="phone" value={ formData.phone } onChange={handleChange} className={"form-control " + errorBorder(filedError.phone) }/>
                                        { errorText(filedError.phone) }
                                    </div>
                                    <div className="col">
                                        <label className="form-label">Email <sup className="text-danger">*</sup></label>
                                        <input type="email" name="email" value={ formData.email } onChange={handleChange} className={"form-control " + errorBorder(filedError.email) }/>
                                        { errorText(filedError.email) }
                                    </div>
                                    <div className="col">
                                        <label className="form-label">Fax</label>
                                        <input type="text" name="fax" value={ formData.fax } onChange={handleChange} className={"form-control " + errorBorder(filedError.fax) }/>
                                        { errorText(filedError.fax) }
                                    </div>
                                </div>

                                <div className="row mb-4 row-cols-1 row-cols-lg-3">
                                    <div className="col">
                                        <label className="form-label">Website Address</label>
                                        <input type="text" name="web_site" value={ formData.web_site } onChange={handleChange} className={"form-control " + errorBorder(filedError.web_site) }placeholder="Ex: http://example.com" />
                                        { errorText(filedError.web_site) }
                                    </div>
                                    <div className="col">
                                        <label className="form-label">Nearest Airport</label>
                                        <input type="text" name="nearest_airport" value={ formData.nearest_airport } onChange={handleChange} className={"form-control " + errorBorder(filedError.nearest_airport) }/>
                                        { errorText(filedError.nearest_airport) }
                                    </div>
                                    <div className="col">
                                        <label className="form-label">Nearest Seaport</label>
                                        <input type="text" name="nearest_seaport" value={ formData.nearest_seaport } onChange={handleChange} className={"form-control " + errorBorder(filedError.nearest_seaport) }/>
                                        { errorText(filedError.nearest_seaport) }
                                    </div>
                                </div>

                                <div className="row mb-4 row-cols-1 row-cols-lg-3">
                                    <div className="col">
                                        <label className="form-label">Annual Turn Over for Last Year (In Lakhs)</label>
                                        <input type="text" name="anual_turnover" value={ formData.anual_turnover } onChange={handleChange} className={"form-control " + errorBorder(filedError.anual_turnover) }/>
                                        { errorText(filedError.anual_turnover) }
                                    </div>
                                    <div className="col">
                                        <label className="form-label">Number of Employees</label>
                                        <input type="text" name="no_of_emp" value={ formData.no_of_emp } onChange={handleChange} className={"form-control " + errorBorder(filedError.no_of_emp) }/>
                                        { errorText(filedError.no_of_emp) }
                                    </div>
                                    <div className="col">
                                        &nbsp;
                                    </div>
                                </div>

                                <div className="cta-btn">
                                    <button type="button" onClick={ onSubmit } className="btn btn-primary button-style">save & continue</button>
                                </div>
                            </form>
                        </fieldset>
                    </div>
                
            </div>
        </div>
    )
};

export default CompanyDetails;