import React, {useState, useEffect} from 'react'
import validator from 'validator';
import axios from 'axios'
import { useLocation } from "react-router-dom";
import Notification, { errorText, errorBorder } from '../reusable/Notification';
import { PageToTop } from '../reusable/CommonUtils';

const ResetPassword = () => {

    const [ alertMessage, setAlertMessage ] = useState({});
    const [ requiredFields, setRequiredFields ] = useState({});

    const search = useLocation().search;
    const [ verifyCode, setVerifyCode ] = useState('');
    const [ isValidCode, setIsValidCode ] = useState(0); //0: initiated, 1:valid, 2:invalid|success
    const [ message, setMessage ] = useState('');


    // Form Data
    const initialFormData = Object.freeze({
        password: '',
        password2: '',
        verify_code: '',
    });

    const [ formData, setFormData ] = useState(initialFormData);

    useEffect(() => {
        const hash = new URLSearchParams(search).get('hash');
        setVerifyCode(hash);
        setFormData({
            ...formData,
            ['verify_code']: hash
        });

        axios.post("user/reset-password-valid-check/", {
            verify_code: hash
        })
        .then((response) => {
            setIsValidCode(1);
        })
        .catch((e) => {
            setIsValidCode(2);
            setMessage('Invalid (or) Expired Link');
        });

        PageToTop();
    }, []);

    const handleChange = (e) => {

        let targetData = '';

        switch (e.target.name) {
            default:
                targetData = e.target.value.trim();
                break;
        }

        setFormData({
            ...formData,
        
            // Trimming any whitespace
            [e.target.name]: targetData
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setAlertMessage({});
        setRequiredFields({});

        axios.post("user/reset-password/", formData).then((response) => {
            setIsValidCode(2);
            setMessage(response.data.message);
        }).catch((e) => {
            if(e.response.status === 400) {
                setAlertMessage({type:'danger', message:e.response.data.password[0]});
            }
            else if(e.response.status === 401) {
                setIsValidCode(2);
                setMessage('Invalid (or) Expired Link');
            }
            else{
                //hadle other errors
            }
        });
    };

    const renderElement = () => {
        if (isValidCode == 1) {
           return <section className="login-wrapper">
                <form onSubmit={ onSubmit }>
                <Notification {...alertMessage}/>
                    <div className="login-panel">
                        <div className="row">
                            <div className="col">
                                <h2>Reset Password</h2>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col">
                                <input type="password" name="password" onChange={handleChange} className="form-control" placeholder="New Password *" />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col">
                                <input type="password" name="password2" onChange={handleChange} className="form-control" placeholder="Confirm New Password *" />
                            </div>
                        </div>
                        <div className="col-12 text-center">
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </div>
                        <div className="col-12 mt-3">
                            <b>Password</b>
                            <ul>
                                <li>8-20 Characters</li>
                                <li>At least one capital letter</li>
                                <li>At least one number</li>
                                <li>At least one Special Character</li>
                            </ul>
                        </div>
                    </div>
                </form>
            </section>
        } else if (isValidCode == 2) {
            return <section className="container">
                <div className="about-section mt-5 mb-5">
                    <div className="secondary-title">
                        <h2>{ message }</h2>
                    </div>
                </div>
            </section>
        } else {
            return <section className="container">
                <div className="about-section mt-5 mb-5">
                    <div className="secondary-title">
                        <h2>Loading...</h2>
                    </div>
                </div>
            </section>
        }
    };

    return (
        <div className="container">
            { renderElement() }
        </div>
    )
}

export default ResetPassword;