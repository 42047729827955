import React from "react";

const isPermitted = (permission) => {
    let userPermission = JSON.parse(localStorage.getItem('userPermission', {}));
    return userPermission.is_owner || userPermission.permissions.includes(permission);
}

const isThisBuyer = () => {
    let userPermission = JSON.parse(localStorage.getItem('userPermission', {}));
    return userPermission.is_this_buyer;
}

const isThisSeller = () => {
    let userPermission = JSON.parse(localStorage.getItem('userPermission', {}));
    return userPermission.is_this_seller;
}

const isThisPo = () => {
    let userPermission = JSON.parse(localStorage.getItem('userPermission', {}));
    return userPermission.is_this_po;
}

const isThisAdmin = () => {
    let userPermission = JSON.parse(localStorage.getItem('userPermission', {}));
    return userPermission.is_owner;
}

const noPermissionTemplate = () => {
    return <div className="container mt-3">
        <div class="alert alert-warning">
            <h5><strong>No Permission!</strong></h5>
        </div>
    </div>
}

export {
    isPermitted,
    isThisBuyer,
    isThisSeller,
    isThisPo,
    isThisAdmin,
    noPermissionTemplate
};