import React, { useEffect } from 'react'
import { AuthTokenVerify } from "../reusable/AuthUtils";
import { PageToTop } from '../reusable/CommonUtils';
import { Link } from 'react-router-dom'

const Dashboard = ({ history }) => {

    useEffect(() => {
        AuthTokenVerify(history);
        PageToTop();
    }, []);


    return (
        <div className="container">
            <div className="dashboard-wrapper">
                <div className="dash-col">
                    <Link to="/profile">
                        <img src="/assets/images/icon-profile.png" alt="Profile" />
                        <h2>My Profile</h2>
                    </Link>
                </div>
                <div className="dash-col">
                    <Link to="/erfq-summary">
                        <img src="/assets/images/dashboard-erfq-icon.png" alt="EFRQ" />
                        <h2>E-RFQ</h2>
                    </Link>
                </div>
                <div className="dash-col">
                    <Link to="/equote-summary">
                        <img src="/assets/images/dashboard-equote-icon.png" alt="EQUOTE" />
                        <h2>E-QUOTE</h2>
                    </Link>
                </div>
                <div className="dash-col">
                    <a href="#">
                        <img src="/assets/images/dashboard-suppliers-icon.png" alt="Suppliers" />
                        <h2>Suppliers</h2>
                    </a>
                </div>
                <div className="dash-col">
                    <a href="#">
                        <img src="/assets/images/dashboard-clients-icon.png" alt="Clients" />
                        <h2>Clients</h2>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Dashboard