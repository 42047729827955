import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Navbar } from './header/index'



const Header = ({history}) => {

    const [toggleMenu, setToggleMenu] = useState('');

    const onClickToggleMenu = () => setToggleMenu(toggleMenu ? '' : 'show');

    return (
        <section className="sticky-navbar">
        <header className="container navbar-expand-md d-flex align-items-center justify-content-center flex-wrap py-4 logo-menu">
            <Link className="d-flex align-items-center mb-lg-0 me-lg-auto text-dark text-decoration-none" to="/"><img src="/assets/images/logo.png" alt="INAI" /></Link>

            <button className="navbar-toggler" type="button" onClick={ () => onClickToggleMenu() }>
                <img src="/assets/images/hamburger.svg" alt="Menu" />
            </button>
            <Navbar history={history} toggleMenu={toggleMenu} setToggleMenu={setToggleMenu} />
        </header>
    </section>
    )
}

export default Header