import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import axios from 'axios'
import { BrowserRouter as Router} from "react-router-dom";
import App from './App'

axios.defaults.baseURL = 'https://staging-siteservice.inaippl.com/';
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken', '');

const Routing = () => {
  return(
    <Router>
      <App/>
    </Router>
  )
}


ReactDOM.render(
  <React.StrictMode>
    <Routing />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
