import React, {useState,} from 'react'
import axios from 'axios'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Notification, { errorText, errorBorder } from '../reusable/Notification';
import { useEffect } from 'react';
import { PageToTop } from '../reusable/CommonUtils';

const Login = () => {

    useEffect(() => {
        PageToTop();
    }, []);

    const [ companyCode, setCompanyCode ] = useState('');
    const [ username, setUsername ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ requiredFields, setRequiredFields ] = useState({});
    const [ alertMessage, setAlertMessage ] = useState({});

    const navigate = useNavigate();
    const location = useLocation();

    const onSubmit = (e) => {
        e.preventDefault();
        setAlertMessage({});
        setRequiredFields({});
        doLogin();
    }

    const doLogin = () => {
        axios.post("auth/token/", {
            username: username,
            password: password,
            company_code: companyCode
        })
        .then((response) => {
            if(response.status === 200){
                localStorage.setItem('accessToken', response.data.access);
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken', '');

                axios.get("user/permissions/")
                .then((response) => {
                    localStorage.setItem('userPermission', JSON.stringify(response.data));
                    if(location.pathname == '/login'){
                        navigate('/');
                    }
                    window.location.reload();
                })
                .catch((e) => {
                    if(e.response.status === 400) {
                        let dict = {};
                        for (const [key, value] of Object.entries(e.response.data)) {
                            dict[key] = value.join(', ');
                        }
                        setRequiredFields(dict);
                        
                    }else if(e.response.status === 401) {
                        setAlertMessage({type:'danger', message:e.response.data.detail});
                    }else{
                        //hadle other errors
                    }
                });
            }
        })
        .catch((e) => {
            if(e.response.status === 400) {
                let dict = {};
                for (const [key, value] of Object.entries(e.response.data)) {
                    dict[key] = value.join(', ');
                }
                setRequiredFields(dict);
                
            }else if(e.response.status === 401) {
                setAlertMessage({type:'danger', message:e.response.data.detail});
            }else{
                //hadle other errors
            }
        });
    }

    const handleCompanyCode = (e) => {
        setCompanyCode(e.target.value)
    }

    const handleUsername = (e) => {
        setUsername(e.target.value)
    }

    const handlePassword = (e) => {
        setPassword(e.target.value)
    }

  
    return (
        <div className="container">
            <section className="login-wrapper">
                <form onSubmit={onSubmit}>
                <Notification {...alertMessage}/>
                <div className="login-panel">
                
                    <div className="row">
                        <div className="col">
                            <h2>Sign Into Your Account</h2>
                        </div>
                    </div>
                    
                    <div className="row mb-3">
                        <div className="col">
                            <input type="text" id="company_code" name="company_code" className={"form-control " + errorBorder(requiredFields.company_code) } placeholder="Client ID *" value={ companyCode } onChange={ handleCompanyCode }/>
                        </div>
                        { errorText(requiredFields.company_code) }
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <input type="text" id="username" name="username" className={"form-control " + errorBorder(requiredFields.username) } placeholder="User ID *" value={ username }  onChange={ handleUsername }/>
                        </div>
                        { errorText(requiredFields.username) }
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <input type="password" id="password" name="password" className={"form-control " + errorBorder(requiredFields.password) } placeholder="Password *" value={ password } onChange={ handlePassword }/>
                        </div>
                        { errorText(requiredFields.password) }
                    </div>
                    <div className="row mb-4 row-cols-1 row-cols-lg-2">
                        <div className="col-12 text-end">
                            Forgot <Link to="/forgot-clientid">Username</Link> / <Link to="/forgot-clientid">Client ID</Link> / <Link to="/forgot-password">Password?</Link>
                        </div>
                    </div>
                    <div className="mb-3 col-12 text-center">
                        <button type="submit" className="btn btn-primary">Login</button>
                    </div>
                    <div className="row col-12 text-center">
                        <span>Don't have an account? <Link to="/email-verify">Sign Up</Link></span>
                    </div>
                </div>
                </form>
            </section>
        </div>
    )
}

export default Login;