import React, { useEffect, useState } from "react";
import ReactPaginate from 'react-paginate';
import axios from "axios";
import { Link } from 'react-router-dom'
import { AuthTokenVerify, UpdateBearer } from "../../../reusable/AuthUtils";
import { PageToTop } from "../../../reusable/CommonUtils";
import { noPermissionTemplate, isPermitted } from "../../../reusable/PermissoinUtils";

const EmployeesList = ({history}) => {

    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [refreshList, setrefreshList] = useState(true);
    const [dataList, setDataList] = useState([]);
    const [noPermission, setNoPermission] = useState(false);

    const recordsPerPage = 15;

    useEffect(() => {
        AuthTokenVerify(history);
    }, []);

    const handlePageClick = (data) => {
        setPage(data.selected + 1);
        setrefreshList(true);
    }

    useEffect(() => {
        if(!refreshList) return;
        axios.get("user/employee/?page=" + page).then((response) => {
            setDataList(response.data.results);
            setPageCount(Math.ceil(response.data.count / recordsPerPage));
            UpdateBearer(history, response);
        }).catch((e) => {
            if(e.response.status === 404) {
                setPage(1);
                setrefreshList(true);
            }
            else if (e.response.status === 403) {
                //permission denied
                setNoPermission(true);
            }
        });
    setrefreshList(false);
    }, [refreshList]);

    const getDataList = () => {
        PageToTop();
        const content = [];
        dataList.map( (item, key) => {
            content.push(<tr key={key} >
                <td>{ (key + 1) + ( recordsPerPage * (page - 1) ) }</td>
                <td>{ item.username }</td>
                <td>{ item.email }</td>
                <td>{ item.first_name }</td>
                <td>{ item.last_name }</td>
                <td>{ item.employee_id }</td>
                <td>{ item.employee_mobile }</td>
                <td>{ item.is_active ? 'Active': 'Inactive' }</td>
                <td className="text-center">
                    
                    {(() => {
                        if(isPermitted('site_my_profile_emp_mgt_modify_empolyee')){
                            return <Link to={`/employee-edit/${item.emp_id}`} className="btn btn-primary">
                                <i className="bi bi-pencil-fill"></i>
                            </Link>
                        }

                        return <button  title="No permission"  type="button" className="btn btn-primary action-disabled">
                            <i className="bi bi-pencil-fill"></i>
                        </button>
                        
                    })()}
                </td>
            </tr>);
        });

        if(content.length < 1){
            content.push(<tr>
                <td colSpan="9" className="text-center">No records found</td>
            </tr>);
        }

        return content;
    };

    const showPagination = () => {
        if (pageCount <= 1) return;
        return <nav>
            <ReactPaginate
                pageClassName={'page-item'}
                previousClassName={'page-item active'}
                nextClassName={'page-item active'}
                pageLinkClassName={'page-link'}
                previousLinkClassName={'page-link'}
                nextLinkClassName={'page-link'}
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageCount}
                forcePage={page - 1}
                pageRangeDisplayed={recordsPerPage}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                activeClassName={'active'}
            />
        </nav>;
    }

    if(noPermission){
        return noPermissionTemplate()
    }

    return (
        <>
            <div className="mb-3 text-end">
                {(() => {
                    if(isPermitted('site_my_profile_emp_mgt_add_empolyee')){
                        return <Link className="button-style" to={'/employee-add'}>Add Employee</Link>
                    }
                    return <a title="No permission" className="button-style action-disabled">Add Employee</a>
                })()}
            </div>
            <div className="table-responsive row">
                <table className="table align-middle table-bordered table-design">
                    <thead>
                    <tr>
                        <td width={100}>Serial No.</td>
                        <td>User Name</td>
                        <td>Email</td>
                        <td>First Name</td>
                        <td>Last Name</td>
                        <td>Emp ID</td>
                        <td>Mobile</td>
                        <td>Status</td>
                        <td width={50} className="product-action">Action</td>
                    </tr>
                    </thead>
                    <tbody>
                        { getDataList() }
                    </tbody>
                </table>
            </div>
            { showPagination() }
        </>
    )
};

export default EmployeesList;