import React, { useState, useEffect, useRef } from "react";
import { confirm } from '../../reusable/Confirmation';
import axios from "axios";
import Notification, { errorText, errorBorder } from "../../reusable/Notification";
import NavigationMenu from "./NavigationMenu";
import { Modal } from "react-bootstrap";
import { AuthTokenVerify, UpdateBearer } from "../../reusable/AuthUtils";
import { PageToTop } from "../../reusable/CommonUtils";
import { noPermissionTemplate, isPermitted } from "../../reusable/PermissoinUtils";

const Documents = ({ history }) => {

    const initForm = {
        'file_name': '',
        'document': []
    };

    const [formData, setFormData] = useState(initForm);
    const [alertMessage, setAlertMessage] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [filedError, setFieldError] = useState({});
    const [records, setRecords] = useState([]);
    const [documentView, setDocumentView] = useState('');
    const [noPermission, setNoPermission] = useState(false);
    const fileInput = useRef(null);

    // for bootsrap model
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {

        AuthTokenVerify(history);

        axios.get("user/company-document-upload/").then((response) => {
            setRecords(response.data);
            UpdateBearer(history, response);
        }).catch((e) => {
            if (e.response.status === 403) {
                //permission denied
                setNoPermission(true);
            }
        });

        PageToTop();

    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        setAlertMessage({});
        setFieldError({});
        setDisableSubmit(true);

        let fData = new FormData();
        fData.append("file_name", formData.file_name);
        fData.append("document", formData.document);

        axios({
            method: "post",
            url: "user/company-document-upload/",
            data: fData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then((response) => {
                if (response.status === 200) {
                    setFormData(initForm);
                    setAlertMessage({ type: 'success', message: "Document Uploaded Successfully!" });
                    setDisableSubmit(false);
                    const newRecords = [...records];
                    newRecords.push(response.data);
                    setRecords(newRecords);
                    fileInput.current.value = '';
                }
            })
            .catch((e) => {
                if (e.response.status === 400) {
                    setAlertMessage({ type: 'danger', message: "Error Occured While Submit!" });
                    const dict = {};
                    for (const [key, value] of Object.entries(e.response.data)) {
                        dict[key] = value.join(', ');
                    }
                    setFieldError(dict);
                } else if (e.response.status === 406) {
                    setAlertMessage({ type: 'danger', message: "Already Reached Maximum Uploads!" });
                }

                setDisableSubmit(false);
            });
        PageToTop();
    };

    const handleChange = (e) => {

        var targetData = '';

        switch (e.target.name) {
            case "document":
                targetData = e.target.files[0];
                break;
            default:
                targetData = e.target.value;
                break;
        }

        setFormData({
            ...formData,
            [e.target.name]: targetData
        });
    };

    const deleteItem = async (key) => {
        const result = await confirm('Are you sure to delete this?');
        if (!result) return;

        clearForm();
        const pk = records[key].pk;
        axios.delete("user/company-document/" + pk + "/")
            .then((response) => {
                if (response.status === 200) {
                    setAlertMessage({ type: 'success', message: "Document Deleted Successfully!" });
                    const newRecords = [...records];
                    newRecords.splice(key, 1);
                    setRecords(newRecords);
                }
            })
            .catch((e) => {
                if (e.response.status === 400) {
                    setAlertMessage({ type: 'danger', message: "Error Occured While Delete!" });
                }
            });
        PageToTop();
    };

    const renderDocument = (key) => {
        axios.get("user/company-document-render/" + key + "/").then((response) => {
            setDocumentView("data:application/pdf;base64," + response.data);
            handleShow();
        }).catch((error) => {
            //
         });
    };

    const clearForm = () => {
        setAlertMessage({});
        setFieldError({});
        setFormData(initForm);
        fileInput.current.value = '';
    };

    const renderBody = () => {
        const content = [];

        records.map((item, key) => {
            content.push(<tr key={key} >
                <td>{key + 1}</td>
                <td>{item.file_name}</td>
                <td>{item.document.split("/").pop()}</td>
                <td>{item.created_on}</td>
                <td className="text-center">

                    {(() => {
                        if(isPermitted('site_my_profile_documents_view_document')){
                            return <button onClick={() => renderDocument(item.pk)} type="button" className="btn btn-success">
                                <i className="bi bi-eye-fill cursor-pointer"></i>
                            </button>
                        }
                        return <button  title="No permission"  type="button" className="btn btn-success action-disabled">
                            <i className="bi bi-eye-fill"></i>
                        </button>
                    })()}

                    {(() => {
                        if(isPermitted('site_my_profile_documents_delete_document')){
                            return <button onClick={() => deleteItem(key) } type="button" className="btn btn-danger ms-1">
                                <i className="bi bi-trash-fill"></i>
                            </button>
                        }
                        
                        return <button  title="No permission"  type="button" className="btn btn-danger ms-1 action-disabled">
                            <i className="bi bi-trash-fill"></i>
                        </button>
                    })()}
                </td>
            </tr>);
        });

        if (content.length < 1) {
            content.push(<tr>
                <td colSpan="5" className="text-center">No records found</td>
            </tr>);
        }

        return content;
    };

    if(noPermission){
        return (
        <div className="container">
            <div className="form-wrapper">
                <NavigationMenu item={ 5 }  />
                <div className="form-panel-wrapper">
                    { noPermissionTemplate() }
                </div>
            </div>
        </div>
        )
    }

    return (
        <div className="container">
            <Modal dialogClassName="modal-80" styl show={show} onHide={handleClose}>
                <Modal.Body>
                    <object>
                        <embed type="text/html" style={{ width: '100%', height: '85vh' }} src={documentView} />
                    </object>
                </Modal.Body>
            </Modal>
            <div className="form-wrapper">
                <NavigationMenu item={5} />
                <div className="form-panel-wrapper">
                    <Notification {...alertMessage} />
                    <fieldset disabled={!isPermitted('site_my_profile_documents_add_document')}>
                        <form onSubmit={onSubmit}>
                            <div className="form-panel">
                                <div className="row row-cols-1 row-cols-lg-2 mb-3 form-elements">
                                    <div className="col-12 form-column form-address-panel">
                                        <div className="row row-cols-1 row-cols-lg-3">
                                            <div className="col">
                                                <label className="form-label">File Name </label><sup className="text-danger">*</sup>
                                                <input type="text" className={"form-control " + errorBorder(filedError.file_name)} value={formData.file_name} onChange={handleChange} name="file_name" />
                                                {errorText(filedError.file_name)}
                                            </div>

                                            <div className="col">
                                                <label className="form-label">Documents (.pdf) <sup className="text-danger">*</sup></label>
                                                <div className="custom-file">
                                                    <input className={"form-control " + errorBorder(filedError.document)} ref={fileInput} onChange={handleChange} name="document" type="file" id="formFile" />
                                                    {errorText(filedError.document)}
                                                </div>
                                            </div>

                                            <div className="col">
                                                <label className="form-label w-100"></label>
                                                {(() => {
                                                    if(isPermitted('site_my_profile_documents_add_document')){
                                                        return <>
                                                            <a type="button" onClick={() => clearForm()} className="button-style">Clear</a>&nbsp;&nbsp;
                                                            <button type="submit" disabled={disableSubmit} className="button-style">Submit</button>
                                                        </>
                                                    }
                                                    return <button title="No permission" type="button" className="button-style action-disabled">Submit</button>
                                
                                                })()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </fieldset>
                    <div className="table-responsive row">
                        <table className="table align-middle table-bordered table-design">
                            <thead>
                                <tr>
                                    <td>Serial No.</td>
                                    <td>File Name</td>
                                    <td>Document</td>
                                    <td>Uploaded On</td>
                                    <td>Action</td>
                                </tr>
                            </thead>
                            <tbody>
                                {renderBody()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Documents;