import React, { useState, useEffect } from "react";
import axios from "axios";
import Notification from "../../reusable/Notification";
import NavigationMenu from "./NavigationMenu";
import { Modal } from "react-bootstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { AuthTokenVerify, UpdateBearer } from "../../reusable/AuthUtils";
import { PageToTop } from "../../reusable/CommonUtils";
import { noPermissionTemplate, isPermitted } from "../../reusable/PermissoinUtils";

const ApprovalStatus = ({history}) => {

    const [ alertMessage, setAlertMessage ] = useState({});
    const [ modalContent, setModalContent ] = useState({title: '', content: ''});
    const [ modalContent1, setModalContent1 ] = useState('');
    const [ isAgree, setIsAgree ] = useState(false);
    const [ approvalFor, setApprovalFor ] = useState(0);
    const [ approvalStatus, setApprovalStatus ] = useState({});
    const [noPermission, setNoPermission] = useState(false);

    // for bootsrap model
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    useEffect(() => {
        //ReactTooltip.rebuild();
    });

    useEffect(() => {

        AuthTokenVerify(history);

        axios.get("user/company-approval/").then((response) => {
            let dict = {}
            for (const [key, value] of Object.entries(response.data)) {
                dict[value.approval_type] = value
            }
            setApprovalStatus(dict);
            UpdateBearer(history, response);
        }).catch((e) => {
            if (e.response.status === 403) {
                //permission denied
                setNoPermission(true);
            }
         });

        PageToTop();
    }, []);

    const submitForApproval = (i) => {
        assignModalContent(i);
        handleShow();
    };

    const assignModalContent = (i) => {
        let modalCnt = {title: '', content: ''};

        setIsAgree(false);
        setApprovalFor(i);
        
        switch (i) {
            case 4:
                modalCnt.title = "Buyer - Services";
                modalCnt.content = <>
                    <p>I hereby declare that all the information given by me is correct, true and valid to the best of my knowledge and belief. I have uploaded the required supporting documents for your reference.</p>

                    <p>I also understand that I will be required to provide any other related information or documents requested by INAI as and when the same is called for. I also understand that the approval provided to me will be cancelled if at any time the details submitted by me are found to be incorrect or misleading.</p>
                </>;
                break;
            case 3:
                modalCnt.title = "Buyer - Goods";
                modalCnt.content = <>
                    <p>I hereby declare that all the information given by me is correct, true and valid to the best of my knowledge and belief. I have uploaded the required supporting documents for your reference.</p>

                    <p>I also understand that I will be required to provide any other related information or documents requested by INAI as and when the same is called for. I also understand that the approval provided to me will be cancelled if at any time the details submitted by me are found to be incorrect or misleading.</p>
                </>;
                break;
            case 2:
                modalCnt.title = "Supply for Services";
                modalCnt.content = <>
                    <p>I hereby declare that all the information given by me is correct, true and valid to the best of my knowledge and belief. I have uploaded the required supporting documents for your reference.</p>

                    <p>I also understand that I will be required to provide any other related information or documents requested by INAI as and when the same is called for. I also understand that the approval provided to me will be cancelled if at any time the details submitted by me are found to be incorrect or misleading.</p>
                </>;
                break;
            default:
                modalCnt.title = "Supply for Goods";
                modalCnt.content = <>
                    <p>I hereby declare that all the information given by me is correct, true and valid to the best of my knowledge and belief. I have uploaded the required supporting documents for your reference.</p>

                    <p>I also understand that I will be required to provide any other related information or documents requested by INAI as and when the same is called for. I also understand that the approval provided to me will be cancelled if at any time the details submitted by me are found to be incorrect or misleading.</p>
                </>;
                break;
        }

        setModalContent(modalCnt);
    };

    const submitForApprovalConfirm = () => {
        if(!isAgree){ return; } 

        setAlertMessage({});

        axios.post("user/company-approval/", {approval_for: approvalFor})
        .then((response) => {
            const data = response.data;
            approvalStatus[data.approval_type] = data;
            setApprovalStatus(approvalStatus);
            setAlertMessage({type:'success', message:"Submitted for Approval"});
            handleClose();
        })
        .catch((e) => {
            if(e.response.status === 400) {
                setAlertMessage({type:'danger', message:"Error Occured While Submit!"});
            }
            handleClose();
        });

        PageToTop();
    };

    const handleClickAgree = (e) => {
        setIsAgree(e.target.checked);
    };

    const showModal = (message) => {
        if (!message){ return; }
        handleShow1();
        setModalContent1(message);
    };

    const getStatus = (i) => {
        let record = approvalStatus[i];
        let html = '';
        let message = '';
        let alert = '';
        let disabled = false;
        let show_comments = '';

        if(record){
            
            switch (record.approval_status) {
                case 4:
                    message = 'Renewal';
                    alert = 'danger';
                    show_comments = <i className="bi bi-question-circle-fill"></i>;
                    record.comments = 'Resubmit your documents';
                    break;
                case 3:
                    message = 'Rejected';
                    alert = 'danger';
                    show_comments = <i className="bi bi-question-circle-fill"></i>;
                    break;
                case 2:
                    message = 'Approved';
                    alert = 'success';
                    disabled = true;
                    break;
                default:
                    message = 'Submitted for Approval';
                    alert = 'warning';
                    disabled = true;
                    break;
            }

            html = <div onClick={() => showModal(record.comments)} data-tip={ record.comments } className={"col alert alert-" + alert} role="alert">
                {message} { show_comments } 
            </div>
        }
        

        return <>
        <div className="col">
            {(() => {
                if(isPermitted('site_my_profile_approval_status_approval_action')){
                    return <button onClick={() => submitForApproval(i)} disabled={disabled} className="btn btn-primary submit-btn">Submit for Approval</button>
                }
                return <button title="No permission" className="btn btn-primary submit-btn action-disabled">Submit for Approval</button>
            })()}
        </div>
        { html }
        </>;
    }

    if(noPermission){
        return (
        <div className="container">
            <div className="form-wrapper">
                <NavigationMenu item={ 6 }  />
                <div className="form-panel-wrapper">
                    { noPermissionTemplate() }
                </div>
            </div>
        </div>
        )
    }

    return (
        <div className="container">
            <Modal dialogClassName="modal-70" show={show} onHide={handleClose}>
                <Modal.Header><h2>Terms & Conditions of {modalContent.title}</h2></Modal.Header>
                <Modal.Body>
                    {modalContent.content}
                    <div className="form-group form-check">
                        <input type="checkbox" checked={isAgree} className="form-check-input" id="agree_box" onClick={handleClickAgree}/>
                        <label className="form-check-label" for="agree_box"><b>I Agree</b></label>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <button onClick={handleClose} className="btn btn-danger">Cancel</button>
                <button className="btn btn-primary" disabled={!isAgree} onClick={ () => submitForApprovalConfirm() }>Submit for Approval</button>
                </Modal.Footer>
            </Modal>
            <Modal show={show1} onHide={handleClose1}>
                <Modal.Body>
                <h5>{ modalContent1 }</h5>
                </Modal.Body>
                <Modal.Footer>
                <button onClick={handleClose1} className="btn btn-danger">Cancel</button>
                </Modal.Footer>
            </Modal>
            <div className="form-wrapper">
                <NavigationMenu item={ 6 } />
                <div className="form-panel-wrapper">
                    <Notification {...alertMessage}/>
                    <div className="row mb-4 row-cols-1 row-cols-lg-3 approval-panel">
                        <div className="col">
                            Supply for Goods
                        </div>
                        { getStatus(1) }
                    </div>

                    <div className="row mb-4 row-cols-1 row-cols-lg-3 approval-panel">
                        <div className="col">
                            Supply for Services
                        </div>
                        { getStatus(2) }
                    </div>

                    <div className="row mb-4 row-cols-1 row-cols-lg-3 approval-panel">
                        <div className="col">
                            Buyer - Goods
                        </div>
                        { getStatus(3) }
                    </div>

                    <div className="row mb-4 row-cols-1 row-cols-lg-3 approval-panel">
                        <div className="col">
                            Buyer - Services
                        </div>
                        { getStatus(4) }
                    </div>

                    <div className="row mb-4 row-cols-1 row-cols-lg-3 approval-panel">
                        <div className="col">
                            PO
                        </div>
                        { getStatus(5) }
                    </div>
                </div>
                <ReactTooltip className={"tooltip"} />
            </div>
        </div>
    );
};

export default ApprovalStatus;