import React, {useState, useEffect} from 'react';
import { useLocation } from "react-router-dom";
import axios from 'axios';
import Notification, { errorText, errorBorder } from '../reusable/Notification';
import { PageToTop } from '../reusable/CommonUtils';
import ReCAPTCHA from "react-google-recaptcha";
import Config from '../reusable/Config';

const EmailVerify = () => {
    const search = useLocation().search;
    const recaptchaRef = React.createRef();

    const [ email, setEmail ] = useState('');
    const [ recaptcha, setRecaptcha ] = useState('');
    const [ filedError, setFieldError ] = useState({});
    const [ alertMessage, setAlertMessage ] = useState({});
    const [ reference, setReference ] = useState('');


    useEffect(() => {
        const vrReference = new URLSearchParams(search).get('reference');
        setReference(vrReference);
        PageToTop();
    }, []);
    

    const onSubmit = (e) => {

        e.preventDefault();
        setAlertMessage({});
        setFieldError({});
        doEmailVerify();
    }

    const handleRecaptcha = (value) => {
        setRecaptcha(value);
        setFieldError({});
    };

    const handleEmail = (e) => {
        setEmail(e.target.value);
    }

    const doEmailVerify = () => {
        let tempEmail = email;
        axios.post("user/email-verify/", {
            email: email,
            reference: reference,
            recaptcha: recaptcha
        })
        .then((response) => {
            setAlertMessage({
                type:'success',
                message:<>Check your email "<b>{ tempEmail }</b>" to complete the registration. Check your Junk/spam folder. Add <b>noreply@inaippl.com</b> to your email address book to avoid notification emails going to the spam folder</>
            });
            setEmail('');
        })
        .catch((e) => {
            if(e.response.status === 400) {
                setFieldError(e.response.data);
            }else{
                //hadle other errors
            }
        });

        recaptchaRef.current.reset();
    }

    return (
        <div className="container">
            <section className="login-wrapper">
                <form onSubmit={ onSubmit }>
                <Notification {...alertMessage}/>
                    <div className="login-panel">
                        <div className="row">
                            <div className="col">
                                <h2>Verify Your Email</h2>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col">
                                <input type="email" value={email} onChange={ handleEmail } className={"form-control " + errorBorder(filedError.email) } placeholder="Enter Your Email *" />
                            </div>
                            { errorText(filedError.email) }
                        </div>
                        <div className="row">
                            <div className="col">
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={ Config.captchaSiteKey }
                                onChange={ handleRecaptcha }
                            />
                            { errorText(filedError.recaptcha) }
                            </div>
                            <div className="col">
                                <div className="col text-center">
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        </div>
    )
}

export default EmailVerify