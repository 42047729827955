import React, { useState, useEffect } from "react";
import axios from "axios";
import { AuthTokenVerify, UpdateBearer } from "../../reusable/AuthUtils";
import Notification from '../../reusable/Notification';
import ReactPaginate from 'react-paginate';
import { PageToTop, tryParseJSONObject } from "../../reusable/CommonUtils";
import { Link, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import { noPermissionTemplate, isPermitted } from "../../reusable/PermissoinUtils";

import "react-datepicker/dist/react-datepicker.css";


const EquoteSummary = ({history}) => {

    const params = new URLSearchParams(useLocation().search);
    const paramPage = params.get('page') ? params.get('page') : 1;
    const paramFilter = tryParseJSONObject(params.get('filter')) ? JSON.parse(params.get('filter')) : {};

    const [noPermission, setNoPermission] = useState(false);

    const [refreshList, setrefreshList] = useState(true);
    const [page, setPage] = useState(paramPage);
    const [pageCount, setPageCount] = useState(0);

    const [companyErfqList, setCompanyErfqList] = useState([]);
    const [filter, setFilter] = useState(paramFilter);
    const [currencies, setCurrencies] = useState([]);

    const availStatus = {1: 'Active', 2: 'Inactive', 4: 'Closed'};
    const availParticipantStatus = {1: 'Requested', 2: 'Accepted', 3: 'Rejected'};

    const recordsPerPage = 15;

    useEffect(() => {
        AuthTokenVerify(history);
        PageToTop();
    }, []);

    useEffect(() => {
        axios.get("common/currencies/").then((response) => {
            setCurrencies(response.data);
        }).catch((error) => {
            //
        });
    }, []);

    useEffect(() => {
        history(`/equote-summary?page=${page}&filter=${JSON.stringify(filter)}`)
    }, [refreshList]);

    useEffect(() => {
        if(!refreshList) return;
        axios.get(`erfq/equote-list/?page=${page}&filter=${encodeURIComponent(JSON.stringify(filter))}`).then((response) => {
            setCompanyErfqList(response.data.results);
            setPageCount(Math.ceil(response.data.count / recordsPerPage));
            UpdateBearer(history, response);
        }).catch((e) => {
            if(e.response.status === 403) {
                //permission denied
                setNoPermission(true);
            }
        });
    setrefreshList(false);
    }, [refreshList]);

    const filterHandle = (e) => {
        var targetData = '';

        switch (e.target.name) {
            default:
                targetData = e.target.value;
                break;
        }

        setFilter({...filter, [e.target.name] : targetData});
        filterHandleTrigger();
    };

    const filterHandleTrigger = () => {
        setrefreshList(true);
        setPage(1);
    };


    const getList = () => {
        const content = [];
        companyErfqList.map( (row, key) => {
            content.push(<tr key={key} >
                <td>{ (key + 1) + ( recordsPerPage * (page - 1) ) }</td>
                <td>{ row.status_read }</td>
                <td>{ row.reference_no }</td>
                <td>{ row.start_date_time }</td>
                <td>{ row.end_date_time }</td>
                <td>{ row.subject }</td>
                <td>{ row.pr_number }</td>
                <td>{ row.rfq_number }</td>
                <td>{ row.currency_read }</td>
                <td className="text-end">{ isNaN(row.bidding_net_total) ? row.bidding_net_total : Number(row.bidding_net_total).toFixed(2) }</td>
                <td>{ row.bidding_position }</td>
                <td>{ row.participant_status_read }</td>
                <td className="action-icons">
                
                {(() => {
                    if (row.participant_status==3){
                        return null;
                    }

                    if(isPermitted('site_quote_view_rfq')){
                        return <Link to={"/equote-view/" + row.pk} className="btn btn-success">
                            <i className="bi bi-eye-fill cursor-pointer"></i>
                        </Link>
                    }
                    return <button  title="No permission"  type="button" className="btn btn-success action-disabled">
                        <i className="bi bi-eye-fill"></i>
                    </button>
                    })()}
                </td>
            </tr>);
        });

        if(content.length < 1){
            content.push(<tr>
                <td colSpan="13" className="text-center">No records found</td>
            </tr>);
        }

        return content;
    };

    const showPagination = () => {
        if (pageCount <= 1) return;
        return <nav>
            <ReactPaginate
                pageClassName={'page-item'}
                previousClassName={'page-item active'}
                nextClassName={'page-item active'}
                pageLinkClassName={'page-link'}
                previousLinkClassName={'page-link'}
                nextLinkClassName={'page-link'}
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageCount}
                forcePage={page - 1}
                pageRangeDisplayed={recordsPerPage}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                activeClassName={'active'}
            />
        </nav>;
    };

    const handlePageClick = (data) => {
        setPage(data.selected + 1);
        setrefreshList(true);
    };

    if(noPermission){
        return noPermissionTemplate();
    }

    return (
        <div className="container">
        <div className="form-wrapper summary-container">
            <div className="title-section mb-4">
                <h2>Your EQUOTE</h2>
            </div>
            <div className="table-responsive row">
                <table className="table align-middle table-bordered table-design">
                    <thead>
                        <tr>
                            <td colSpan={2}>
                                <select name="status" value={filter['status'] ? filter['status'] : ""} onChange={filterHandle} className="form-control">
                                    <option value={""}>--status--</option>
                                    {
                                        Object.keys(availStatus).map((key, index) => ( 
                                            <option value={key}>{ availStatus[key] }</option>
                                        ))
                                    }
                                </select>
                            </td>
                            <td><input type="text" style={{width: 'auto'}} className="form-control" onChange={filterHandle} name="ref_no" value={filter['ref_no'] ? filter['ref_no'] : ""} /></td>
                            <td>
                                <DatePicker
                                    className={"form-control"}
                                    dateFormat="dd/MM/yyyy"
                                    selected={filter.start_date}
                                    onChange={(date) => {
                                        setFilter({...filter, 'start_date' : date});
                                        filterHandleTrigger();
                                    }}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                />
                            </td>
                            <td>
                                <DatePicker
                                    className={"form-control"}
                                    dateFormat="dd/MM/yyyy"
                                    selected={filter.end_date}
                                    onChange={(date) => {
                                        setFilter({...filter, 'end_date' : date});
                                        filterHandleTrigger();
                                    }}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                />
                            </td>
                            <td><input type="text" style={{width: 'auto'}} className="form-control" onChange={filterHandle} name="subject" value={filter['subject']  ? filter['subject'] : "" }  /></td>
                            <td><input type="text" style={{width: 'auto'}} className="form-control" onChange={filterHandle} name="pr_number" value={filter['pr_number']  ? filter['pr_number'] : ""} /></td>
                            <td><input type="text" style={{width: 'auto'}} className="form-control" onChange={filterHandle} name="rfq_number" value={filter['rfq_number']  ? filter['rfq_number'] : ""} /></td>
                            <td>
                                <select name="currency" style={{width: 'auto'}} value={filter['currency'] ? filter['currency'] : ""} onChange={filterHandle} className="form-control">
                                    <option value={""}>--currency--</option>
                                    {
                                        currencies.map((row, key) => ( 
                                            <option value={row.pk}>{ row.code }</option>
                                        ))
                                    }
                                </select>
                            </td>
                            <td><input type="text" style={{width: 'auto'}} className="form-control" onChange={filterHandle} name="bid_amt" value={filter['bid_amt']  ? filter['bid_amt'] : "" }  /></td>
                            <td><input type="text" style={{width: 'auto'}} className="form-control" onChange={filterHandle} name="position" value={filter['position']  ? filter['position'] : "" }  /></td>
                            <td><select style={{width: 'auto'}}  name="participant_status" value={filter['participant_status'] ? filter['participant_status'] : ""} onChange={filterHandle} className="form-control">
                                    <option value={""}>--status--</option>
                                    {
                                        Object.keys(availParticipantStatus).map((key, index) => ( 
                                            <option value={key}>{ availParticipantStatus[key] }</option>
                                        ))
                                    }
                                </select></td>
                            <td><button className="btn btn-danger" onClick={() => { setFilter({}); filterHandleTrigger(); }}><i class="bi bi-bootstrap-reboot"></i></button></td>
                        </tr>
                        <tr>
                            <td className="sno-width">S.No</td>
                            <td className="status-width">Status</td>
                            <td className="ref-width">Ref No</td>
                            <td className="dates-width">Start Date & Time</td>
                            <td className="dates-width">End Date & Time</td>
                            <td className="subject-width">Subject</td>
                            <td className="prno-width">PR. No.</td>
                            <td className="prno-width">RFQ No.</td>
                            <td>Currency</td>
                            <td className="ref-width">Bidding Amt</td>
                            <td className="ref-width">Current Position</td>
                            <td>Accept</td>
                            <td className="status-width">Action</td>
                        </tr>
                    </thead>
                    <tbody>
                        { getList() }
                    </tbody>
                </table>
                { showPagination() }
            </div>
        </div>
        </div>
    );
};

export default EquoteSummary;