import React,{useState} from "react";
import NavigationMenu from "./NavigationMenu";
import Notification from "../../reusable/Notification";
import { isThisAdmin } from "../../reusable/PermissoinUtils";
import {DepartmentEdit,DepartmentList} from "./Department/index";


const Department = ({ history ,rComp}) => {
     
   

    const [alertMessage, setAlertMessage] = useState({});

   
    

    const render = () => {
        let returnComp = null;
        switch (rComp) {
            case "add":
                returnComp = <DepartmentEdit setAlertMessage={setAlertMessage}/>;
                break;
            default:
                returnComp =  <DepartmentList setAlertMessage={setAlertMessage}/> ;
                break;
        }
        return returnComp;
    };


return (
    <div className="container">
        <div className="form-wrapper">
            <NavigationMenu item={ 9 } />
            <div className="form-panel-wrapper">
                <Notification {...alertMessage}/>
                { render() }
            </div>
        </div>
    </div>
)
};

export default Department;
