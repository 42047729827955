import { useEffect } from "react";
import { PageToTop } from "../reusable/CommonUtils";

const Faq = () => {

    const faq = [
        {
            'q': "Why do we need to fill in all the details in the portal?",
            'a': "A portal is a place which presents users with the most relevant information for their context. To let the world know about your company profile, it is wise to enter all the details about your company.",
        },
        {
            'q': "Is registration mandatory for portal usage? Is it free of cost? Is there any pre-requisite for registrations?",
            'a': "Yes, a one-time registration is mandatory for portal usage where in user is expected to enter a valid authorized email address and a registered mobile number. It is 100% free of cost. Any company can register and utilise the website for their procurement or promote the products.",
        },
        {
            'q': "How can I register on the portal?",
            'a': "A valid email address and a registered mobile number are needed for registration. As a first step, you are requested to enter a valid email address for signing up. An activation link will be sent to your mail address for verification. Upon clicking the link, you will be taken to the registration page. The details like Name, User id, Mobile number, and Contact details have to be entered here. An OTP will be sent to your registered mobile number and upon confirmation, your registration process will be complete.",
        },
        {
            'q': "Will the website be available all the time?",
            'a': "Yes, the website is available round the clock - 24 X 7 X 365 days across the world.",
        },
        {
            'q': "How secure is the portal?",
            'a': "The Portal is designed, developed and delivered as a highly secure SaaS to our clients. We have strong Authentication and Authorization process along with valid retention policies. We follow a 2-step verification process even before getting registered at INAI.",
        },
        {
            'q': "Is there any option to alter the email address which we gave during the first time registration?",
            'a': "Yes, you have to send an e-mail from the 1st registered e-mail and it will take 2 to 3 days to update after which the new email address can be used.",
        },
        {
            'q': "What if we forgot the INAI user id, Client id or portal password? Is there a way to reset it? Also, how frequently should we change the password?",
            'a': "Yes, there is a link provided on the login page which needs to be clicked if the user forgets the Username, Client Id or Password as appropriately.",
        },
    ];

    useEffect(() => {
        PageToTop();
    }, []);

    const showFaq = () => {
        return faq.map((qa, index) => {
            return <div className="accordion-item">
            <h2 className="accordion-header" id={"question" + index}>
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + index} aria-expanded="false" aria-controls="collapseOne">
                { qa.q } 
                </button>
            </h2>
            <div id={"collapse" + index} className="accordion-collapse collapse" aria-labelledby={"question" + index} data-bs-parent="#accordionExample">
                <div className="accordion-body">
                    <p>{ qa.a }</p>
                </div>
            </div>
        </div>;
        });
    };

    return (
        <>
            <section className="container">
                <div className="about-section">
                    <div className="section-title">
                        <h2><img src="/assets/images/title-divider.png" /> FAQ <img src="/assets/images/title-divider.png" /></h2>
                    </div>
                    <div className="faq-wrapper">
                        <div className="accordion" id="accordionExample">
                            { showFaq() }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Faq