import React, { useEffect, useState } from 'react'
import { AuthTokenVerify, UpdateBearer } from "../../reusable/AuthUtils";
import { PageToTop } from '../../reusable/CommonUtils';
import { useParams } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import axios from "axios";
import { Tooltip as ReactTooltip } from "react-tooltip";


const CompanyProfile = ({ history }) => {

    let { id } = useParams();

    const [data, setData] = useState(false);
    const [companyProducts, setCompanyProducts] = useState([]);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const recordsPerPage = 15;

    useEffect(() => {
        AuthTokenVerify(history);
        PageToTop();
    }, []);

    useEffect(() => {
        //ReactTooltip.rebuild();
    });

    useEffect(() => {
        axios.get("user/company-profile/" + id + "/").then((response) => {
            setData(response.data);
            UpdateBearer(history, response);
        }).catch((e) => {
            UpdateBearer(history, e.response);
        });
    }, []);

    useEffect(() => {
        axios.get("products/company-profile-products/" + id + "/?page=" + page).then((response) => {
            setCompanyProducts(response.data.results);
            setPageCount(Math.ceil(response.data.count / recordsPerPage));
            UpdateBearer(history, response);
        }).catch((e) => {
            if (e.response.status === 404) {
                setPage(1);
            }
        });
    }, [page]);

    const showBusinessNature = () => {
        let rdata = [];
        data.business_nature.map((item, key) => {
            rdata.push(item.business_nature);
        });
        return rdata.join(', ');
    };

    const handlePageClick = (data) => {
        setPage(data.selected + 1);
    };

    const showPagination = () => {
        if (pageCount <= 1) return;
        return <nav>
            <ReactPaginate
                pageClassName={'page-item'}
                previousClassName={'page-item active'}
                nextClassName={'page-item active'}
                pageLinkClassName={'page-link'}
                previousLinkClassName={'page-link'}
                nextLinkClassName={'page-link'}
                previousLabel={'«'}
                nextLabel={'»'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageCount}
                forcePage={page - 1}
                pageRangeDisplayed={recordsPerPage}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                activeClassName={'active'}
            />
        </nav>;
    };

    const getProducts = () => {
        const content = [];
        companyProducts.map((item, key) => {
            var brands = item.brands.map(brands => { return brands.label }).join(', ');
            var keywords = item.keywords.map(keywords => { return keywords.label }).join(', ');
            var certificates = item.certificates.map(certificates => { return certificates.label }).join(', ');
            var credentials = item.credentials.map(credentials => { return credentials.label }).join(', ');
            var industries = item.industries.map(industries => { return industries.label }).join(', ');

            content.push(<tr key={key} >
                <td>{(key + 1) + (recordsPerPage * (page - 1))}</td>
                <td>{item.product_read}</td>
                <td>{brands}</td>
                <td data-tip={item.description}>{item.description}</td>
                <td data-tip={keywords}>{keywords}</td>
                <td>{item.business_type_read}</td>
                <td>{industries}</td>
                <td>{credentials}</td>
                <td>{certificates}</td>
            </tr>);
        });

        if (content.length < 1) {
            content.push(<tr>
                <td colSpan="9" className="text-center">No records found</td>
            </tr>);
        }

        return content;
    };

    const render = () => {
        if (data) {
            return (
                <div className="form-wrapper">
                    <div className="card">
                        <div className="card-header">
                            {data.company_name} <span className="badge  bg-primary">{data.customer_id}</span>
                        </div>
                        <div className="card-body form-panel-wrapper">
                            <div className="row inai-info-background">
                                <div className="col-md-3 inai-border-right ps-md-0">
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <label className="form-label"><b>Nature of Business : </b>{showBusinessNature()}</label>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <label className="form-label"><b>PAN : </b>{data.pan}</label>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <label className="form-label"><b>TAN : </b>{data.tan}</label>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <label className="form-label"><b>GSTIN : </b>{data.gstin_vat}</label>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <label className="form-label"><b>CIN : </b>{data.cin}</label>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-4 inai-border-right ps-md-3">
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <label className="form-label"><b>Legal Status of Firm : </b>{data.legal_status_read}</label>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <label className="form-label"><b>Nearest Seaport : </b>{data.nearest_seaport}</label>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <label className="form-label"><b>Annual Turn Over for Last Year (In Lakhs) : </b>{data.anual_turnover}</label>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <label className="form-label"><b>Year of Establishment : </b>{data.established_on}</label>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <label className="form-label"><b>Nearest Airport : </b>{ data.nearest_airport }</label>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <label className="form-label"><b>Number of Employees : </b>{ data.no_of_emp }</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 ps-md-3 pe-md-0">
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <label className="form-label"><b>Registered Office Address : </b>{data.reg_street_name}, {data.reg_city} - {data.reg_pincode}, {data.reg_state_read}, {data.reg_country_read}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <label className="form-label"><b>Factory Address : </b>{data.fact_street_name}, {data.factory_city} -{data.factory_pincode}, {data.factory_state_read}, {data.factory_country_read}</label>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <label className="form-label"><b>Website Address : </b>{data.web_site}</label>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <label className="form-label"><b>Country : </b>{data.country_read} | <b>Email : </b>{data.email} | <b>Phone Number : </b>{data.phone}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="table-responsive row">
                                <label className="subtitle">Products</label>
                                <div className="p-2">
                                    <table className="table align-middle table-design inai-table table-striped table-hover table-borderless shadow mb-3 bg-white rounded">
                                        <thead>
                                            <tr>
                                                <td width={60}>S No.</td>
                                                <td width={200}>Product</td>
                                                <td>Brand / Make</td>
                                                <td width={200}>Description</td>
                                                <td>Keywords</td>
                                                <td width={150}>Type of Business</td>
                                                <td>Industry</td>
                                                <td>Key Clients</td>
                                                <td>Certificates</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getProducts()}
                                        </tbody>
                                    </table>
                                    {showPagination()}
                                    <ReactTooltip className={"tooltip"} />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            )
        }

        return (
            <div className="form-panel-wrapper">
                No Records found
            </div>
        );

    };

    return (
        <div className="container">
            {render()}
        </div>
    )
}

export default CompanyProfile