import React, { useState, useEffect } from "react"
import { Link } from 'react-router-dom'


const NavigationMenu = ({item=1}) => {

    const [ activeItem, setActiveItem ] = useState(item);

    const getActiveItem = (i) => activeItem === i ? "tablink active" : "tablink";

    return(
        <div className="form-headings-wrapper">
            <ul>
                <li><Link to="/profile" className={ getActiveItem(1) }>company details</Link></li>
                <li><Link to="/contacts" className={ getActiveItem(2) }>contact details</Link></li>
                <li><Link to="/branches" className={ getActiveItem(3) }>branch details</Link></li>
                <li><Link to="/products" className={ getActiveItem(4) }>product portfolio</Link></li>
                <li style={{width: '5.938rem'}} className="doc"><Link to="/documents" className={ getActiveItem(5) } style={{marginLeft: '-11px'}}>documents</Link></li>
                <li><Link to="/approvals" className={ getActiveItem(6) }>approval status</Link></li>
                <li><Link to="/employees" className={ getActiveItem(7) }>employee management</Link></li>
                <li><Link to="/change-password" className={ getActiveItem(8) }>change password</Link></li>
                <li><Link to="/department-management" className={ getActiveItem(9) }>Department</Link></li>
                <li><Link to="/address-book" className={ getActiveItem(10) }>Address Book</Link></li>
            </ul>
        </div>
    );
}

export default NavigationMenu;