import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { PageToTop } from '../reusable/CommonUtils';

const Home = () => {

    const [statistics, setStatistics] = useState({
        'total_reg_comp': 0,
        'total_erfq': 0,
    });

    useEffect(() => {
        PageToTop();
        axios.get("common/site_statistics/").then((response) => {
            setStatistics(response.data);
        }).catch((e) => {
            //
        });
    }, []);

    return (
        <>
            <section className="banner-wrapper">
                <div className="container">
                    <div className="banner-panel row-cols-1">
                        <div className="banner-left text-center">
                            <h2>Communication is the heart of Business.<br />Stay connected with a Right<br />Supplier and Buyer!!</h2><br/>
                            <Link to="/email-verify" className="button-style">Register</Link>
                        </div>
                        <div className="banner-right">
                            <img src="/assets/images/home-banner-img.png" alt="INAI" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="container">
                <div className="about-section">
                    <div className="section-title">
                        <h2><img src="/assets/images/title-divider.png" /> About US <img src="/assets/images/title-divider.png" />
                        </h2>
                    </div>
                    <div className="secondary-title">
                        <h2>Together We'll Explore New Things</h2>
                    </div>
                    <div className="row row-cols-1">
                        <div className="col-lg-6 home-about-img"><img src="/assets/images/home-about-img.png" alt="INAI" className="img-fluid" /></div>
                        <div className="col-lg-6 home-about-cont">
                        <p>INAI platform plays a major role in bringing suppliers and buyers together over the internet, and allows buyers to share information to all the suppliers seamlessly.</p>

                        <p>Buyers can instantly respond to the queries posted by the suppliers over the online portal which can be easily referred by the other suppliers related to that specific product/item. They can even view the status of the procurement process at any point of time.</p>

                        <p>Suppliers on INAI can enjoy transparency. They can query a buyer, and get responses immediately. Questions asked by other suppliers (and the answers provided by the buyer), are also visible, enabling everyone to view the information to submit their best offer.</p>

                        <p>The security of our website architecture ensures that all bidding processes are conducted smoothly, fairly and securely.</p>

                        <p>In short, INAI is a perfect medium of communication which enables the clients to focus on taking the right decisions pertaining to their bid.</p>

                        <p className="read-more"><Link to="/about-us">Read More</Link></p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="services-section">
                <div className="container">
                    <div className="section-title">
                        <h2><img src="/assets/images/title-divider.png" /> What we do <img src="/assets/images/title-divider.png" />
                        </h2>
                    </div>
                    <div className="secondary-title">
                        <h2>We Provide Exclusive Services for your Business</h2>
                    </div>
                    <div className="row row-cols-1">
                        <div className="col-lg-6 home-service-cont">
                            <div className="service-col">
                                <img src="/assets/images/erfq-icon.png" alt="E-RFQ" />
                                <h2>E-RFQ</h2>
                                <p>INAI E-RFQ is a standardized digital platform that enables an organization to procure the best product / service...</p>
                                <Link to="/about-us">Read more</Link>
                            </div>
                            {/*<div className="service-col">
                                <img src="/assets/images/eaction-icon.png" alt="E-Auction" />
                                <h2>E-Auction</h2>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
                                <a href="javascript:;">Read more</a>
                            </div>
                            <div className="service-col">
                                <img src="/assets/images/esourcing-icon.png" alt="E-Sourcing" />
                                <h2>E-Sourcing</h2>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
                                <a href="javascript:;">Read more</a>
                            </div>
                            <div className="service-col">
                                <img src="/assets/images/edata-icon.png" alt="E-Data" />
                                <h2>E-Data</h2>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
                                <a href="javascript:;">Read more</a>
                            </div>*/}
                        </div>
                        <div className="col-lg-6 service-img"><img src="/assets/images/home-service-banner.png" alt="INAI"
                            className="img-fluid" /></div>
                    </div>
                </div>
            </section>

            <section className="container acheived-section">
                <div className="section-title">
                    <h2><img src="/assets/images/title-divider.png" /> Acheived so far <img src="/assets/images/title-divider.png" />
                    </h2>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="acheived-col">
                            <img src="/assets/images/clients-icon.png" alt="Clients" />
                            <h2>{ statistics.total_reg_comp }+</h2>
                            <p>Registered Clients</p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="acheived-col">
                            <img src="/assets/images/bids-icon.png" alt="Auctions" />
                            <h2>{ statistics.total_erfq }+</h2>
                            <p>Sucessful E-RFQ</p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="acheived-col">
                            <img src="/assets/images/countries-icon.png" alt="Countries" />
                            <h2>01</h2>
                            <p>Countries</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Home