import { useEffect } from "react";
import { PageToTop } from "../reusable/CommonUtils";

const Services = () => {

    useEffect(() => {
        PageToTop();
    }, []);

    return (
        <>
            <section className="container">
                <div className="about-section">
                    <div className="section-title">
                        <h2><img src="/assets/images/title-divider.png" /> Our Services <img src="/assets/images/title-divider.png" />
                        </h2>
                    </div>
                    <div className="highlight-text">Lifetime free registration, but registration and validation is mandatory to access any of our services.</div>
                    <div className="service-img">
                        <img src="/assets/images/service-img.png" alt="Services" />
                    </div>
                    <br /><br />
                    <div className="right-img">
                        <div className="img-section"><img src="/assets/images/erfq-img.png" className="img-fluid" alt="ERFQ" /></div>
                        <div className="content-section">
                            <h3>Electronic platform for procurement (E-RFQ)</h3>
                            <p>INAI E-RFQ is a standardized digital platform that enables an organization to procure the best product / service from the right supplier, at the most competitive price. Our E-RFQ enables procurement departments to invite qualified suppliers to submit competitive quotations.</p>
                            <p>Our in-built B2B Auction (E-Bidding) system helps the buyers to procure products and services at the best possible price. All correspondence will be notified to both buyers and suppliers.</p>
                            <p>Whenever suppliers have a question to ask to buyers, they can do so right within INAI Procurement Service. Replies from buyers are posted to every supplier, ensuring each of them can adhere to procurer requirements. For buyers, this saves the time and energy wasted in replying to similar, repetitive technical and commercial queries from multiple suppliers.</p>
                            <p>E-RFQ also helps suppliers to know their bid position, enabling them to submit their most competitive quotes to grab the order. </p>
                        </div>
                    </div>
                    <div className="left-img">
                        <div className="img-section"><img src="/assets/images/supplier-rationalization.png" className="img-fluid" alt="Supplier Rationalization" /></div>
                        <div className="content-section">
                            <h3>Supplier Rationalization</h3>
                            <p>Supplier Rationalization is a process that organizations deploy to reduce the number of suppliers that they work with to a minimum number, based on certain important technical and commercial specifications. This allows them to streamline their expenses to a fewer number of suppliers, ensure they get the best value for their spend and derive the best value from these relationships. (We have to say qualify and eliminate the unqualified suppliers)</p>
                            <p>INAI systematically collects the data’s and validates credibility’s to qualify the suppliers categorically.</p>
                            <p>This ensures that buyers can easily qualify the suppliers that meet specific criteria and requirements, while eliminating those that do not meet them.</p>
                        </div>
                    </div>
                    <div className="right-img">
                        <div className="img-section"><img src="/assets/images/strategic-sourcing.png" className="img-fluid" alt="Strategic Sourcing" /></div>
                        <div className="content-section">
                            <h3>Strategic Sourcing </h3>
                            <p>Strategic sourcing is defined as, <i>“the process of identifying the spend profile of an organization and its supplier base to ensure that their business requirements are aligned with the suppliers”.</i></p>
                            <p>This is a technique that encourages organizations to continually perform market research and analyze their supplier base, to see if the products suppliers offer are aligning with their own business requirements. INAI’s procurement service allows you to find the products and suppliers who fulfill requirements that may be important to your business objectives. </p>
                            <p>Our product and supplier search allows streamline the <b>Sourcing</b>  process. Instead of working with a large number of suppliers, you can find just those suppliers and products that fulfill the criteria important to your specific requirements.</p>
                            <p>Through this, the end goal of strategic sourcing can be achieved : to build a collaborative, long term relationship with trusted suppliers that leads to lower costs and better value for money.</p>
                        </div>
                    </div>
                    {/*<h3>Enquiry Management system </h3>
                <p>Whether you are a buyer or supplier, you and your team receive queries to work on, and respond to. With email, based on the query, you have to loop in the respective people from your organization who can then work on it. With an increasing volume of queries, there are multiple mail threads to manage which becomes inefficient and difficult. In the world of distributed, remote working, using tools like Excel to manage this is also not viable. </p>
                <p>INAI’s Enquiry Management system allows you to log queries inside it, and assign them to members of your team. You can then track and monitor the progress made on enquiries, giving you a seamless, centralized way to delegate tasks and solve them quickly.</p>
                <h3>Order Management System</h3>
                <p>After successfully securing purchase orders, you might be finding it difficult to track which team member is responsible for a certain order, and what the fulfillment status is. With INAI, after securing purchase orders, you can assign ownership to team members, and track progress made. This ensures that in addition to a streamlined procurement process, you can manage your purchase orders more efficiently also.</p>
                <h3>Quotation Management System</h3>
                <p>Whether you’re a supplier who’s received a request for a quotation, or a buyer looking to manage quotations from multiple sellers, INAI Procurement Service helps you manage this process better. Rather than using emails or Excel, where it is easy to miss work items, and difficult to manage them, we offer a centralized platform where tasks can be delegated to team members and their status monitored end-to-end. This ensures that for buyers, procurement needs are smoothly monitored and managed, while for suppliers, it becomes easier to secure more orders. </p>
                */}
                </div>
            </section>
        </>
    )
}

export default Services