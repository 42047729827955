import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const FileModal = ({ show, onHide, fileData }) => {

    const renderFile = () => {
        if (!fileData) {
          return null;
        }
    
        const { type, data } = fileData;
    
        if (type === 'image/png' || type === 'image/jpeg' || type === 'image/gif') {
          return <img src={`data:${type};base64,${data}`} alt="File" style={{ maxWidth: '100%', maxHeight: '100%' }} />;
        } else if (type === 'application/pdf') {
            return <iframe
            src={`data:${fileData.type};base64,${fileData.data}`}
            style={{ width: '100%', height: '500px', border: 'none' }}
          ></iframe>
        } else if (type === 'application/msword' || type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
          return <iframe src={`data:${type};base64,${data}`} style={{ width: '100%', height: '500px', border: 'none' }}></iframe>;
        } else if (type === 'application/vnd.ms-excel' || type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          return <iframe src={`data:${type};base64,${data}`} style={{ width: '100%', height: '500px', border: 'none' }}></iframe>;
        } else {
          return <p>Unsupported file type</p>;
        }
      };

  return (
    <Modal show={show} onHide={onHide} size="lg"  animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{fileData?.file_name??''}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
         
         {renderFile()}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FileModal;
