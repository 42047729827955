import React, { useState } from "react"
import { Link } from 'react-router-dom'

const NavigationMenu = ({item=1}) => {

    const [activeItem, setActiveItem] = useState(item);

    const getActiveItem = (i) => activeItem === i ? "tablink active" : "tablink";

    return(
        <div className="form-headings-wrapper">
            <ul>
                <li><Link to="/myconnect" className={ getActiveItem(1) }>My List</Link></li>
                <li><Link to="/pendinglist" className={ getActiveItem(2) }>Request Received</Link></li>
                <li><Link to="/requestlist" className={ getActiveItem(3) }>Request Sent</Link></li>
                <li><Link to="/connsearch" className={ getActiveItem(4) }>Search</Link></li>
            </ul>
        </div>
    );
}
export default NavigationMenu;