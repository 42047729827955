import React, { useEffect, useState } from "react";
import Notification, { errorText, errorBorder } from "../../../reusable/Notification";
import axios from "axios";
import { AuthTokenVerify, UpdateBearer } from "../../../reusable/AuthUtils";
import { Link, useParams, useNavigate } from 'react-router-dom'
import RolesMap from "./RolesMap";
import _ from "lodash"

const EmployeeEdit = ({history, setAlertMessage}) => {

    const { id } = useParams();

    const [filedError, setFieldError] = useState({});

    const initForm = {
        username: '',
        email: '',
        employee_id: '',
        first_name: '',
        last_name: '',
        employee_mobile: '',
        is_active: true,
        role_map: new Map(),
        dept_permission_map:new Map()
    };

    const navigate = useNavigate();

    const [formData, setFormData] = useState(initForm);
    const [rolesAvailable, setRolesAvailable] = useState([]);
    const [departmentAvailable, setDepartmentAvailable] = useState([]);
    const [modalShow, setModalShow] = useState(false);

    const [disableSubmit, setDisableSubmit] = useState(false);

    useEffect(() => {
        AuthTokenVerify(history);

        axios.get("roles/roles/").then((response) => {
            setRolesAvailable(response.data);
        }).catch((e) => {
            //
        });

        axios.get("dept/company_parent_dept/").then((response) => {
            
             setDepartmentAvailable(response.data)
        }).catch((e) => {
            //
        });

        if(id){
            axios.get(`user/employee/${id}/`)
            .then((response) => {
                let data = response.data;
                let role_map_obj = new Map();
                let dept_map_obj = new Map();
                data['roles_map'].map((role) => (
                    role_map_obj.set(role, true)
                ));
                data['dept_permission_map'].map((dept)=>(
                    dept_map_obj.set(dept,true)
                ))
                delete data['roles_map'];
                delete data['dept_permission_map'];
                delete data['emp_id'];
                delete data['pk'];
                setFormData({...data, role_map: role_map_obj,dept_permission_map: dept_map_obj});
            })
            .catch((e) => {
                //
            });
        }


    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        setFieldError({});
        setDisableSubmit(true);
        setAlertMessage({});

        let formDataClone = _.clone(formData);
        let role_map_list = [];
        let dept_map_list =[];
        for (const [key, value] of formDataClone.role_map) {
            if(value) role_map_list.push(key);
        }
        formDataClone.role_map = role_map_list;

        for (const [key, value] of formDataClone.dept_permission_map) {
            if(value) dept_map_list.push(key);
        }
        formDataClone.dept_permission_map = dept_map_list;

        if(id){
            axios.put(`user/employee/${id}/`, formDataClone)
            .then((response) => {
                setAlertMessage({type:'success', message:response.data.message});
                navigate('/employees');
            })
            .catch((e) => {
                if(e.response.status === 400) {
                    setAlertMessage({type:'danger', message:"Error Occured While Submit!"});
                    let dict = {};
                    for (const [key, value] of Object.entries(e.response.data)) {
                        dict[key] = value.join(', ');
                    }
                    setFieldError(dict);
                    UpdateBearer(history, e.response);
                }
                setDisableSubmit(false);
            });
        }else{
            axios.post("user/employee/", formDataClone)
            .then((response) => {
                setAlertMessage({type:'success', message:response.data.message});
                navigate('/employees');
            })
            .catch((e) => {
                if(e.response.status === 400) {
                    setAlertMessage({type:'danger', message:"Error Occured While Submit!"});
                    let dict = {};
                    for (const [key, value] of Object.entries(e.response.data)) {
                        dict[key] = value.join(', ');
                    }
                    setFieldError(dict);
                    UpdateBearer(history, e.response);
                }
                setDisableSubmit(false);
            });
        }
    };

    const handleChange = (e) => {
        console.log("e.target.value",e.target.value)
        console.log("e.target.checked",e.target.checked)
        //let targetData = e.target.value;

        let targetData = '';

        switch (e.target.name) {
            case "role_map":
                targetData = formData.role_map.set(parseInt(e.target.value), e.target.checked)
                break;
            case "dept_permission_map":
                console.log("department data",formData.dept_permission_map)
                targetData = formData.dept_permission_map.set(parseInt(e.target.value), e.target.checked)
                break;
            case "is_active":
                targetData = (e.target.value == 1) ? true : false;
                break;
            default:
                targetData = e.target.value;
                break;
        }
      
        setFormData({
            ...formData,
            // Trimming any whitespace
            [e.target.name]: targetData
        });
    };
    

    return (
        <form onSubmit={ onSubmit }>
            <RolesMap
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
            <div className="form-panel">
                <div className="row row-cols-1 row-cols-lg-2 mb-3 form-elements">
                    <div className="col-12 form-column form-address-panel">
                        <div className="row mb-4 row-cols-1 row-cols-lg-3">
                            <div className="col">
                                <label className="form-label">Username </label>
                                <input type="text" name="username" readOnly={id} value={formData.username} className={"form-control " + errorBorder(filedError.username) } onChange={handleChange} />
                                { errorText(filedError.username) }
                            </div>
                            <div className="col">
                                <label className="form-label">Email </label>
                                <input type="text" name="email" readOnly={id} value={formData.email} className={"form-control " + errorBorder(filedError.email) } onChange={handleChange} />
                                { errorText(filedError.email) }
                            </div>
                            <div className="col">
                                <label className="form-label">First Name </label>
                                <input type="text" name="first_name" value={formData.first_name} className={"form-control " + errorBorder(filedError.first_name) } onChange={handleChange} />
                                { errorText(filedError.first_name) }
                            </div>
                        </div>
                        <div className="row mb-4 row-cols-1 row-cols-lg-3">
                            <div className="col">
                                <label className="form-label">Last Name </label>
                                <input type="text" name="last_name" value={formData.last_name} className={"form-control " + errorBorder(filedError.last_name) } onChange={handleChange} />
                                { errorText(filedError.last_name) }
                            </div>
                            <div className="col">
                                <label className="form-label">Employee ID</label>
                                <input type="text" name="employee_id" value={formData.employee_id} className={"form-control " + errorBorder(filedError.employee_id) } onChange={handleChange} />
                                { errorText(filedError.employee_id) }
                            </div>
                            <div className="col">
                                <label className="form-label">Mobile</label>
                                <input type="text" name="employee_mobile" value={formData.employee_mobile} className={"form-control " + errorBorder(filedError.employee_mobile) } onChange={handleChange} />
                                { errorText(filedError.employee_mobile) }
                            </div>
                        </div>
                        <div className="row mb-4 row-cols-1 row-cols-lg-3">
                            <div className="col">
                                <label className="form-label">Is Active </label>
                                <div className="mb-3">
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" onChange={handleChange} checked={formData.is_active} name="is_active" type="radio"   value="1" />
                                        <label className="form-check-label" for="Yes">Yes</label>
                                    </div>

                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" onChange={handleChange} checked={!formData.is_active} name="is_active" type="radio"   value="2" />
                                        <label className="form-check-label" for="No">No</label>
                                    </div>
                                    {errorText(filedError.is_active)}
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <div class="row card mb-3">
                    <h5 class="card-header">Roles <small onClick={() => setModalShow(true)} className="cursor-pointer text-primary">(see roles map)</small></h5>
                    <div class="card-body">
                        <div className="row">
                            {
                                rolesAvailable.map((row, key) => ( 
                                    <div className="col-md-2">
                                        <label className="cursor-pointer">
                                            <input className="form-check-input"
                                            name="role_map"
                                            checked={formData.role_map.get(parseInt(row.pk))}
                                            value={row.pk}
                                            onClick={handleChange}
                                            type="checkbox"/> {row.name}
                                        </label>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div> 
                <div class="row card mb-3">
                    <h5 class="card-header">Department</h5>
                    <div class="card-body">
                        <div className="row">
                            {
                                departmentAvailable.map((row, key) => ( 
                                    <div className="col-md-2 mb-4">
                                        <label className="cursor-pointer">
                                            <input className="form-check-input"
                                            name="dept_permission_map"
                                            checked={formData.dept_permission_map.get(parseInt(row.pk))}
                                            value={row.pk }
                                            onClick={handleChange}
                                            type="checkbox"/> {row.department_read}
                                        </label>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-3 text-end">
                <Link className="button-style" type="button" to={'/employees'}>Cancel</Link>&nbsp;&nbsp;
                { formData.pk ? (
                    <>
                    <button type="submit" disabled={disableSubmit} className="button-style">Update</button>
                    </>
                ) : (
                    <button type="submit" disabled={disableSubmit} className="button-style">{id ? 'Edit Employee' : 'Add Employee'}</button>
                )}
            </div>
        </form>     
    )
};

export default EmployeeEdit;