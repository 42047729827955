import React, { useState, useEffect } from "react";
import { isPermitted } from "../../reusable/PermissoinUtils";
import axios from "axios";
import ReactPaginate from "react-paginate";
 
import { Link, useLocation } from "react-router-dom";

import { PageToTop, tryParseJSONObject } from "../../reusable/CommonUtils";

import { AuthTokenVerify, UpdateBearer } from "../../reusable/AuthUtils";
import Notification from "../../reusable/Notification";

import {
  noPermissionTemplate,
  isThisAdmin,
} from "./../../reusable/PermissoinUtils";
import { confirm } from "./../../reusable/Confirmation";

const EquipmentSummary = ({ history }) => {
  
  const params = new URLSearchParams(useLocation().search);
  const paramFilter = tryParseJSONObject(params.get('filter')) ? JSON.parse(params.get('filter')) : {};


  const [dataList, setDataList] = useState([]);
  const [refreshList, setrefreshList] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [noPermission, setNoPermission] = useState(false);
   const [alertMessage, setAlertMessage] = useState({});
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(paramFilter);
 
  const recordsPerPage = 15;
  const equipmentTypeList = [
    { pk: 0, name: "Main Equipment" },
    { pk: 1, name: "Spare Parts" },
  ];
  useEffect(() => {
    AuthTokenVerify(history);
    if (!refreshList) return;
    axios
      .get(`ems/equipment/?page=${page}&filter=${encodeURIComponent(JSON.stringify(filter))}`)
      .then((response) => {
         
        setDataList(response.data.results);
        setPageCount(Math.ceil(response.data.count / recordsPerPage));
        UpdateBearer(history, response);
      })
      .catch((e) => {
        if (e.response.status === 404) {
          setPage(1);
          setrefreshList(true);
        } else if (e.response.status === 403) {
          //permission denied
          setNoPermission(true);
        }
      });
    setrefreshList(false);
  }, [refreshList]);

  


  const handlePageClick = (data) => {
    setPage(data.selected + 1);
    setrefreshList(true);
  };

  const deleteItem = async (key) => {
    const result = await confirm("Are you sure to delete this?");
    if (!result) return;

    setAlertMessage({});

    const pk = dataList[key].pk;
    axios
      .delete("ems/equipment/" + pk + "/")
      .then((response) => {
        setAlertMessage({
          type: "success",
          message: "Equipment Deleted Successfully!",
        });
        setrefreshList(true);
      })
      .catch((e) => {
        if (e.response.status === 400) {
          setAlertMessage({
            type: "danger",
            message: "Error Occurred While Delete!",
          });
        }
      });
    PageToTop();
  };

  const showPagination = () => {
    if (pageCount <= 1) return;
    return (
      <nav>
        <ReactPaginate
          pageClassName={"page-item"}
          previousClassName={"page-item active"}
          nextClassName={"page-item active"}
          pageLinkClassName={"page-link"}
          previousLinkClassName={"page-link"}
          nextLinkClassName={"page-link"}
          previousLabel={"previous"}
          nextLabel={"next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          forcePage={page - 1}
          pageRangeDisplayed={recordsPerPage}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </nav>
    );
  };

  const getDataList = () => {
    PageToTop();
    const content = [];
    dataList.map((item, key) => {
      content.push(
        <tr key={key}>
          <td>{key + 1 + recordsPerPage * (page - 1)}</td>
          <td>{item.name}</td>
          <td>{item.type_read}</td>
          <td>{item.department_read}</td>
          <td>{item.item_code}</td>

          <td>{item.serial_no}</td>
          <td>{item.part_no}</td>

          <td className="text-center">
          
             
                  <Link to={`/ems-add/${item.pk}`} className="btn btn-primary">
                    <i className="bi bi-pencil-fill"></i>
                  </Link>
                

              

              
                  <Link
                    to={`/ems-view/${item.pk}`}
                    className="btn btn-success ms-1"
                  >
                    <i className="bi bi-eye-fill cursor-pointer"></i>
                  </Link>
                 
              

          

            {(() => {
              if (isThisAdmin()) {
                return (
                  <button
                    onClick={() => deleteItem(key)}
                    type="button"
                    className="btn btn-danger ms-1"
                  >
                    <i className="bi bi-trash-fill"></i>
                  </button>
                );
              }

              return (
                <button
                  title="No permission"
                  type="button"
                  className="btn btn-danger ms-1 action-disabled"
                >
                  <i className="bi bi-trash-fill"></i>
                </button>
              );
            })()}
          </td>
        </tr>
      );
    });

    if (content.length < 1) {
      content.push(
        <tr>
          <td colSpan="9" className="text-center">
            No records found
          </td>
        </tr>
      );
    }

    return content;
  };


  const filterHandle = (e) => {
    var targetData = '';

    switch (e.target.name) {
        default:
            targetData = e.target.value;
            break;
    }

    setFilter({...filter, [e.target.name] : targetData});
    filterHandleTrigger();
};

const filterHandleTrigger = () => {
  setrefreshList(true);
  setPage(1);
};

  const renderBody = () => {
    return (
      <>
        <div className="title-section mb-4">
          <h2>Your EMS</h2>
        
             
             
                <Link className="button-style" to="/ems-add">
                  Add Equipment
                </Link>
               
  
           
        </div>

        <Notification {...alertMessage} />

        <div className="table-responsive row">
          <table className="table align-middle table-bordered table-design">
            <thead>
            <tr>
                            {/* <td colSpan={2}>
                                <select name="status" value={filter['status'] ? filter['status'] : ""} onChange={filterHandle} className="form-control">
                                    <option value={""}>--status--</option>
                                    {
                                        Object.keys(availStatus).map((key, index) => ( 
                                            <option value={key}>{ availStatus[key] }</option>
                                        ))
                                    }
                                </select>
                            </td> */}
                            <td colSpan={2}><input type="text" style={{width: 'auto'}} className="form-control" onChange={filterHandle} name="name" value={filter['name'] ? filter['name'] : ""} /></td>
                            {/* <td><input type="text" style={{width: 'auto'}} className="form-control" onChange={filterHandle} name="name" value={filter['name']  ? filter['name'] : "" }  /></td> */}
                            <td>
                                <select name="type" style={{width: 'auto'}} value={filter['type'] ? filter['type'] : ""} onChange={filterHandle} className="form-control">
                                    <option value={""}>--Equipment Type--</option>
                                    {
                                        equipmentTypeList.map((row, key) => ( 
                                            <option value={row.pk}>{ row.name }</option>
                                        ))
                                    }
                                </select>
                            </td>
                            <td><input type="text" style={{width: 'auto'}} className="form-control" onChange={filterHandle} name="department" value={filter['department']  ? filter['department'] : ""} /></td>
                            <td><input type="text" style={{width: 'auto'}} className="form-control" onChange={filterHandle} name="item_code" value={filter['item_code']  ? filter['item_code'] : ""} /></td>
                            
                             <td><input type="text" style={{width: 'auto'}} className="form-control" onChange={filterHandle} name="serial_no" value={filter['serial_no']  ? filter['serial_no'] : ""} /></td>
                            <td><input type="text" style={{width: 'auto'}} className="form-control" onChange={filterHandle} name="part_no" value={filter['part_no']  ? filter['part_no'] : ""} /></td>
                            <td><button className="btn btn-danger" onClick={() => { setFilter({}); filterHandleTrigger(); }}><i class="bi bi-bootstrap-reboot"></i></button></td>
                        </tr>
              <tr>
                <td>S No.</td>
                <td>Equipment Name</td>
                <td>Equipment Type</td>
                <td>Department</td>
                <td>Item Code</td>
                <td>Serial No</td>
                <td> Part No</td>

                <td className="product-action">Action</td>
              </tr>
            </thead>
            <tbody>{getDataList()}</tbody>
          </table>
        </div>
        {showPagination()}
      </>
    );
  };

  if (noPermission) {
    return noPermissionTemplate();
  }
  return (
    <>
      <div className="container">
        <div className="form-wrapper summary-container">{renderBody()}</div>
      </div>
    </>
  );
};

export default EquipmentSummary;
