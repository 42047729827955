import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { AuthTokenVerify, UpdateBearer } from "../../reusable/AuthUtils";
import { PageToTop, returnOnlyDefined } from "../../reusable/CommonUtils";
import Notification, { errorText, errorBorder } from "../../reusable/Notification";
import { Modal } from "react-bootstrap";
import AsyncSelect from 'react-select/async';
import ReactPaginate from 'react-paginate';
import _ from "lodash"
import { Link } from "react-router-dom";
import { noPermissionTemplate, isPermitted } from "../../reusable/PermissoinUtils";


const ErfqView = ({ history }) => {

    let { id } = useParams();

    const [data, setData] = useState({
        'erfq_form_documents': []
    });
    const [items, setItems] = useState([]);
    const [amendments, setAmendments] = useState([]);
    const [participants, setParticipants] = useState([]);
    const [participantsDocs, setParticipantsDocs] = useState([]);
    const [participantPositions, setParticipantPositions] = useState({});
    const [terms_conditions, setTermsConditions] = useState([]);
    const [emails, setEmails] = useState([]);
    const [documentView, setDocumentView] = useState('');
    const fileInput = useRef(null);
    const [disable, setDisable] = React.useState(false);

    const initForm = {
        'file_name': '',
        'document': [],
        'description': ''
    };

    const [formData, setFormData] = useState(initForm);

    const [amendmentFieldError, setAmendmentFieldError] = useState({});
    const [alertMessage, setAlertMessage] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [endDateEdit, setEndDateEdit] = useState(false);

    // bootsrap model for document render
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // bootsrap model for invite sellers
    const [showSeller, setShowSeller] = useState(false);
    const handleCloseSeller = () => setShowSeller(false);
    const handleShowSeller = () => setShowSeller(true);

    const [fieldErrorSeller, setFieldErrorSeller] = useState({});
    const initFormSeller = {
        'erfq': id,
        'company': '',
        'advance_search': 0,
        'product': null,
        'brand': [],
        'business_type': {},
        'industry': [],
        'credential': [],
        'certificate': []
    };

    const [formDataSeller, setFormDataSeller] = useState(initFormSeller);

    const [refreshList, setrefreshList] = useState(false);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const recordsPerPage = 15;
    const [dataList, setDataList] = useState([]);
    const [dataListFetched, setDataListFetched] = useState(false);

    const [questionAnswers, setQuestionAnswers] = useState({});
    const [answer, setAnswer] = useState({});
    const [disableAButton, setDisableAButton] = useState({});
    const [answerErr, setAnswerErr] = useState({});

    const clearSearch = () => {
        setFormDataSeller(initFormSeller);
    };

    const submitAnswer = (key) => {
        setDisableAButton({ ...disableAButton, [key]: true });
        setAnswerErr({ ...answerErr, [key]: [] });
        setAlertMessage({});

        axios.post("erfq/answer/" + key + "/", {
            answer: answer[key]
        }).then((response) => {
            var data = response.data;
            UpdateBearer(history, response);
            setDisableAButton({ ...disableAButton, [key]: false });
            setAnswer({ ...answer, [key]: '' });
            setAlertMessage({ type: 'success', message: data.message });
            PageToTop();

            var qaClone = _.clone(questionAnswers);
            qaClone[key]['answers'].push(data.answer);
            setQuestionAnswers(qaClone);
        }).catch((e) => {
            UpdateBearer(history, e.response);
            if (e.response.status === 400) {
                setAnswerErr({ ...answerErr, [key]: e.response.data.answer });
            }
            if ([404, 406].includes(e.response.status)) {
                setAlertMessage({ type: 'danger', message: e.response.data.message });
                PageToTop();
            }
            setDisableAButton({ ...disableAButton, [key]: false });
        });
    };

    const productOptions = (inputValue) => {
        return new Promise(resolve => {
            axios.get("products/product-search/?q=" + inputValue).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                //
            });
        });
    };

    const brandsOptions = (inputValue) => {
        let data = formDataSeller.brand.map(brands => { if (!brands.__isNew__) return brands.value }).filter(e => e).join(',');
        return new Promise(resolve => {
            axios.get("products/brands-search/?q=" + inputValue + "&skip=" + data).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                //
            });
        });
    };

    const industriesOptions = (inputValue) => {
        let data = formDataSeller.industry.map(industries => { if (!industries.__isNew__) return industries.value }).filter(e => e).join(',');
        return new Promise(resolve => {
            axios.get("products/industries-search/?q=" + inputValue + "&skip=" + data).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                //
            });
        });
    };

    const credentialsOptions = (inputValue) => {
        let data = formDataSeller.credential.map(credentials => { if (!credentials.__isNew__) return credentials.value }).filter(e => e).join(',');
        return new Promise(resolve => {
            axios.get("products/credentials-search/?q=" + inputValue + "&skip=" + data).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                //
            });
        });
    };

    const certificatesOptions = (inputValue) => {
        let data = formDataSeller.certificate.map(certificates => { if (!certificates.__isNew__) return certificates.value }).filter(e => e).join(',');
        return new Promise(resolve => {
            axios.get("products/certificates-search/?q=" + inputValue + "&skip=" + data).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                //
            });
        });
    };


    useEffect(() => {
        AuthTokenVerify(history);
        PageToTop();
    }, []);

    useEffect(() => {
        axios.get("erfq/form-view/" + id + "/").then((response) => {
            setData(response.data);
            setItems(response.data.erfq_form_items);
            setParticipantPositions(response.data.erfq_bidding);
            setTermsConditions(response.data.erfq_form_terms);
            setEmails(response.data.erfq_form_email);
            setAmendments(response.data.erfq_form_amendments);
            setParticipants(response.data.erfq_form_participants);
            setParticipantsDocs(response.data.erfq_form_participants_docs);
            UpdateBearer(history, response);
            if (response.data.status === 4) {
                setDisable(true)
            }
            setQuestionAnswers(response.data.questions_answers);
        }).catch((e) => {
            UpdateBearer(history, e.response);
            if (e.response.status === 404) {
                history('/erfq-summary');
            }
        });
    }, []);

    const getQuestionAnswers = () => {

        const content = [];

        Object.keys(questionAnswers).map((key, index) => {
            let question_answer = questionAnswers[key];
            let question = question_answer.question;
            
            content.push(
                <div className="message info">
                    <div className="message-logo"><label>{question.company_read.substring(0, 1)}</label></div>
                    <div className="message-body">
                        <div className="message-info">
                            <h4>{question.company_read}</h4>
                            <h5> <i className="bi bi-clock"></i> {question.created_on}</h5>
                        </div>
                        <hr></hr>
                        <div className="message-text">{question.question}</div>
                    </div>
                    <br/>
                </div>
            );

            question_answer.answers.map((row, key) => {
                content.push(
                    <div className="message reply">
                        <div className="message-logo"><label>{row.company_read.substring(0, 1)}</label></div>
                        <div className="message-body">
                            <div className="message-info">
                                <h4>{row.company_read}</h4>
                                <h5> <i className="bi bi-clock"></i> {row.created_on}</h5>
                            </div>
                            <hr></hr>
                            <div className="message-text">{row.answer}</div>
                        </div>
                        <br/>
                    </div>
                );
            });

            if (data.status != 4 && isPermitted('site_erfq_question_and_answer')) {
                content.push(
                    <div className="message reply reply-box mb-4">
                        <div className="message-logo"><label><i class="bi bi-arrow-return-right"></i></label></div>
                        <div className="message-body mb-1">
                            <textarea 
                                className="form-control message-reply"
                                name="answer"
                                onChange={(e) => setAnswer({ ...answer, [key]: e.target.value })}
                                value={answer[key]} 
                            />
                        </div>
                        <button type="submit" disabled={disableAButton[key]} onClick={() => submitAnswer(key)} className="btn btn-success p-2">Reply</button>
                        {errorText(returnOnlyDefined(answerErr, 'object[' + key + '].join(",")'))}
                        <br/>
                    </div>
                );
            }
        });

        if (content.length < 1) {
            content.push(<tr>
                <i>No records found</i>
            </tr>);
        }

        return content;
    };

    const getItemList = () => {
        const content = [];
        items.map((row, key) => {
            content.push(<tr key={key} >
                <td>{(key + 1)}</td>
                <td>{row.item_code}</td>
                <td>{row.item_qty}</td>
                <td>{row.item_uom_read}</td>
                <td>{row.description}</td>
            </tr>);
        });

        if (content.length < 1) {
            content.push(<tr>
                <td colSpan="5" className="text-center">No records found</td>
            </tr>);
        }

        return content;
    };

    const renderAmendmentDocument = (key) => {
        axios.get("erfq/form-amendment-document-render/" + key + "/").then((response) => {
            setDocumentView("data:application/pdf;base64," + response.data);
            handleShow();
        }).catch((error) => {
            //
        });
    };


    const renderParticipantDocument = (key) => {
        axios.get("erfq/form-participant-document-render/" + key + "/").then((response) => {
            setDocumentView("data:application/pdf;base64," + response.data);
            handleShow();
        }).catch((error) => {
            //
        });
    };

    const downloadParticipantDocument = (key) => {
        axios({
            url: "erfq/form-participant-document-download/" + key + "/",
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            const filename = response.headers.filename;
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });
    };

    const renderErfqDocument = (key) => {
        axios.get("erfq/form-document-render/" + key + "/").then((response) => {
            setDocumentView("data:application/pdf;base64," + response.data);
            handleShow();
        }).catch((error) => {
            //
        });
    };

    const getErfqDocumentList = () => {
        const content = [];
        data.erfq_form_documents.map((row, key) => {
            content.push(<tr key={key} >
                <td>{(key + 1)}</td>
                <td>{row.file_name}</td>
                <td className="text-center">
                    {(() => {
                        if (row.document) {
                            return (
                                <i disabled onClick={() => renderErfqDocument(row.pk)} className="btn btn-success bi bi-eye-fill cursor-pointer"></i>

                            )
                        }
                        return null;
                    })()}

                </td>
            </tr>);
        });

        if (content.length < 1) {
            content.push(<tr>
                <td colSpan="3" className="text-center">No records found</td>
            </tr>);
        }

        return content;
    };


    const getErfqParticipantsList = () => {
        const content = [];
        participants.map((row, key) => {
            let position = '**';
            if (data.bidding_type_ref == 'type_3') {
                position = participantPositions.hasOwnProperty(row.to_company) ? participantPositions[row.to_company] : '-';
            }
            content.push(<tr key={key} >
                <td>{(key + 1)}</td>
                <td><Link to={"/company-profile/" + row.to_customer_id}><b><span className="badge  bg-primary">{row.to_customer_id}</span></b> {row.to_company_name}</Link></td>
                <td>{position}</td>
                <td>{row.status_read}</td>
            </tr>);
        });

        if (content.length < 1) {
            content.push(<tr>
                <td colSpan="4" className="text-center">No records found</td>
            </tr>);
        }

        return content;
    };


    const getAmendmentList = () => {
        const content = [];
        amendments.map((row, key) => {
            content.push(<tr key={key} >
                <td>{(key + 1)}</td>
                <td>{row.file_name}</td>
                <td>{row.description}</td>
                <td className="text-center">
                    {(() => {
                        if (row.document) {
                            return (
                                <i disabled onClick={() => renderAmendmentDocument(row.pk)} className="btn btn-success bi bi-eye-fill cursor-pointer"></i>
                            )
                        }
                        return null;
                    })()}
                </td>
            </tr>);
        });

        if (content.length < 1) {
            content.push(<tr>
                <td colSpan="4" className="text-center">No records found</td>
            </tr>);
        }

        return content;
    };

    const getParticipantsDocsList = () => {
        const content = [];
        participantsDocs.map((row, key) => {
            content.push(<tr key={key} >
                <td>{(key + 1)}</td>
                <td>{row.file_name}</td>
                <td>{row.description}</td>
                <td>{row.company_name}</td>
                <td className="text-center">
                    {(() => {
                        if (row.document) {
                            return (
                                <i disabled onClick={() => downloadParticipantDocument(row.pk)} className="btn btn-primary bi bi-cloud-download-fill cursor-pointer"></i>
                            )
                        }
                        return null;
                    })()}
                </td>
            </tr>);
        });

        if (content.length < 1) {
            content.push(<tr>
                <td colSpan="5" className="text-center">No records found</td>
            </tr>);
        }

        return content;
    };

    const getTermsList = () => {
        const content = [];
        terms_conditions.map((row, key) => {
            content.push(<tr key={key} >
                <td>{(key + 1)}. {row.terms_condition}</td>
            </tr>);
        });

        if (content.length < 1) {
            content.push(<tr>
                <td colSpan="5" className="text-center">No records found</td>
            </tr>);
        }

        return content;
    };
    


    const getEmailList = () => {
        const content = [];
        emails.map((row, key) => {
            content.push(<tr key={key} >
                <td>{(key + 1)}. {row.email}</td>
            </tr>);
        });

        if (content.length < 1) {
            content.push(<tr>
                <td colSpan="5" className="text-center">No records found</td>
            </tr>);
        }

        return content;
    };

    const submitAmendment = (e) => {
        e.preventDefault();
        setAlertMessage({});
        setAmendmentFieldError({});
        setDisableSubmit(true);

        let fData = new FormData();
        fData.append("file_name", formData.file_name);
        fData.append("document", formData.document);
        fData.append("description", formData.description);
        fData.append("erfq_id", data.pk);

        axios({
            method: "post",
            url: "erfq/form-amendment/",
            data: fData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then((response) => {
                setFormData(initForm);
                setAlertMessage({ type: 'success', message: "Document Uploaded Successfully!" });
                setDisableSubmit(false);
                const newRecords = [...amendments];
                newRecords.push(response.data);
                setAmendments(newRecords);
                fileInput.current.value = '';
            })
            .catch((e) => {
                if (e.response.status === 400) {
                    setAlertMessage({ type: 'danger', message: "Error Occured While Submit!" });
                    const dict = {};
                    for (const [key, value] of Object.entries(e.response.data)) {
                        dict[key] = value.join(', ');
                    }
                    setAmendmentFieldError(dict);
                }
                setDisableSubmit(false);
            });
        PageToTop();
    };

    const handleChange = (e) => {

        var targetData = '';

        switch (e.target.name) {
            case "document":
                targetData = e.target.files[0];
                break;
            default:
                targetData = e.target.value;
                break;
        }

        setFormData({
            ...formData,
            [e.target.name]: targetData
        });
    };

    const handleChangeSeller = (newValue, actionMeta) => {
        if (typeof (actionMeta) !== 'undefined') {
            setFormDataSeller({
                ...formDataSeller,
                [actionMeta.name]: newValue
            });
        } else {
            let targetData = '';
            switch (newValue.target.name) {
                case "advance_search":
                    targetData = newValue.target.checked;
                    break;
                case "business_type":
                    targetData = {
                        ...formDataSeller.business_type,
                        [newValue.target.value]: newValue.target.checked
                    };
                    break;
                default:
                    targetData = newValue.target.value;
                    break;
            }
            setFormDataSeller({
                ...formDataSeller,
                [newValue.target.name]: targetData
            });
        }
    };

    useEffect(() => {
        if (!refreshList) return;
        axios.get("erfq/form-invite-participants/", {
            params: {
                erfq: id,
                page: page,
                company: formDataSeller.company,
                advance_search: formDataSeller.advance_search ? 1 : 0,
                product: JSON.stringify(formDataSeller.product),
                brand: JSON.stringify(formDataSeller.brand),
                business_type: JSON.stringify(formDataSeller.business_type),
                industry: JSON.stringify(formDataSeller.industry),
                credential: JSON.stringify(formDataSeller.credential),
                certificate: JSON.stringify(formDataSeller.certificate)
            }
        }).then((response) => {
            setDataList(response.data.results);
            setPageCount(Math.ceil(response.data.count / recordsPerPage));
            UpdateBearer(history, response);
            setDataListFetched(true);
        })
            .catch((e) => {
                if (e.response.status === 400) {
                    setAlertMessage({ type: 'danger', message: "Error Occured While Submit!" });
                    const dict = {};
                    for (const [key, value] of Object.entries(e.response.data)) {
                        dict[key] = value.join(', ');
                    }
                    setFieldErrorSeller(dict);
                } else if (e.response.status === 404) {
                    setPage(1);
                    setrefreshList(true);
                }
                else if (e.response.status === 403) {
                    //permission denied
                    history('/');
                }
            });
        setrefreshList(false);
    }, [refreshList]);

    const onSellerSearch = (e) => {
        e.preventDefault();
        setFieldErrorSeller({});
        setAlertMessage({});
        setDataList([]);
        setrefreshList(true);
        setPageCount(0);
        setDataListFetched(false);
    };

    const handlePageClick = (data) => {
        setPage(data.selected + 1);
        setrefreshList(true);
    };


    const showPagination = () => {
        if (pageCount <= 1) return;
        return <nav>
            <ReactPaginate
                pageClassName={'page-item'}
                previousClassName={'page-item active'}
                nextClassName={'page-item active'}
                pageLinkClassName={'page-link'}
                previousLinkClassName={'page-link'}
                nextLinkClassName={'page-link'}
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageCount}
                forcePage={page - 1}
                pageRangeDisplayed={recordsPerPage}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                activeClassName={'active'}
            />
        </nav>;
    };

    const sendRequest = (key) => {

        setAlertMessage({});

        const newDataList = [...dataList];

        axios.post("erfq/form-invite-participants/", {
            company: newDataList[key].pk,
            erfq: id
        }).then((response) => {
            newDataList[key]['requested'] = true;
            setDataList(newDataList);
            UpdateBearer(history, response);
            const newRecords = [...participants];
            newRecords.push(response.data.participant);
            setParticipants(newRecords);
        }).catch((e) => {
            setAlertMessage({ type: 'danger', message: e.response.data.message });
            UpdateBearer(history, e.response);
            PageToTop();
        });
    };

    const sendReportToEmail = () => {
        setAlertMessage({});
        axios.post("erfq/send-report/", {
            erfq: id
        }).then((response) => {
            setAlertMessage({ type: 'success', message: response.data.message });
            PageToTop();
        }).catch((e) => {
            setAlertMessage({ type: 'danger', message: e.response.data.message });
            PageToTop();
        });
    };

    const sellerSearchList = () => {
        const content = [];

        if (dataListFetched && dataList.length < 1) {
            content.push(
                <a className="list-group-item list-group-item-action flex-column align-items-start">
                    <h5 className="mb-1 text-center">No Records Found</h5>
                </a>
            );
            return content;
        }

        dataList.map((item, key) => {
            let button = <span>
                <button
                    type="button"
                    onClick={() => { sendRequest(key) }}
                    className="btn btn-primary "
                >Request</button>
            </span>;

            if (item.requested) {
                button = <span>
                    <button
                        type="button"
                        className="btn btn-warning "
                    >Requested</button>
                </span>;
            }

            content.push(
                <a className="list-group-item list-group-item-action flex-column align-items-start">
                    <div className="d-flex w-100 justify-content-between">
                        <Link to={"/company-profile/" + item.customer_id} className="mb-1"><b><span className="badge  bg-primary">{item.customer_id}</span></b> &nbsp;{item.company_name}</Link>
                        {button}
                    </div>
                </a>
            );
        });

        return content;
    };

    const endDateTime = () => {

        return <>
            {(() => {
                if (data.status == 1) {
                    //return <>{data.end_date_time} <a className="bi bi-pencil cursor-pointer" onClick={() => setEndDateEdit(true)}></a></>
                }
                return <>{data.end_date_time}</>
            })()}
        </>
    };

    if (!isPermitted('site_erfq_view_rfq')) {
        return noPermissionTemplate();
    }

    return (
        <div className="container">
            <Modal dialogClassName="modal-80" show={show} onHide={handleClose}>
                <Modal.Body>
                    <object>
                        <embed name="Title of my video" type="text/html" style={{ width: '100%', height: '85vh' }} src={documentView} />
                    </object>
                </Modal.Body>
            </Modal>
            <Modal dialogClassName="modal-80" show={showSeller} onHide={handleCloseSeller}>
                <Modal.Body>
                    <form onSubmit={onSellerSearch}>
                        <div className="row mb-4 row-cols-1 row-cols-lg-3">
                            <div className="col"></div>
                            <div className="col">
                                <label className="form-label">Company Name / Company ID</label>
                                <input type="text" className={"form-control " + errorBorder(fieldErrorSeller.company)} name="company" onChange={handleChangeSeller} value={formDataSeller.company} />
                                {errorText(fieldErrorSeller.company)}
                            </div>
                            <div className="col"></div>
                        </div>
                        <div className="row mb-4 row-cols-1 row-cols-lg-3">
                            <div className="col">
                                <div className="form-group form-check">
                                    <input type="checkbox" checked={formDataSeller.advance_search} className="form-check-input" id="advance_search" name="advance_search" onClick={handleChangeSeller} />
                                    <label className="form-check-label" for="advance_search"><b>Advance Search</b></label>
                                </div>
                            </div>
                        </div>
                        {(() => {
                            if (formDataSeller.advance_search) {
                                return (
                                    <>
                                        <div className="row mb-4 row-cols-1 row-cols-lg-3">
                                            <div className="col">
                                                <label className="form-label">Product Name <sup className="text-danger">*</sup></label>
                                                <AsyncSelect
                                                    onChange={handleChangeSeller}
                                                    loadOptions={productOptions}
                                                    name="product"
                                                    isClearable={true}
                                                    value={formDataSeller.product}
                                                    className={errorBorder(fieldErrorSeller.product)}
                                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                />
                                                {errorText(fieldErrorSeller.product)}
                                            </div>
                                            <div className="col">
                                                <label className="form-label">Brand / Make</label>
                                                <AsyncSelect
                                                    onChange={handleChangeSeller}
                                                    loadOptions={brandsOptions}
                                                    name="brand"
                                                    isMulti
                                                    value={formDataSeller.brand}
                                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                />
                                            </div>
                                            <div className="col">
                                                <label className="form-label">Type of Business</label>
                                                <div className="mb-3">
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" name="business_type" type="checkbox" checked={formDataSeller.business_type["1"] ? true : false} id="ManufacInfo" onChange={handleChangeSeller} value="1" />
                                                        <label className="form-check-label" for="ManufacInfo">Manufacturer</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" name="business_type" type="checkbox" checked={formDataSeller.business_type["2"] ? true : false} id="DealerInfo" onChange={handleChangeSeller} value="2" />
                                                        <label className="form-check-label" for="DealerInfo">Dealer</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-4 row-cols-1 row-cols-lg-3">
                                            <div className="col">
                                                <label className="form-label">Industry</label>
                                                <AsyncSelect
                                                    onChange={handleChangeSeller}
                                                    loadOptions={industriesOptions}
                                                    name="industry"
                                                    value={formDataSeller.industry}
                                                    isMulti
                                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                />
                                            </div>
                                            <div className="col">
                                                <label className="form-label">Key Clients</label>
                                                <AsyncSelect
                                                    onChange={handleChangeSeller}
                                                    loadOptions={credentialsOptions}
                                                    name="credential"
                                                    value={formDataSeller.credential}
                                                    isMulti
                                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                />
                                            </div>
                                            <div className="col">
                                                <label className="form-label">Certificates</label>
                                                <AsyncSelect
                                                    onChange={handleChangeSeller}
                                                    loadOptions={certificatesOptions}
                                                    name="certificate"
                                                    value={formDataSeller.certificate}
                                                    isMulti
                                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                            return null;
                        })()}
                        <div className="cta-btn text-center">
                            <button type="button" onClick={clearSearch} className="btn btn-warning button-secondary">Clear</button>&nbsp;
                            <button type="submit" className="btn btn-primary button-style" >Search</button>
                        </div>
                    </form>
                    <div className="list-group mt-5">
                        {sellerSearchList()}
                    </div>
                    <br />
                    {showPagination()}
                </Modal.Body>
            </Modal>
            <div className="form-wrapper">
                <div className="card">
                    <div className="card-header">{ data.reference_no } { data.pr_number } { data.rfq_number } { data.subject }</div>
                    <div className="card-body form-panel-wrapper">
                        <Notification {...alertMessage} />
                        <div className="row inai-info-background">
                            <div className="col-md-4 inai-border-right ps-md-0">
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Reference Number : </b>{data.reference_no}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Start Date & Time : </b> {data.start_date_time}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>End Date & Time : </b> {endDateTime()}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Bidding Type : </b> {data.bidding_type_read}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Date of Finalization : </b>{data.finalize_date}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Currency : </b>{data.currency_read}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 inai-border-right ps-md-5">
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Place of Delivery : </b>{data.delivery_place}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Incoterms : </b> {data.incoterms_read}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Nature of Bidding : </b> {data.bidding_nature_read}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Subject : </b> {data.subject}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>PR No with Date : </b> {data.pr_number ? data.pr_number : '-'} / {data.pr_date ? data.pr_date : '-'}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>RFQ No : </b> {data.rfq_number}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 ps-md-5 pe-md-0">
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Target Delivery Date : </b> {data.delivery_date}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Price Validity : </b> {data.price_validity}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Specific End Use : </b> {data.specific_end_use}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Status : </b> {data.status_read}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <a type="button" onClick={sendReportToEmail} style={{ display: (data.status != 4 && data.bidding_type_ref != 'type_3') ? 'none' : '' }} className="btn btn-primary">Send Report to E-Mail</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="table-responsive row">
                            <label className="subtitle">Documents </label>
                            <div className="p-2">
                                <table className="table align-middle table-design inai-table table-striped table-hover table-borderless shadow mb-3 bg-white rounded">
                                    <thead>
                                        <tr>
                                            <td>S.No</td>
                                            <td>Caption</td>
                                            <td>Action</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {getErfqDocumentList()}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="table-responsive row">
                            <label className="subtitle">Items </label>
                            <div className="p-2">
                                <table className="table align-middle table-design inai-table table-striped table-hover table-borderless shadow mb-3 bg-white rounded">
                                    <thead>
                                        <tr>
                                            <td>S.No</td>
                                            <td>Item Code </td>
                                            <td>Quantity </td>
                                            <td>UOM </td>
                                            <td>Description </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {getItemList()}
                                    </tbody>
                                </table>
                            </div>
                        </div>


                        <div className="table-responsive row">
                            <label className="subtitle">Email </label>
                            <div className="p-2">
                                <table className="table align-middle table-design inai-table table-borderless shadow mb-3 bg-white rounded">

                                    <tbody>
                                    {getEmailList()}
                                    </tbody>
                                </table>
                            </div>
                        </div>


                        <div className="table-responsive row">
                            <label className="subtitle">Terms and Conditions </label>
                            <div className="p-2">
                                <table className="table align-middle table-design inai-table table-borderless shadow mb-3 bg-white rounded">

                                    <tbody>
                                         {getTermsList()}
                                    </tbody>
                                </table>
                            </div>
                        </div>


                        <div className="form-panel row">
                            <label className="subtitle">Amendment </label>
                            <form style={{ display: disable ? 'none' : '' }} onSubmit={submitAmendment}>
                                <div className="row row-cols-1 row-cols-lg-2 mb-3 form-elements">
                                    <div className="col-12 form-column form-address-panel">

                                        <div className="row mb-2 row-cols-1 row-cols-lg-2 single-col">
                                            <div className="col">
                                                <label className="form-label">Caption *</label>
                                                <input type="text" className={"form-control " + errorBorder(amendmentFieldError.file_name)} name="file_name" value={formData.file_name} onChange={handleChange} />
                                                {errorText(amendmentFieldError.file_name)}
                                            </div>
                                            <div className="col">
                                                <label className="form-label">Documents (.pdf)</label>
                                                <input type="file" className={"form-control " + errorBorder(amendmentFieldError.document)} name="document" ref={fileInput} onChange={handleChange} />
                                                {errorText(amendmentFieldError.document)}
                                            </div>
                                        </div>
                                        <div className="row mb-2 row-cols-1 single-col">
                                            <div className="col">
                                                <label className="form-label">Description</label>
                                                <textarea className={"form-control " + errorBorder(amendmentFieldError.description)} name="description" value={formData.description} onChange={handleChange}></textarea>
                                                {errorText(amendmentFieldError.description)}
                                            </div>
                                        </div>
                                        <div className="cta-btn mt-4">
                                            {(() => {
                                                if (isPermitted('site_erfq_modify_or_amendment')) {
                                                    return <button type="submit" className="btn btn-primary button-style">Submit</button>
                                                }
                                                return <button title="No permission" type="button" className="btn btn-primary button-style action-disabled">Submit</button>
                                            })()}
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="table-responsive row">
                                <div className="p-2">
                                    <table className="table align-middle table-design inai-table table-striped table-hover table-borderless shadow mb-3 bg-white rounded">
                                        <thead>
                                            <tr>
                                                <td>S.No</td>
                                                <td>Caption</td>
                                                <td>Description</td>
                                                <td>Action</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getAmendmentList()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="table-responsive row">
                            <label className="subtitle">Invited Suppliers </label>
                            <div className="p-2">
                                <table className="table align-middle table-design inai-table table-striped table-hover table-borderless shadow mb-3 bg-white rounded">
                                    <thead>
                                        <tr>
                                            <td>S.No</td>
                                            <td>Name of the Suppliers</td>
                                            <td>Bidding Position</td>
                                            <td>Status</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {getErfqParticipantsList()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="cta-btn mb-4">
                            {(() => {
                                if (isPermitted('site_erfq_invite_supplier')) {
                                    return <button type="button" className="btn btn-primary button-style" style={{ display: disable ? 'none' : '' }} disabled={disable} onClick={handleShowSeller}>Select Suppliers</button>
                                }
                                return <button title="No permission" type="button" className="btn btn-primary button-style action-disabled">Select Suppliers</button>
                            })()}
                        </div>

                        <div className="table-responsive row">
                            <label className="subtitle">Invited Suppliers Docs </label>
                            <div className="p-2">
                                <table className="table align-middle table-design inai-table table-striped table-hover table-borderless shadow mb-3 bg-white rounded">
                                    <thead>
                                        <tr>
                                            <td>S.No</td>
                                            <td>Caption</td>
                                            <td>Description</td>
                                            <td>Company</td>
                                            <td>Action</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {getParticipantsDocsList()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row ow-cols-1 row-cols-lg-1">
                            <label className="subtitle">Question and Answer </label>
                            <div class="chat-body">
                                {getQuestionAnswers()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ErfqView;