import BranchDetails from "./BranchDetails";
import CompanyDetails from "./CompanyDetails";
import ContactDetails from "./ContactDetails";
import Products from "./Products";
import Documents from "./Documents";
import ApprovalStatus from "./ApprovalStatus";
import Employees from "./Employees";
import ChangePassword from "./ChangePassword";
import CompanyProfile from "./CompanyProfile";
import Department from "./Department";

export {
    CompanyDetails,
    ContactDetails,
    BranchDetails,
    Products,
    Documents,
    ApprovalStatus,
    Employees,
    ChangePassword,
    CompanyProfile,
    Department,
}