import React, { useState, useEffect } from "react";
import { confirm } from '../../reusable/Confirmation';
import axios from "axios";
import Notification, { errorText, errorBorder } from "../../reusable/Notification";
import NavigationMenu from "./NavigationMenu";
import { AuthTokenVerify, UpdateBearer } from "../../reusable/AuthUtils";
import { PageToTop } from "../../reusable/CommonUtils";
import { noPermissionTemplate, isPermitted } from "../../reusable/PermissoinUtils";

const ContactDetails = ({history}) => {

    const initUser = {
        category: '',
        title: '',
        contact_person: '',
        phone_number: '',
        email: '',
    }

    const [ users, setUsers ] = useState([]);
    const [ categories, setCategories ] = useState([]);
    const [ alertMessage, setAlertMessage ] = useState({});
    const [ disableSubmit, setDisableSubmit ] = useState(false);
    const [ filedError, setFieldError ] = useState({});
    const [ user, setUser ] = useState(initUser);
    const [noPermission, setNoPermission] = useState(false);

    useEffect(() => {

        AuthTokenVerify(history);

        axios.get("user/company-contact/").then((response) => {
            setUsers(response.data.contacts);
            setCategories(response.data.categories);
            UpdateBearer(history, response);
        }).catch((e) => {
            if(e.response.status === 403) {
                //permission denied
                setNoPermission(true);
            }
        });

        PageToTop();
    }, []);

    const editItem = (key) => {
        setAlertMessage({});
        setFieldError({});
        setUser({...users[key], ['index']: key});
    };

    const deleteItem = async (key) => {
        const result = await confirm('Are you sure to delete this?');
        if(!result) return;

        clearAddForm();
        const pk = users[key].pk;
        axios.delete("user/company-contact/" + pk + "/")
            .then((response) => {
                if(response.status === 200){
                    setAlertMessage({type:'success', message:"Contact Deleted Successfully!"});
                    const newUsers = [...users];
                    newUsers.splice(key, 1);
                    setUsers(newUsers);
                }
            })
            .catch((e) => {
                if(e.response.status === 400) {
                    setAlertMessage({type:'danger', message:"Error Occured While Delete!"});
                }
            });
        PageToTop();
    };

    const clearAddForm = () => {
        setAlertMessage({});
        setFieldError({});
        setUser(initUser);
    };

    const renderBody = () => {
        const content = [];

        users.map( (item, key) => {
            content.push(<tr key={key} >
                <td>{ item.title_read } { item.contact_person }</td>
                <td>{ item.category_name }</td>
                <td>{ item.phone_number }</td>
                <td>{ item.email }</td>
                <td className="text-center">
                    {(() => {
                        if(isPermitted('site_my_profile_contact_details_modify_contact')){
                            return <button onClick={() => editItem(key) } type="button" className="btn btn-primary">
                                <i className="bi bi-pencil-fill"></i>
                            </button>
                        }

                        return <button  title="No permission"  type="button" className="btn btn-primary action-disabled">
                            <i className="bi bi-pencil-fill"></i>
                        </button>
                        
                    })()}

                    {(() => {
                        if(isPermitted('site_my_profile_contact_details_delete_contact')){
                            return <button onClick={() => deleteItem(key) } type="button" className="btn btn-danger ms-1">
                                <i className="bi bi-trash-fill"></i>
                            </button>
                        }
                        
                        return <button  title="No permission"  type="button" className="btn btn-danger ms-1 action-disabled">
                            <i className="bi bi-trash-fill"></i>
                        </button>
                    })()}
                </td>
            </tr>);
        });

        if(content.length < 1){
            content.push(<tr>
                <td colSpan="5" className="text-center">No records found</td>
            </tr>);
        }

        return content;
    };

    const getCategories = () => {
        const content = [<option value="0">-select-</option>];
        categories.map(item => {
            content.push(<option value={item.pk}>{item.category}</option>);
        });
        return content;
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setAlertMessage({});
        setFieldError({});
        setDisableSubmit(true);
        if(user.pk){
            axios.put("user/company-contact/" + user.pk + "/", user)
            .then((response) => {
                if(response.status === 200){
                    const newUsers = [...users];
                    newUsers[user.index] = response.data;
                    setUsers(newUsers);
                    setUser(initUser);
                    setAlertMessage({type:'success', message:"Contact Updated Successfully!"});
                    setDisableSubmit(false);
                }
            })
            .catch((e) => {
                if(e.response.status === 400) {
                    setAlertMessage({type:'danger', message:"Error Occured While Submit!"});
                    const dict = {};
                    for (const [key, value] of Object.entries(e.response.data)) {
                        dict[key] = value.join(', ');
                    }
                    setFieldError(dict);
                }

                setDisableSubmit(false);
            });
        }else{
            axios.post("user/company-contact/", user)
            .then((response) => {
                if(response.status === 200){
                    setUsers([...users, response.data]);
                    setUser(initUser);
                    setAlertMessage({type:'success', message:"Contact Added Successfully!"});
                    setDisableSubmit(false);
                }
            })
            .catch((e) => {
                if(e.response.status === 400) {
                    setAlertMessage({type:'danger', message:"Error Occured While Submit!"});
                    let dict = {};
                    for (const [key, value] of Object.entries(e.response.data)) {
                        dict[key] = value.join(', ');
                    }
                    setFieldError(dict);
                }

                setDisableSubmit(false);
            });
        }

        PageToTop();

    };

    const handleChange = (e) => {
        setUser({
            ...user,
        
            [e.target.name]: e.target.value
        });
    };

    if(noPermission){
        return (
        <div className="container">
            <div className="form-wrapper">
                <NavigationMenu item={ 2 }  />
                <div className="form-panel-wrapper">
                    { noPermissionTemplate() }
                </div>
            </div>
        </div>
        )
    }
    
    return (
        <div className="container">
            <div className="form-wrapper">
                <NavigationMenu item={ 2 }  />
                <div className="form-panel-wrapper">
                    <Notification {...alertMessage}/>
                    <fieldset disabled={!isPermitted('site_my_profile_contact_details_add_contact') && !user.pk}>
                        <form onSubmit={ onSubmit }>
                            <div className="form-panel">
                                <div className="row row-cols-1 row-cols-lg-2 mb-3 form-elements">
                                    <div className="col-12 form-column">
                                        <div className="row mb-4 row-cols-1 row-cols-lg-3">
                                            <div className="col">
                                                <label className="form-label">Designation <sup className="text-danger">*</sup></label>
                                                <select onChange={handleChange} value={user.category} name="category" className={"form-select " + errorBorder(filedError.category) }>
                                                    { getCategories() }
                                                </select>
                                                { errorText(filedError.category) }
                                            </div>

                                            <div className="col">
                                                <label className="form-label">Title <sup className="text-danger">*</sup></label>
                                                <select onChange={handleChange} value={user.title} name="title" className={"form-select " + errorBorder(filedError.title) }>
                                                    <option value="0">-select-</option>
                                                    <option value="1">Mr</option>
                                                    <option value="2">Mrs</option>
                                                    <option value="3">Ms</option>
                                                </select>
                                                { errorText(filedError.title) }
                                            </div>

                                            <div className="col">
                                                <label className="form-label">Name <sup className="text-danger">*</sup></label>
                                                <input onChange={handleChange} value={user.contact_person} name="contact_person" type="text" className={"form-control " + errorBorder(filedError.contact_person) } />
                                                { errorText(filedError.contact_person) }
                                            </div>

                                        </div>

                                        <div className="row mb-4 row-cols-1 row-cols-lg-3">
                                            <div className="col">
                                                <label className="form-label">Phone <sup className="text-danger">*</sup></label>
                                                <input onChange={handleChange} value={user.phone_number} name="phone_number" type="text" className={"form-control " + errorBorder(filedError.phone_number) } />
                                                { errorText(filedError.phone_number) }
                                            </div>
                                            <div className="col">
                                                <label className="form-label">Email <sup className="text-danger">*</sup></label>
                                                <input onChange={handleChange} value={user.email}  name="email" type="text" className={"form-control " + errorBorder(filedError.email) } />
                                                { errorText(filedError.email) }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 text-end">
                            {(() => {
                                if(user.pk){
                                    return <>
                                        <a type="button" onClick={() => clearAddForm()} className="button-style">Cancel</a>&nbsp;&nbsp;
                                        <button type="submit" disabled={ disableSubmit } className="button-style">Update</button>
                                    </>
                                }else{
                                    if(isPermitted('site_my_profile_contact_details_add_contact')){
                                        return <button type="submit" disabled={ disableSubmit } className="button-style">Add Contact</button>
                                    }
                                    return <button title="No permission" type="button" className="button-style action-disabled">Add Contact</button>
                                }
                                
                            })()}
                            </div>
                        </form>
                    </fieldset>
                    
                    <div className="table-responsive row">
                        <table className="table align-middle table-bordered table-design">
                            <thead>
                            <tr>
                                <td>Name</td>
                                <td>Designation</td>
                                <td>Phone</td>
                                <td>Email</td>
                                <td>Action</td>
                            </tr>
                            </thead>
                            <tbody>
                                { renderBody() }
                            </tbody>
                        </table>
                    </div>
                
                </div>
                </div>
            </div>
    )
}

export default ContactDetails