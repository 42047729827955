import React, { useState, useEffect } from "react";

const CommonSearch = ({handleSearch }) => {

    const [searchTerm, setSearchTerm] = useState('');

    return (
            <div className="input-group">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    aria-label="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <button className="btn btn-primary" type="button" onClick={() => handleSearch(searchTerm)}>
                    Search
                </button>
                <button className="btn btn-secondary" type="button" onClick={() => { setSearchTerm(''); handleSearch(''); }}>
                    Clear
                </button>
            </div>
    )
}

export default CommonSearch