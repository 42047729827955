import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { AuthTokenVerify, UpdateBearer } from "../../reusable/AuthUtils";
import { PageToTop } from "../../reusable/CommonUtils";
import Notification, { errorText, errorBorder } from "../../reusable/Notification";
import { returnOnlyDefined } from "../../reusable/CommonUtils";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import { isPermitted } from "../../reusable/PermissoinUtils";



const EquoteView = ({ history }) => {

    let { id } = useParams();

    const [data, setData] = useState({ erfq_form_documents: [] });
    const [items, setItems] = useState([]);
    const [amendments, setAmendments] = useState([]);
    const [sellerDocs, setSellerDocs] = useState([]);
    const [termsConditions, setTermsConditions] = useState([]);
    const [documentView, setDocumentView] = useState('');
    const [alertMessage, setAlertMessage] = useState({});
    const [refresh, setRefresh] = useState(true);
    const [enableSubmit, setEnableSubmit] = useState(false);
    const fileInput = useRef(null);
    const [disable, setDisable] = React.useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);

    const [documentFieldError, setDocumentFieldError] = useState({});

    const initForm = {
        'file_name': '',
        'document': [],
        'description': ''
    };

    const [formData, setFormData] = useState(initForm);

    const itemQuoteInit = {
        items: {}, discount: 0, discount_type: 1, packing: 0,
        packing_type: 1, fright: 0, fright_type: 1
    };
    const [itemQuote, setItemQuote] = useState(itemQuoteInit);

    const itemQuoteErrorInit = { discount: [], items: {}, fright: [], packing: [] };
    const [itemQuoteError, setItemQuoteError] = useState(itemQuoteErrorInit);

    const [question, setQuestion] = useState('');
    const [disableQButton, setDisableQButton] = useState(false);
    const [questionErr, setQuestionErr] = useState([]);
    const [questionAnswers, setQuestionAnswers] = useState({});

    const [termsConditionsResponse, setTermsConditionsResponse] = useState({});
    const [termsConditionsResponseErr, setTermsConditionsResponseErr] = useState({});
    const [enableTermsCondition, setEnableTermsCondition] = useState(false);

    // bootsrap model for document render
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    useEffect(() => {
        AuthTokenVerify(history);
        PageToTop();
    }, []);

    useEffect(() => {
        let ensub = (data.participant_status == 2 && data.status == 1) ? true : false;
        setEnableSubmit(ensub);
        let acceptEnable = (data.participant_status == 1 && data.status != 4) ? true : false;
        setDisable(data.participant_status != 2);
        setEnableTermsCondition(acceptEnable);
    }, [data.participant_status, data.status]);

    useEffect(() => {
        if (!refresh) return;
        axios.get("erfq/equote-view/" + id + "/").then((response) => {
            setData(response.data);
            setItems(response.data.erfq_form_items);
            let itemqt = {};
            response.data.erfq_form_items.map((row) => {
                itemqt[row.pk] = {
                    item_price: "",
                    item_hsn: "",
                    item_gst: 0,
                };
            });
            setItemQuote({ ...itemQuote, items: itemqt });
            setTermsConditions(response.data.erfq_form_terms);


            if (Object.keys(response.data.conditions_response).length != 0) {
                setTermsConditionsResponse(response.data.conditions_response);
            } else {
                let termsans = {};
                response.data.erfq_form_terms.map((row) => {
                    termsans[row.pk] = { 'is_accepted': null, 'description': '' };
                });
                setTermsConditionsResponse(termsans);
            }


            setAmendments(response.data.erfq_form_amendments);
            setSellerDocs(response.data.erfq_participant_docs);

            let equote_data = response.data.equote_data;
            if (!_.isEmpty(equote_data)) {
                setItemQuote({
                    items: response.data.equote_items_data,
                    discount: equote_data.discount,
                    discount_type: equote_data.discount_type,
                    fright: equote_data.fright,
                    fright_type: equote_data.fright_type,
                    packing: equote_data.packing,
                    packing_type: equote_data.packing_type
                })
            }

            setQuestionAnswers(response.data.questions_answers);

            UpdateBearer(history, response);
        }).catch((e) => {
            UpdateBearer(history, e.response);
            if (e.response.status === 404 || e.response.status === 401) {
                history('/equote-summary');
            }
        });
        setRefresh(false);
    }, [refresh]);

    const submitDocument = (e) => {
        e.preventDefault();
        setAlertMessage({});
        setDocumentFieldError({});
        setDisableSubmit(true);

        let fData = new FormData();
        fData.append("file_name", formData.file_name);
        fData.append("document", formData.document);
        fData.append("description", formData.description);
        fData.append("erfq_id", data.pk);

        axios({
            method: "post",
            url: "erfq/equote-seller-document/",
            data: fData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then((response) => {
                setFormData(initForm);
                setAlertMessage({ type: 'success', message: "Document Uploaded Successfully!" });
                setDisableSubmit(false);
                const newRecords = [...sellerDocs];
                newRecords.push(response.data);
                setSellerDocs(newRecords);
                fileInput.current.value = '';
            })
            .catch((e) => {
                if (e.response.status === 400) {
                    setAlertMessage({ type: 'danger', message: "Error Occured While Submit!" });
                    const dict = {};
                    for (const [key, value] of Object.entries(e.response.data)) {
                        dict[key] = value.join(', ');
                    }
                    setDocumentFieldError(dict);
                }
                setDisableSubmit(false);
            });
        PageToTop();
    };

    const handleChange = (e) => {

        var targetData = '';

        switch (e.target.name) {
            case "document":
                targetData = e.target.files[0];
                break;
            default:
                targetData = e.target.value;
                break;
        }

        setFormData({
            ...formData,
            [e.target.name]: targetData
        });
    };

    const submitQuestion = () => {
        setDisableQButton(true);
        setQuestionErr([]);
        setAlertMessage({});

        axios.post("erfq/question/" + id + "/", {
            question: question
        }).then((response) => {
            UpdateBearer(history, response);
            setDisableQButton(false);
            setQuestion('');
            setAlertMessage({ type: 'success', message: response.data.message });
            PageToTop();

            let question = response.data.question;
            setQuestionAnswers({
                ...questionAnswers,
                [question.pk]: {
                    question: question,
                    answers: []
                }
            });
        }).catch((e) => {
            UpdateBearer(history, e.response);
            if (e.response.status === 400) {
                setQuestionErr(e.response.data.question);
            }
            if ([404, 406].includes(e.response.status)) {
                setAlertMessage({ type: 'danger', message: e.response.data.message });
                PageToTop();
            }
            setDisableQButton(false);
        });

    };

    const handleItemQuoateChange = (e, pk = false) => {
        var targetValue = parseInt(e.target.value);

        if (e.target.name != 'discount_type') {
            targetValue = parseFloat(e.target.value);
            if (e.target.value.split(".")[1] && e.target.value.split(".")[1].length > 2) {
                targetValue = targetValue.toFixed(2);
            }

        }

        switch (e.target.name) {
            case 'item_price':
                var items = itemQuote.items;
                items[pk][e.target.name] = targetValue;
                setItemQuote({ ...itemQuote, items: items });
                break;
            case 'item_hsn':
                var items = itemQuote.items;
                items[pk][e.target.name] = targetValue;
                setItemQuote({ ...itemQuote, items: items });
                break;
            case 'item_gst':
                var items = itemQuote.items;
                items[pk][e.target.name] = targetValue;
                setItemQuote({ ...itemQuote, items: items });
                break;
            case "discount":
                if (targetValue > 100 && itemQuote.discount_type == 1) {
                    targetValue = 100;
                }
                setItemQuote({ ...itemQuote, [e.target.name]: targetValue });
                break;
            case "discount_type":
                setItemQuote({ ...itemQuote, [e.target.name]: targetValue, 'discount': 0 });
                break;
            case "packing":
                if (targetValue > 100 && itemQuote.packing_type == 1) {
                    targetValue = 100;
                }
                setItemQuote({ ...itemQuote, [e.target.name]: targetValue });
                break;
            case "packing_type":
                setItemQuote({ ...itemQuote, [e.target.name]: targetValue, 'packing': 0 });
                break;
            case "fright":
                if (targetValue > 100 && itemQuote.fright_type == 1) {
                    targetValue = 100;
                }
                setItemQuote({ ...itemQuote, [e.target.name]: targetValue });
                break;
            case "fright_type":
                setItemQuote({ ...itemQuote, [e.target.name]: targetValue, 'fright': 0 });
                break;
        }
    };

    const getItemList = () => {
        const content = [];
        let overal_total = 0;
        let net_total = 0;
        items.map((row, key) => {

            let item_price;
            let item_hsn;
            let item_gst;

            if (!_.isEmpty(itemQuote['items'])) {
                item_price = itemQuote['items'][row.pk]['item_price'];
                item_hsn = itemQuote['items'][row.pk]['item_hsn'];
                item_gst = itemQuote['items'][row.pk]['item_gst'];
            }

            let total = parseFloat(Number((isNaN(item_price) ? 0 : item_price) * parseInt(row.item_qty)).toFixed(2));
            item_price = item_price > 0 ? item_price : '';
            overal_total += total;
            content.push(<tr key={key} >
                <td>{(key + 1)}</td>
                <td className="text-center">{row.item_code}</td>
                <td className="text-center">{row.item_qty}</td>
                <td className="text-center">{row.item_uom_read}</td>
                <td className="text-center">{row.description}</td>
                <td>
                    <input type="number" className="form-control input-small" disabled={!enableSubmit} name="item_hsn" value={item_hsn} onChange={(e) => handleItemQuoateChange(e, row.pk)} />
                    {errorText(returnOnlyDefined(itemQuoteError, 'object.items[' + row.pk + ']["item_hsn"].join(",")'))}
                </td>
                <td>
                    <input type="number" className="form-control input-small" disabled={!enableSubmit} name="item_gst" value={item_gst} onChange={(e) => handleItemQuoateChange(e, row.pk)} />
                    {errorText(returnOnlyDefined(itemQuoteError, 'object.items[' + row.pk + ']["item_gst"].join(",")'))}
                </td>
                <td className="text-end">
                    <input type="number" className="form-control input-small" disabled={!enableSubmit} name="item_price" value={item_price} onChange={(e) => handleItemQuoateChange(e, row.pk)} />
                    {errorText(returnOnlyDefined(itemQuoteError, 'object.items[' + row.pk + ']["item_price"].join(",")'))}
                </td>
                <td className="text-end">{Number(total).toFixed(2)}</td>
            </tr>);
        });

        overal_total = parseFloat(parseFloat(overal_total).toFixed(2));

        content.push(<tr>
            <td colSpan="7"></td>
            <td className="text-end">Total :</td>
            <td>{Number(overal_total).toFixed(2)}</td>
        </tr>);


        content.push(
            <tr>
                <td colSpan="7"></td>
                <td className="text-end" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '1rem' }}>
                    <span style={{ marginRight: '10px' }}>Discount:</span>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <label className="cursor-pointer" style={{ display: 'inline-flex', alignItems: 'center' }}>
                            % 
                            <input
                                disabled={!enableSubmit}
                                type="radio"
                                value={1}
                                onChange={(e) => handleItemQuoateChange(e)}
                                checked={itemQuote.discount_type == 1}
                                name="discount_type"
                                style={{ marginLeft: '5px' }}
                            />
                        </label>
                        <label className="cursor-pointer" style={{ display: 'inline-flex', alignItems: 'center' }}>
                            fixed 
                            <input
                                disabled={!enableSubmit}
                                type="radio"
                                value={2}
                                onChange={(e) => handleItemQuoateChange(e)}
                                checked={itemQuote.discount_type == 2}
                                name="discount_type"
                                style={{ marginLeft: '5px' }}
                            />
                        </label>
                    </div>
                </td>
                <td>
                    <input
                        type="number"
                        className="form-control input-small"
                        disabled={!enableSubmit}
                        name="discount"
                        value={itemQuote.discount > 0 ? itemQuote.discount : ''}
                        onChange={(e) => handleItemQuoateChange(e)}
                    />
                </td>
            </tr>
            );

        content.push(
            <tr>
                <td colSpan="7"></td>
                <td className="text-end" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '1rem' }}>
                    <span style={{ marginRight: '10px' }}>Packing :</span>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <label className="cursor-pointer" style={{ display: 'inline-flex', alignItems: 'center' }}>
                            % 
                            <input
                                disabled={!enableSubmit}
                                type="radio"
                                value={1}
                                onChange={(e) => handleItemQuoateChange(e)}
                                checked={itemQuote.packing_type == 1}
                                name="packing_type"
                                style={{ marginLeft: '5px' }}
                            />
                        </label>
                        <label className="cursor-pointer" style={{ display: 'inline-flex', alignItems: 'center' }}>
                            fixed 
                            <input
                                disabled={!enableSubmit}
                                type="radio"
                                value={2}
                                onChange={(e) => handleItemQuoateChange(e)}
                                checked={itemQuote.packing_type == 2}
                                name="packing_type"
                                style={{ marginLeft: '5px' }}
                            />
                        </label>
                    </div>
                </td>
                <td>
                    <input
                        type="number"
                        className="form-control input-small"
                        disabled={!enableSubmit}
                        name="packing"
                        value={itemQuote.packing > 0 ? itemQuote.packing : ''}
                        onChange={(e) => handleItemQuoateChange(e)}
                    />
                </td>
            </tr>
            );


        content.push(
            <tr>
                <td colSpan="7"></td>
                <td className="text-end" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '1rem' }}>
                    <span style={{ marginRight: '20px' }}>Freight:</span>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '11px' }}>
                        <label className="cursor-pointer" style={{ display: 'inline-flex', alignItems: 'center' }}>
                            % 
                            <input
                                disabled={!enableSubmit}
                                type="radio"
                                value={1}
                                onChange={(e) => handleItemQuoateChange(e)}
                                checked={itemQuote.fright_type == 1}
                                name="fright_type"
                                style={{ marginLeft: '5px' }}
                            />
                        </label>
                        <label className="cursor-pointer" style={{ display: 'inline-flex', alignItems: 'center' }}>
                            fixed 
                            <input
                                disabled={!enableSubmit}
                                type="radio"
                                value={2}
                                onChange={(e) => handleItemQuoateChange(e)}
                                checked={itemQuote.fright_type == 2}
                                name="fright_type"
                                style={{ marginLeft: '5px' }}
                            />
                        </label>
                    </div>
                </td>
                <td>
                    <input
                        type="number"
                        className="form-control input-small"
                        disabled={!enableSubmit}
                        name="fright"
                        value={itemQuote.fright > 0 ? itemQuote.fright : ''}
                        onChange={(e) => handleItemQuoateChange(e)}
                        style={{ width: '91%' }} // Ensure the input takes the full width of its container
                    />
                </td>
            </tr>
            );
            

        let discount = parseFloat(Number(itemQuote.discount).toFixed(2));
        if (itemQuote.discount_type == 1) {
            discount = (overal_total / 100) * discount;
        }
        discount = (isNaN(discount)) ? 0 : discount;
        net_total = Number(overal_total > 0 ? overal_total - discount : 0).toFixed(2);

        content.push(<tr>
            <td colSpan="7"></td>
            <td className="text-end">Net Total :</td>
            <td>{net_total}</td>
        </tr>);

        if (enableSubmit) {
            content.push(<tr>
                <td colSpan="8"></td>
                <td>
                    {(() => {
                        if (isPermitted('site_eqoue_submit_the_quote')) {
                            return <button type="button" className="btn btn-success" onClick={submitBid} >Submit</button>
                        }

                        return <button type="button" title="No permission" className="btn btn-success action-disabled" >Submit</button>
                    })()}
                </td>
            </tr>);
        }



        return content;
    };

    const submitBid = () => {
        setItemQuoteError(itemQuoteErrorInit);
        setAlertMessage({});

        axios.post("erfq/equote-view/" + id + "/", {
            discount: itemQuote.discount,
            discount_type: itemQuote.discount_type,
            fright: itemQuote.fright,
            fright_type: itemQuote.fright_type,
            packing: itemQuote.packing,
            packing_type: itemQuote.packing_type,
            items: JSON.stringify(itemQuote.items)
        }).then((response) => {
            UpdateBearer(history, response);
            setAlertMessage({ type: 'success', message: response.data.message });
            PageToTop();
        }).catch((e) => {
            UpdateBearer(history, e.response);
            if (e.response.status === 404) {
                history('/equote-summary');
            }
            if (e.response.status === 400) {
                setItemQuoteError(e.response.data);
            }
            if ([406, 417].includes(e.response.status)) {
                setAlertMessage({ type: 'danger', message: e.response.data.message });
                PageToTop();
            }
        });
    };

    const renderAmendmentDocument = (key) => {
        axios.get("erfq/equote-amendment-document-render/" + key + "/").then((response) => {
            setDocumentView("data:application/pdf;base64," + response.data);
            handleShow();
        }).catch((error) => {
            //
        });
    };

    const renderSellerDocument = (key) => {
        axios.get("erfq/equote-seller-document-render/" + key + "/").then((response) => {
            setDocumentView("data:application/pdf;base64," + response.data);
            handleShow();
        }).catch((error) => {
            //
        });
    };

    const downloadSellerDocument = (key) => {
        axios({
            url: "erfq/equote-seller-document-download/" + key + "/",
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            const filename = response.headers.filename;
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });
    };

    const getAmendmentList = () => {
        const content = [];
        amendments.map((row, key) => {
            content.push(<tr key={key} >
                <td>{(key + 1)}</td>
                <td>{row.file_name}</td>
                <td>{row.description}</td>
                <td>
                    {(() => {
                        if (row.document) {
                            return (
                                <i disabled onClick={() => renderAmendmentDocument(row.pk)} className="btn btn-success bi bi-eye-fill cursor-pointer"></i>
                            )
                        }
                        return null;
                    })()}
                </td>
            </tr>);
        });

        if (content.length < 1) {
            content.push(<tr>
                <td colSpan="4" className="text-center">No records found</td>
            </tr>);
        }

        return content;
    };

    const getSellerDocumentList = () => {
        const content = [];
        sellerDocs.map((row, key) => {
            content.push(<tr key={key} >
                <td>{(key + 1)}</td>
                <td>{row.file_name}</td>
                <td>{row.description}</td>
                <td>
                    {(() => {
                        if (row.document) {
                            return (
                                <i disabled onClick={() => downloadSellerDocument(row.pk)} className="btn btn-primary bi bi-cloud-download-fill cursor-pointer"></i>
                            )
                        }
                        return null;
                    })()}
                </td>
            </tr>);
        });

        if (content.length < 1) {
            content.push(<tr>
                <td colSpan="4" className="text-center">No records found</td>
            </tr>);
        }

        return content;
    };

    const handleTermsCondtionResponse = (e, name, pk) => {
        let temp = termsConditionsResponse[pk];
        temp[name] = e.target.value;
        setTermsConditionsResponse({ ...termsConditionsResponse, [pk]: temp });
    };

    const getTermsList = () => {
        const content = [];
        termsConditions.map((row, key) => {
            content.push(
                <tr key={key}>
                    <td className="text-left">{(key + 1)}</td>
                    <td>{row.terms_condition}</td>
                    <td className="text-left">
                        <div className="form-check form-check-inline ">
                            <label className="form-check-label text-success">
                                <input className="form-check-input" disabled={!enableTermsCondition} checked={termsConditionsResponse[row.pk] && termsConditionsResponse[row.pk].is_accepted == 1} value={1} type="radio" onClick={(e) => handleTermsCondtionResponse(e, 'is_accepted', row.pk)} name={'is_accepted_' + key} />
                                <b>Agree</b>
                            </label>
                        </div>
                        <div className="form-check form-check-inline ">
                            <label className="form-check-label text-danger">
                                <input className="form-check-input" disabled={!enableTermsCondition} checked={termsConditionsResponse[row.pk] && termsConditionsResponse[row.pk].is_accepted == 0} value={0} type="radio" onClick={(e) => handleTermsCondtionResponse(e, 'is_accepted', row.pk)} name={'is_accepted_' + key} />
                                <b>Disagree</b>
                            </label>
                        </div>
                        <br />{errorText(returnOnlyDefined(termsConditionsResponseErr, 'object[' + row.pk + '].is_accepted.join(",")'))}
                    </td>
                    <td>
                        <textarea className="form-control" disabled={!enableTermsCondition} name="description" value={termsConditionsResponse[row.pk] && termsConditionsResponse[row.pk].description} onChange={(e) => handleTermsCondtionResponse(e, 'description', row.pk)} maxLength={200} cols="4"></textarea>
                        {errorText(returnOnlyDefined(termsConditionsResponseErr, 'object[' + row.pk + '].description.join(",")'))}
                    </td>
                </tr>
            );
        });

        if (content.length < 1) {
            content.push(<tr>
                <td className="text-center" colSpan={4}>No records found</td>
            </tr>);
        }

        return content;
    };

    const responseToInvite = (status) => {
        setTermsConditionsResponseErr({});
        axios.patch("erfq/equote-view/" + id + "/", {
            'status': status,
            'condition_response': termsConditionsResponse
        }).then((response) => {
            if (response.data.status == 2) {
                setAlertMessage({ type: 'success', message: response.data.message });
            } else {
                setAlertMessage({ type: 'warning', message: response.data.message });
            }

            UpdateBearer(history, response);
            setRefresh(true);
        }).catch((e) => {
            UpdateBearer(history, e.response);
            if (e.response.status === 404) {
                history('/equote-summary');
            }
            if (e.response.status === 400) {
                setTermsConditionsResponseErr(e.response.data);
            }
        });
    };

    const renderAcceptOrReject = () => {

        if (enableTermsCondition) {
            if (isPermitted('site_quote_accept_reject_erfq_invitation')) {
                return <tr>
                    <td className="text-center" colSpan={4}>
                        <button type="button" onClick={() => responseToInvite('accept')} className="btn btn-success">Accept</button>&nbsp;
                        <button type="button" onClick={() => responseToInvite('reject')} className="btn btn-danger">Decline</button>
                    </td>
                </tr>;
            } else {
                return <tr>
                    <td className="text-center" colSpan={4}>
                        <button type="button" title="No permission" className="btn btn-success action-disabled">Accept</button>&nbsp;
                        <button type="button" title="No permission" className="btn btn-danger action-disabled">Decline</button>
                    </td>
                </tr>;
            }
        }
    };

    const renderErfqDocument = (key) => {
        axios.get("erfq/equote-document-render/" + key + "/").then((response) => {
            setDocumentView("data:application/pdf;base64," + response.data);
            handleShow();
        }).catch((error) => {
            //
        });
    };


    const getErfqDocumentList = () => {
        const content = [];
        data.erfq_form_documents.map((row, key) => {
            content.push(<tr key={key} >
                <td>{(key + 1)}</td>
                <td>{row.file_name}</td>
                <td>
                    {(() => {
                        if (row.document) {
                            return (
                                <i disabled onClick={() => renderErfqDocument(row.pk)} className="btn btn-success bi bi-eye-fill cursor-pointer"></i>
                            )
                        }
                        return null;
                    })()}
                </td>
            </tr>);
        });

        if (content.length < 1) {
            content.push(<tr>
                <td colSpan="3" className="text-center">No records found</td>
            </tr>);
        }

        return content;
    };

    const getQuestionAnswers = () => {

        const content = [];

        Object.keys(questionAnswers).map((key, index) => {
            let question_answer = questionAnswers[key];
            let question = question_answer.question;

            content.push(
                <div className="message info">
                    <div className="message-logo"><label>{question.company_read ? question.company_read.substring(0, 1) : <i class="bi bi-eye-slash"></i>}</label></div>
                    <div className="message-body">
                        <div className="message-info">
                            <h4>{question.company_read}</h4>
                            <h5> <i className="bi bi-clock"></i> {question.created_on}</h5>
                        </div>
                        <hr></hr>
                        <div className="message-text">{question.question}</div>
                    </div>
                    <br></br>
                </div>
            );


            question_answer.answers.map((row, key) => {
                content.push(
                    <div className="message reply">
                        <div className="message-logo"><label>{row.company_read.substring(0, 1)}</label></div>
                        <div className="message-body">
                            <div className="message-info">
                                <h4>{row.company_read}</h4>
                                <h5> <i className="bi bi-clock"></i> {row.created_on}</h5>
                            </div>
                            <hr></hr>
                            <div className="message-text">{row.answer}</div>
                        </div>
                        <br></br>
                    </div>
                );
            });

        });

        if (data.status != 4 && isPermitted('site_eqoue_question_and_answer')) {

            content.push(
                <div className="message reply-box">
                    <div className="message-logo"><label><i class="bi bi-arrow-return-right"></i></label></div>
                    <div className="message-body mb-1">
                        <textarea 
                            className="form-control message-reply"
                            placeholder="Please ask your questions here"
                            name="answer"
                            onChange={(e) => { setQuestion(e.target.value) }}
                            value={question}
                        />
                    </div>
                    <button type="submit" disabled={disableQButton} onClick={submitQuestion} className="btn btn-success p-2">Ask</button>
                    {errorText(questionErr.join(','))}
                </div>
            );
        }

        if (content.length < 1) {
            content.push(<tr>
                <i>No records found</i>
            </tr>);
        }

        return content;
    };

    return (
        <div className="container">
            <Modal dialogClassName="modal-80" show={show} onHide={handleClose}>
                <Modal.Body>
                    <object>
                        <embed name="Title of my video" type="text/html" style={{ width: '100%', height: '85vh' }} src={documentView} />
                    </object>
                </Modal.Body>
            </Modal>
            <div className="form-wrapper">
                <div className="card">
                    <div className="card-header">{ data.reference_no } { data.pr_number } { data.rfq_number } { data.subject }</div>
                    <div className="card-body form-panel-wrapper">
                        <Notification {...alertMessage} />
                        <div className="row inai-info-background">
                            <div className="col-md-4 inai-border-right ps-0">
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Reference Number : </b>{data.reference_no}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Start Date & Time : </b> {data.start_date_time}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>End Date & Time : </b> {data.end_date_time}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Bidding Type : </b> {data.bidding_type_read}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Date of Finalization : </b> {data.finalize_date}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 inai-border-right ps-5">
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Currency : </b> {data.currency_read}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Place of Delivery : </b> {data.delivery_place}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Incoterms : </b> {data.incoterms_read}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Nature of Bidding : </b> {data.bidding_nature_read}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Subject : </b> {data.subject}</label>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 ps-5 pe-0">
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>PR No with Date : </b> {data.pr_number ? data.pr_number : '-'} / {data.pr_date ? data.pr_date : '-'}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>RFQ No : </b> {data.rfq_number}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Status : </b> {data.status_read}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Target Delivery Date : </b> {data.delivery_date}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <label className="form-label"><b>Price Validity : </b> {data.price_validity}</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="table-responsive row">
                            <label className="subtitle">Documents </label>
                            <div className="p-2">
                                <table className="table align-middle table-design inai-table table-striped table-hover table-borderless shadow mb-3 bg-white rounded">
                                    <thead>
                                        <tr>
                                            <td>S.No</td>
                                            <td>Caption</td>
                                            <td>Action</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {getErfqDocumentList()}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="table-responsive row">
                            <label className="subtitle">Amendment </label>
                            <div className="p-2">
                                <table className="table align-middle table-design inai-table table-striped table-hover table-borderless shadow mb-3 bg-white rounded">
                                    <thead>
                                        <tr>
                                            <td>S.No</td>
                                            <td>Caption</td>
                                            <td>Description</td>
                                            <td>Action</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {getAmendmentList()}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="table-responsive row">
                            <label className="subtitle">Terms & Conditions </label>
                            <div className="p-2">
                                <table className="table align-middle table-design inai-table table-striped table-hover table-borderless shadow mb-3 bg-white rounded">
                                    <thead>
                                        <tr>
                                            <td>S.No</td>
                                            <td width="600">Terms and Conditions</td>
                                            <td className="subject-width">Action</td>
                                            <td width="400">Remarks</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {getTermsList()}
                                        {renderAcceptOrReject()}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="form-panel">
                            <form onSubmit={submitDocument}>
                                <div className="row row-cols-1 row-cols-lg-2 mb-3 form-elements">
                                    <div className="col-12 form-column form-address-panel">
                                        <div className="row mb-2 row-cols-1 row-cols-lg-2 single-col">
                                            <div className="col">
                                                <label className="form-label">Caption *</label>
                                                <input type="text" className={"form-control " + errorBorder(documentFieldError.file_name)} disabled={disable} name="file_name" value={formData.file_name} onChange={handleChange} />
                                                {errorText(documentFieldError.file_name)}
                                            </div>
                                            <div className="col">
                                                <label className="form-label">Documents (.pdf, .jpg, .jpeg, .png, .gif, .doc, .docx, .xls, .xlsx)</label>
                                                <input type="file" className={"form-control " + errorBorder(documentFieldError.document)} disabled={disable} name="document" ref={fileInput} onChange={handleChange} />
                                                {errorText(documentFieldError.document)}
                                            </div>
                                        </div>
                                        <div className="row mb-2 row-cols-1 single-col">
                                            <div className="col">
                                                <label className="form-label">Description</label>
                                                <textarea className={"form-control " + errorBorder(documentFieldError.description)} disabled={disable} name="description" value={formData.description} onChange={handleChange} style={{ height: '70px' }}></textarea>
                                                {errorText(documentFieldError.description)}
                                            </div>
                                        </div>
                                        <div className="cta-btn mt-4">
                                            {(() => {
                                                if (isPermitted('site_eqoue_attach_document')) {
                                                    return <button type="submit" disabled={disable} style={{ display: disable ? 'none' : '' }} className="btn btn-primary button-style">Submit</button>
                                                }
                                                return <button title="No permission" type="button" className="btn btn-primary button-style action-disabled">Submit</button>
                                            })()}
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <div className="table-responsive row">
                                <label className="subtitle">Suppliers Docs </label>
                                <div className="p-2">
                                    <table className="table align-middle table-design inai-table table-striped table-hover table-borderless shadow mb-3 bg-white rounded">
                                        <thead>
                                            <tr>
                                                <td>S.No</td>
                                                <td>Caption</td>
                                                <td>Description</td>
                                                <td>Action</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getSellerDocumentList()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="table-responsive row">
                                <label className="subtitle">Items </label>
                                <div className="p-2">
                                    <table className="table align-middle table-design inai-table table-striped table-hover table-borderless shadow mb-3 bg-white rounded">
                                        <thead>
                                            <tr className="text-center">
                                                <td>S.No</td>
                                                <td>Item Code </td>
                                                <td>Quantity </td>
                                                <td>UOM </td>
                                                <td>Description </td>
                                                <td>HSN </td>
                                                <td>GST </td>
                                                <td>Item Price</td>
                                                <td>Total Price</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getItemList()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="row ow-cols-1 row-cols-lg-1">
                                <label className="subtitle">Question and Answer <a href="javascript:;">[Ask Now]</a> </label>
                                <div class="chat-body">
                                    {getQuestionAnswers()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EquoteView;