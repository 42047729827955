const ToolBar = () => {

    return (
        <section className="social-links">
            <div className="container">
                <div className="links-left">
                    <ul>
                        <li><a href="mailto:inai@inaippl.com"><i
                                    className="bi bi-envelope"></i><span>inai@inaippl.com</span></a></li>
                        <li><a href="tel:+91 99765 42758"><i className="bi bi-telephone"></i><span>+91 99765
                                    42758</span></a></li>
                    </ul>
                </div>
                <div className="links-right">
                    <ul>
                        <li><a href="#"><i className="bi bi-facebook"></i></a></li>
                        <li><a href="#"><i className="bi bi-twitter"></i></a></li>
                        <li><a href="#"><i className="bi bi-instagram"></i></a></li>
                        <li><a href="#"><i className="bi bi-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>
        </section>
    )
    
}

export default ToolBar