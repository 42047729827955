import { useEffect } from "react"
import { PageToTop } from "../reusable/CommonUtils";

const Aboutus = () => {

    useEffect(() => {
        PageToTop();
    }, []);

    return (
        <>
            <section className="container">
                <div className="about-section">
                    <div className="section-title">
                        <h2><img src="/assets/images/title-divider.png" /> About US <img src="/assets/images/title-divider.png" />
                        </h2>
                    </div>
                    <br />
                    <div className="right-img">
                        <div className="img-section"><img src="/assets/images/about-us.png" className="img-fluid" alt="About Us" /></div>
                        <div className="content-section">
                            <h3>Who we are</h3>
                            <p>INAI Procure Private Limited is a procurement service Provider and merchant exporter, that serves customers around the world.</p>
                            <p>INAI Provide a standardized digital platform to support the management to enhance the monitoring systems and processes for procurement(e-bidding), supplier rationalization, strategic sourcing, enquires, quotation, purchase order. The platform is designed, developed and delivered as a highly secure SaaS.</p>
                            <p>As a Merchant Exporter INAI Procurement team is committed serving customer demands by a standardized procurement process to procure the right product in an economically optimized way. which results in saving a lot of time, money. In addition to streamlining pre-procurement processes, we offer post procurement services that extend to delivery, as per your requirements. A Systematic monitoring system is followed to ensure timely delivery of goods to the destination.</p>
                        </div>
                    </div>
                    <div className="left-img">
                        <div className="img-section"><img src="/assets/images/why-inai.png" className="img-fluid" alt="About Us" /></div>
                        <div className="content-section">
                            <h3>Why upgrading to online platform from email mode of communication</h3>
                            <p>So far, we have been communicating through email mode all these years. But some of the drawbacks of email communication are</p>
                            <ul className="list-unstyled pt-2">
                                <li>Ø Time consumption</li>
                                <li>Ø Communication gap when it comes to multiple suppliers handled by the procurement team.</li>
                                <li>Ø There is high chance that some suppliers may be missed while posting an answer for a query posted by a different supplier.</li>
                                <li>Ø Sometimes emails could be marked to a wrong person by mistake (at a click of a button), potentially resulting in confidential data leakage and sensitive business information. Need to be utmost diligent  to minimise the likelihood of business data breach and theft.</li>
                            </ul>
                            <p>But because of our strenuous efforts and increased customers and suppliers, we have upgraded to Online Portal.</p>
                            <p>Thus, INAI plays a major role in bringing suppliers and buyers together over the internet, and allows buyers to share information to all the suppliers seamlessly.Buyers can instantly respond to the queries posted by the suppliers over the online portal which can be easily referred by the other suppliers related to that specific product/item. They can even view the status of the procurement process at any point of time.</p>
                            <p>Suppliers on INAI can enjoy transparency. They can query a buyer, and get responses immediately. Questions asked by other suppliers (and the answers provided by the buyer), are also visible, enabling everyone to view the information to submit their best offer.</p>
                            <p>The security of our website architecture ensures that all bidding processes are conducted smoothly, fairly and securely. In short, INAI is a perfect medium of communication which enables the clients to focus on taking the right decisions pertaining to their bid. </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Aboutus