import React, { useEffect, useState } from "react";

const FileUploader = (props) => {

    const defaultParams = {
        name: 'file',
        onUpload: null,
        reference: null,
        fileName: null
    };

    const [params, setParams] = useState(defaultParams);
    const [fileName, setFileName] = useState(null);

    useEffect(() => {
        let combined = {...params, ...props};
        setParams(combined);
        setFileName(props.fileName??defaultParams.fileName);
    }, [props]);

    const onChangeTrigger = (e) => {
        if(params.onUpload){
            params.onUpload(e, params.reference);
        }
        
        try {
            setFileName(e.target.files[0].name);
        } catch (error) {
            //
        }
    };

    return <>
        <label id="test" className="form-control cursor-pointer" style={{padding: '2px', 'white-space': 'nowrap', 'overflow': 'hidden', 'text-overflow': 'ellipsis'}}>
            <input type="file"
                style={{display: 'none'}}
                className="form-control"
                name={params.name}
                onChange={(e) => onChangeTrigger(e)}/>
            <span class="btn btn-primary">Choose File</span>
            <span className="ms-2">{fileName??<i className="text-muted">No File Choosen</i>}</span>
        </label>
    </>
}

export default FileUploader;