import React, { useEffect, useState } from "react";
import { errorText, errorBorder } from "../../../reusable/Notification";
import axios from "axios";
import AsyncCreatableSelect from "react-select/async-creatable";
import { components } from "react-select";

import { AuthTokenVerify, UpdateBearer } from "../../../reusable/AuthUtils";
import { Link, useParams, useNavigate } from "react-router-dom";

import _ from "lodash";

const DepartmentEdit = ({ history, setAlertMessage }) => {
  const { id } = useParams();

  const Input = props => <components.Input {...props} maxLength={50} />;

  const navigate = useNavigate();
  const [filedError, setFieldError] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(false);

  const initDepartment = {
    department: "",
    dept_code: "",
    parent_dept:"",
    is_parent: 0,
    is_active: 1,
  };

  const [department, setDepartment] = useState(initDepartment);
  const [parentDepartment,setParentDepartment] = useState([])



  useEffect(() => {
    AuthTokenVerify(history);

    if (id) {
      axios
        .get(`dept/company_dept/${id}`)
        .then((response) => {
          let data = response.data;

   
          setDepartment({ ...data ,department:{ label: data.department_read, value: data.department }});
         
        })
        .catch((e) => {
          //
        });
    }
  }, []);

  useEffect(()=>{
    axios
    .get(`dept/company_parent_dept/`)
    .then((response) => {
      let data = response.data;

     setParentDepartment(data)
       
    })
    .catch((e) => {
      //
    });
  },[])

  const handleChange = (e,actionMeta) => {
    if (typeof (actionMeta) !== 'undefined') {
      setDepartment({
        ...department,
        pk:undefined,
          [actionMeta.name]: e
      });
  }else{
    let temp= {
      ...department,
      pk:undefined  ,
      [e.target.name]: e.target.value,
    }
    if(e.target.name == 'is_parent'){
      temp.parent_dept = ''
    }
    setDepartment({
      ...temp
    });
   
  };
  }
   

  const onSubmit = (e) => {
    e.preventDefault();
    setFieldError({});
    setDisableSubmit(true);
    setAlertMessage({});

    if (id) {
      axios
        .put(`dept/company_dept/${id}/`, department)
        .then((response) => {
          setAlertMessage({ type: "success", message: response.data.message });
          navigate("/department-management");
        })
        .catch((e) => {
          if (e.response.status === 400) {
            setAlertMessage({
              type: "danger",
              message: "Error Occured While Submit!",
            });
            let dict = {};
            for (const [key, value] of Object.entries(e.response.data)) {
              dict[key] = value.join(", ");
            }
            setFieldError(dict);
            UpdateBearer(history, e.response);
          }
          setDisableSubmit(false);
        });
    } else {
      
      axios
        .post("dept/company_dept/", department)
        .then((response) => {
          setAlertMessage({ type: "success", message: response.data.message });
          navigate("/department-management");
        })
        .catch((e) => {
          if (e.response.status === 400) {
            setAlertMessage({
              type: "danger",
              message: "Error Occured While Submit!",
            });
            let dict = {};
            for (const [key, value] of Object.entries(e.response.data)) {
              dict[key] = value.join(", ");
            }
            setFieldError(dict);
            UpdateBearer(history, e.response);
          }
          setDisableSubmit(false);
        });
    }
  };

  const departmentOptions = (inputValue) => {
  
    return new Promise((resolve) => {
      axios
        .get("dept/department_search/?q=" + inputValue  )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          //
        });
    });
  };

  const renderParentDepartment = () => {
    const content = [<option value="">-select-</option>];
    parentDepartment.map((item) => {
     return content.push(<option value={item.pk}>{item.department_read}</option>);
    });
    return content;
  };

  return (
    <div className="container">
      <fieldset>
        <form onSubmit={onSubmit}>
          <div className="form-panel">
            <div className="row row-cols-1 row-cols-lg-2 mb-3 form-elements">
              <div className="col-12 form-column">
                <div className="row mb-4 row-cols-1 row-cols-lg-3">

                  <div className="col">
                    <label className="form-label">
                      Department Name <sup className="text-danger">*</sup>
                    </label>
                    
                    <AsyncCreatableSelect
                      onChange={handleChange}
                      loadOptions={departmentOptions}
                      name="department"
                       
                      value={department.department}
                      isClearable={true}

                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                        Input,
                      }}
                      className={errorBorder(filedError.department)}
                    />
                    {errorText(filedError.department)}
                  </div>

                  <div className="col">
                    <label className="form-label">
                      Department Id <sup className="text-danger">*</sup>
                    </label>
                    <input
                      onChange={handleChange}
                      value={department.dept_code}
                      name="dept_code"
                      type="text"
                      className={
                        "form-control " + errorBorder(filedError.dept_code)
                      }
                    />
                    {errorText(filedError.dept_code)}
                  </div>

                  <div className="col">
                    <label className="form-label">Is Parent Department </label>
                    <div className="mb-3">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          onChange={handleChange}
                          disabled={department.pk}
                          checked={department.is_parent == 1}
                          name="is_parent"
                          type="radio"
                          id="Yes"
                          value={1}
                        />
                        <label className="form-check-label" for="Yes">
                          Yes
                        </label>
                      </div>

                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          onChange={handleChange}
                          disabled={department.pk}
                          checked={department.is_parent == 0}
                          name="is_parent"
                          type="radio"
                          id="No"
                          value={0}
                        />
                        <label className="form-check-label" for="No">
                          No
                        </label>
                      </div>
                      {errorText(filedError.is_parent)}
                    </div>
                  </div>

                </div>

                <div className="row mb-4 row-cols-1 row-cols-lg-3">
 
 


                <div className="col">
                  <label className="form-label">Select Parent Department</label>
                  <select
                    name="parent_dept"
                    disabled={(department.pk || (department.is_parent == 1))}
                    value={department.parent_dept}
                    onChange={handleChange}
                    className={
                      "form-select " + errorBorder(filedError.parent_dept)
                    }
                  >
                    {renderParentDepartment()}
                  </select>
                  {errorText(filedError.parent_dept)}
                </div>
                 
          

                  <div className="col">
                    <label className="form-label">Is Active </label>
                    <div className="mb-3">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          onChange={handleChange}
                          checked={department.is_active == 1}
                          name="is_active"
                          type="radio"
                          id="Yes"
                          value={1}
                        />
                        <label className="form-check-label" for="Yes">
                          Yes
                        </label>
                      </div>

                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          onChange={handleChange}
                          checked={department.is_active == 0}
                          name="is_active"
                          type="radio"
                          id="No"
                          value={0}
                        />
                        <label className="form-check-label" for="No">
                          No
                        </label>
                      </div>
                      {errorText(filedError.is_active)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-3 text-end">
            <Link
              className="button-style"
              type="button"
              to={"/department-management"}
            >
              Cancel
            </Link>
            &nbsp;&nbsp;
            {department.pk ? (
              <>
                <button
                  type="submit"
                  disabled={disableSubmit}
                  className="button-style"
                >
                  Update
                </button>
              </>
            ) : (
              <button
                type="submit"
                disabled={disableSubmit}
                className="button-style"
              >
                {id ? "Edit Department" : "Add Department"}
              </button>
            )}
          </div>
        </form>
      </fieldset>
    </div>
  );
};

export default DepartmentEdit;
