import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { AuthTokenVerify } from "../../reusable/AuthUtils";
import FileModal from './FileModel'
import {
  errorBorder,
  errorText,
} from "../../reusable/Notification";


 import { useParams, } from "react-router-dom";

const EquipmentView = ({ history }) => {
  const { id } = useParams();

  const initDocument = {
    file_name: "",
    document: "",
  };

  const initFieldError = {
    po_form_documents: {},
  };

  const initForm = {
    documents: [initDocument],
    name: "",
    type: "",
    make: "",
    serial_no: "",
    part_no: "",
    item_code: "",
    parent_eqp: "",
    category: "",
    equipment_drawing: 0,
  };

  const equipment_type = [
    { pk: 0, name: "Main Equipment" },
    { pk: 1, name: "Spare Parts" },
  ];

  const [formData, setFormData] = useState(initForm);
  const [parentEquipment, setParentEquipment] = useState([]);
  const [equipmentCategory, setEquipmentCategory] = useState([]);
 

  const [equipmentSubDepartment, setEquipmentSubDepartment] = useState([]);

  const [filedError, setFieldError] = useState(initFieldError);
 
 
 
  const [showModal, setShowModal] = useState(false);
  const [fileData, setFileData] = useState(null);  


  const fileInput = useRef([]);
 
   
 
   
  useEffect(() => {
    axios
      .get("ems/equipment/")
      .then((response) => {
    
        setParentEquipment(response.data.results);
        // UpdateBearer(history, response);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    AuthTokenVerify(history);
    axios
    .get("dept/company_emp_dept/")
    .then((response) => {
      setEquipmentCategory(response.data.departments);
      setEquipmentSubDepartment(response.data.department_map);
      // setMapDepartment(response.data.department_map);
    })
    .catch(() => {});

    if (id) {
      axios
        .get(`ems/equipment/${id}`)
        .then((response) => {
          let data = response.data;
 
          setEquipmentCategory([{pk:data.department,"department_read":data.department_read}])
          setFormData({ ...data });
       
        })
        .catch(() => {
          //
        });
    }
  }, []);

  
  
  
 
  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  
  const renderEquipmentType = () => {
    const content = [<option value="">-select-</option>];
    equipment_type.map((item) => {
      content.push(<option value={item.pk}>{item.name}</option>);
    });
    return content;
  };

  const renderParentEquipment = () => {
    const content = [<option value="">-select-</option>];
    parentEquipment.map((item) => {
      content.push(<option value={item.pk}>{item.name}</option>);
    });
    return content;
  };

  const renderEquipmentCategory = () => {
    const content = [<option value="">-select-</option>];
    equipmentCategory.map((item) => {
      content.push(<option value={item.pk}>{item.department_read}</option>);
    });
    return content;
  };

  const handleChange = (e) => {
    let targetData = e.target.value;
    let targetName = e.target.name;
    if (targetName == "type" && targetData != "1") {
      formData["parent_eqp"] = "";
    }
    let updateData = { ...formData, [targetName]: targetData };
    setFormData(updateData);
    
  };

  const renderEquipmentSubDepartment = () => {
    const content = [<option value="">-select-</option>];
    equipmentSubDepartment.map((item) => {
     
      return  content.push(<option value={item.sub_dept}>{item.sub_dept_read}</option>);
    
    });
    return content;
  };
  
  const openFile = (item) => {
   
    axios.get(`/ems/eqp_file/${item.pk}`, {
      responseType: 'blob', // Specify the response type as blob
    })
      .then((response) => {
        const reader = new FileReader();
        reader.readAsDataURL(response.data); // Read the blob data as base64
        reader.onloadend = () => {
          setFileData({
            type: response.headers['content-type'],
            data: reader.result.split(',')[1],  
            file_name:item.file_name??''
          });
          handleShowModal();
        };
      })
      .catch((error) => {
        console.error('Error fetching file:', error);
      });
  };

  const renderDocuments = () => {
    const content = [  <FileModal show={showModal} onHide={handleCloseModal} fileData={fileData} />];
    formData.documents.map( (item, index) => {
        let fetched_doc = item.pk;
        content.push(
            <div className="row mb-3 row-cols-1 row-cols-lg-3 single-col">
                {(() => {
                    if(fetched_doc){
                        return <>
                            <div className="col">
                                <input type="text" className="form-control" readOnly  name="file_name" value={item.file_name} />
                            </div>
                            <div className="col">
                                <input type="text" className="form-control" readOnly  name="file_name" value={item.document}   onClick={()=>openFile(item)}  />
                            </div>
                        </>;
                    }

                    return <>
                        <div className="col">
                            <input type="text" className="form-control"  name="file_name" value={item.file_name}/>
                        </div>
                        
                        <div className="col">
                            <input type="file" className="form-control" ref={(element) => {fileInput.current[index] = element}}  name="document"/>
                        </div>
                    </>;
                })()}

                <div className="col-1 plus-icon">
                   
                </div>
            </div>
        );
    });
   
    return content;
};
  return (
    <>
      <div className="container">
        <div className="form-wrapper">
          <div className="form-panel-wrapper">
            
            <form  >

            <div className="row mb-4 row-cols-1 row-cols-lg-3">
                <div className="col">
                  <label className="form-label">
                    Equipment Name<sup className="text-danger">*</sup>
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className={"form-control " + errorBorder(filedError.name)}
                  />
                  {errorText(filedError.name)}
                </div>

                <div className="col">
                  <label className="form-label">
                    Equipment Type<sup className="text-danger">*</sup>
                  </label>
                  <select
                    name="type"
                    value={formData.type}
                    onChange={handleChange}
                    className={"form-select " + errorBorder(filedError.type)}
                  >
                    {renderEquipmentType()}
                  </select>
                  {errorText(filedError.type)}
                </div>

                <div className="col">
                  <label className="form-label">Parent Equipment</label>
                  <select
                    name="parent_eqp"
                    disabled={true}
                    value={formData.parent_eqp}
                    onChange={handleChange}
                    className={
                      "form-select " + errorBorder(filedError.parent_eqp)
                    }
                  >
                    {renderParentEquipment()}
                  </select>
                  {errorText(filedError.parent_eqp)}
                </div>
              </div>

              <div className="row mb-4 row-cols-1 row-cols-lg-3">
                <div className="col">
                  <label className="form-label">
                    Equipment Department<sup className="text-danger">*</sup>
                  </label>
                  <select
                    name="department"
                    value={formData.department}
                    disabled={true}
                    onChange={handleChange}
                    className={
                      "form-select " + errorBorder(filedError.department)
                    }
                  >
                    {renderEquipmentCategory()}
                  </select>
                  {errorText(filedError.department)}
                </div>

                <div className="col">
                  <label className="form-label">
                    Equipment Sub Department<sup className="text-danger">*</sup>
                  </label>
                  <select
                    name="sub_department"
                    value={formData.sub_department}
                    disabled={true}
                    onChange={handleChange}
                    className={
                      "form-select " + errorBorder(filedError.sub_department)
                    }
                  >
                    {renderEquipmentSubDepartment()}
                  </select>
                  {errorText(filedError.sub_department)}
                </div>

                <div className="col">
                  <label className="form-label">
                    Equipment Make<sup className="text-danger">*</sup>
                  </label>
                  <input
                    name="make"
                    type="text"
                    value={formData.make}
                    onChange={handleChange}
                    className={"form-control " + errorBorder(filedError.make)}
                  />
                  {errorText(filedError.make)}
                </div>

                
              </div>

              
              <div className="row mb-4 row-cols-1 row-cols-lg-3">
              <div className="col">
                  <label className="form-label">
                    Part Number<sup className="text-danger">*</sup>
                  </label>
                  <input
                    type="text"
                    name="part_no"
                    value={formData.part_no}
                    onChange={handleChange}
                    className={
                      "form-control " + errorBorder(filedError.part_no)
                    }
                  />
                  {errorText(filedError.part_no)}
                </div>
                <div className="col">
                  <label className="form-label">
                    Serial Number<sup className="text-danger">*</sup>
                  </label>
                  <input
                    type="text"
                    name="serial_no"
                    value={formData.serial_no}
                    onChange={handleChange}
                    className={
                      "form-control " + errorBorder(filedError.serial_no)
                    }
                  />
                  {errorText(filedError.serial_no)}
                </div>

                <div className="col">
                  <label className="form-label">
                   Item code <sup className="text-danger">*</sup>
                  </label>
                  <input
                    name="item_code"
                    type="text"
                    value={formData.item_code}
                    onChange={handleChange}
                    className={
                      "form-control " + errorBorder(filedError.item_code)
                    }
                  />
                  {errorText(filedError.item_code)}
                </div>
                
              </div>


              <div className="form-panel">
                <div className="row row-cols-1 row-cols-lg-2 mb-3 form-elements">
                  <div className="col-12 form-column form-address-panel">
                    <div className="row mb-2 row-cols-1 row-cols-lg-3 single-col">
                      <div className="col">
                        <label className="form-label">Equipment Name Plate / Equipment Drawing Files</label>
                      </div>
                      <div className="col">
                        <label className="form-label">
                          Documents  (.pdf, .jpg, .jpeg, .png, .gif, .doc, .docx, .xls, .xlsx)
                        </label>
                      </div>
                      
                    </div>
                    {renderDocuments( )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default EquipmentView;
