import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Notification, { errorText, errorBorder, successText } from '../../reusable/Notification';
import { renderHTML, PageToTop } from '../../reusable/CommonUtils';

const Register = ({ email, verifyCode }) => {

    // Form Data
    const initialFormData = {
        email: email,
        email_verify_token: verifyCode,
        first_name: '',
        last_name: '',
        password: '',
        password2: '',
        mobile: '',
        otp: '',
        sent_otp: 0,
        otp_verified: 0,
    };
    const [formData, setFormData] = useState(initialFormData);
    const [requiredFields, setRequiredFields] = useState({});
    const [successFields, setSuccessFields] = useState({});
    const [alertMessage, setAlertMessage] = useState({});
    const [message, setMessage] = useState('');
    const [disableSendOTP, setDisableSendOTP] = useState(false);

    const handleChange = (e) => {

        let targetData = '';

        switch (e.target.name) {
            default:
                targetData = e.target.value.trim();
                break;
        }

        setFormData({
            ...formData,

            // Trimming any whitespace
            [e.target.name]: targetData
        });
    };

    const changeNumber = () => {
        setRequiredFields({ ...requiredFields, mobile: '', otp: '' });
        setFormData({
            ...formData,
            mobile: '',
            otp: '',
            sent_otp: 0,
            otp_verified: 0
        });
        setSuccessFields({ ...successFields, mobile: '', otp: '' });
        setDisableSendOTP(false);
    };

    const sendOtp = () => {
        setRequiredFields({ ...requiredFields, mobile: '', otp: '' });
        setFormData({
            ...formData,
            sent_otp: 0,
            otp_verified: 0
        });
        setSuccessFields({ ...successFields, mobile: '', otp: '' });

        axios.post("user/reg-send-otp/", { mobile: formData.mobile })
            .then((response) => {
                setSuccessFields({ ...successFields, mobile: response.data.message, otp: '' });
                setFormData({
                    ...formData,
                    sent_otp: 1,
                    otp_verified: 0
                });
                setDisableSendOTP(true);
                setTimeout(() => {
                    setDisableSendOTP(false);
                }, 30000);
            })
            .catch((e) => {
                if (e.response.status === 400) {
                    setRequiredFields({ ...requiredFields, mobile: e.response.data.mobile[0] });
                }
            });
    };

    const verifyOtp = () => {
        setRequiredFields({ ...requiredFields, mobile: '', otp: '' });
        setFormData({
            ...formData,
            otp_verified: 0
        });
        setSuccessFields({ ...successFields, mobile: '', otp: '' });

        axios.post("user/reg-verify-otp/", { mobile: formData.mobile, otp: formData.otp })
            .then((response) => {
                setSuccessFields({ ...successFields, otp: response.data.message, mobile: '' });
                setFormData({
                    ...formData,
                    otp_verified: 1
                });
            })
            .catch((e) => {
                if (e.response.status === 400) {
                    setRequiredFields({ ...requiredFields, ...e.response.data });
                }
            });
    }

    const onSubmit = (e) => {
        e.preventDefault();
        setAlertMessage({});
        setRequiredFields({});

        axios.post("user/company-registration/", formData)
            .then((response) => {
                setMessage(<>{renderHTML(response.data.message)}</>);
            })
            .catch((e) => {
                if (e.response.status === 400) {
                    let dict = {};
                    for (const [key, value] of Object.entries(e.response.data)) {
                        dict[key] = value.join(', ');
                    }
                    setRequiredFields(dict);
                    setAlertMessage({ type: 'danger', message: "Error Occured!" });
                } else if (e.response.status === 404) {
                    setAlertMessage({ type: 'danger', message: e.response.data.message });
                } else {
                    setAlertMessage({ type: 'danger', message: e.response.data.message });
                }

                PageToTop();

            });
    };

    const renderElement = () => {
        if (message) {
            return <section className="container">
                <div className="about-section mt-5 mb-5">
                    <div className="secondary-title">
                        <h2>{message}</h2>
                    </div>
                </div>
            </section>;
        } else {
            return <div className="container">
                <section className="login-wrapper">
                    <Notification {...alertMessage} />
                    <form onSubmit={onSubmit}>
                        <div className="login-panel">
                            <div className="row">
                                <div className="col">
                                    <h2>Sign Up</h2>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col">
                                    <input type="text" name="first_name" onChange={handleChange} className="form-control" placeholder="First Name *" />
                                </div>
                                {errorText(requiredFields.first_name)}
                            </div>
                            <div className="row mb-3">
                                <div className="col">
                                    <input type="text" name="last_name" onChange={handleChange} className="form-control" placeholder="Last Name *" />
                                </div>
                                {errorText(requiredFields.last_name)}
                            </div>
                            <div className="row mb-3">
                                <div className="col">
                                    <input type="text" name="username" onChange={handleChange} className="form-control" placeholder="User ID *" />
                                </div>
                                {errorText(requiredFields.username)}
                            </div>
                            <div className="row mb-3">
                                <div className="col">
                                    <input type="email" readOnly value={email} className="form-control" placeholder="Email *" />
                                </div>
                                {requiredFields.email}
                                {errorText(requiredFields.email)}
                            </div>
                            <div className="row mb-3">
                                <div className="col">
                                    <input type="password" name="password" onChange={handleChange} className="form-control" placeholder="Password *" />
                                </div>
                                {errorText(requiredFields.password)}
                            </div>
                            <div className="row mb-3">
                                <div className="col">
                                    <input type="password" name="password2" onChange={handleChange} className="form-control" placeholder="Confirm Password *" />
                                </div>
                                {errorText(requiredFields.password2)}
                            </div>
                            <div className="row mb-3">
                                {(() => {
                                    if (formData.sent_otp) {
                                        return (
                                            <a onClick={changeNumber} className="text-primary cursor-pointer mb-2">[Change Number]</a>
                                        );
                                    }
                                })()}
                                <div className="col">
                                    <input type="text" disabled={formData.sent_otp} value={formData.mobile} name="mobile" onChange={handleChange} className="form-control" placeholder="Mobile *" />
                                </div>
                                {errorText(requiredFields.mobile)}
                                {successText(successFields.mobile)}
                                {successText(successFields.otp)}
                            </div>
                            {(() => {
                                if (formData.otp_verified == 0) {
                                    return (
                                        <div className="mb-3 text-center">
                                            <div className="col">
                                                <button type="button" disabled={disableSendOTP} onClick={sendOtp} className="btn btn-primary">
                                                    {formData.sent_otp ? 'Resend OTP' : 'Send OTP'}
                                                </button>
                                            </div>
                                            <div className="col mt-2">
                                                {(() => {
                                                    if (disableSendOTP) {
                                                        return (<>* You can Resend OTP after 30 seconds</>);
                                                    }
                                                })()}
                                            </div>
                                        </div>
                                    );
                                };
                            })()}
                            {(() => {
                                if (formData.sent_otp && formData.otp_verified == 0) {
                                    return (
                                        <>
                                            <div className="row mb-3">
                                                <div className="col">
                                                    <input type="text" name="otp" onChange={handleChange} className="form-control" placeholder="OTP *" />
                                                </div>
                                                {errorText(requiredFields.otp)}
                                            </div>
                                            <div className="row mb-3 text-center">
                                                <div className="col">
                                                    <button type="button" onClick={verifyOtp} className="btn btn-primary">Verify OTP</button>
                                                </div>
                                            </div>
                                        </>
                                    );
                                }
                            })()}

                            <div className="col-12 text-center">
                                <button type="submit" disabled={!formData.otp_verified} className="btn btn-primary">Register</button>
                            </div>
                            <div className="col-12 mt-3">
                                <b>Password</b>
                                <ul className="pass-strength">
                                    <li>8-20 Characters</li>
                                    <li>At least one capital letter</li>
                                    <li>At least one number</li>
                                    <li>At least one Special Character</li>
                                </ul>
                            </div>
                        </div>
                    </form>
                </section>
            </div>;
        }
    }

    return (
        <>{renderElement()}</>
    )
}

export default Register