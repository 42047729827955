import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";
import React, { useState } from 'react';
import Config from '../reusable/Config';
import axios from 'axios';
import { errorText } from '../reusable/Notification';

const Footer = () => {

    const recaptchaRef = React.createRef();

    const initForm = {
        name: '',
        email: '',
        mobile: '',
        subject: '',
        message: '',
        recaptcha: '',
    };

    const [ form, setForm ] = useState(initForm);

    const [ filedError, setFieldError ] = useState({});
    const [ message, setMessage ] = useState('');

    const handleRecaptcha = (value) => {
        setForm({...form, recaptcha: value});
        setFieldError({});
    };

    const handleChange = (e) => {
        var targetData = '';

        switch (e.target.name) {
            default:
                targetData = e.target.value;
                break;
        }

        setForm({...form, [e.target.name] : targetData});
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setMessage('');
        setFieldError({});

        axios.post("common/contactus/", form).then((response) => {
            setMessage(response.data.message);
            setForm(initForm);
        }).catch((e) => {
            if(e.response.status === 400) {
                setFieldError(e.response.data);
            }
        });

        recaptchaRef.current.reset();
    };

    return (
        <>
        <section className="footer-wrapper">
            <div className="container">
                <div className="section-title">
                    <h2><img src="/assets/images/title-divider.png" /> Anytime, from anywhere <img
                            src="/assets/images/title-divider.png" /></h2>
                </div>
                <div className="row row-cols-1 row-cols-lg-3">
                    <div className="col footer-about-comp">
                        <h2>INAI Procure Private Limited</h2>
                        <p>Stay connected with a Right Supplier and Buyer!!</p>
                        <div className="footer-social-icons">
                            <ul>
                                <li><a href="#"><i className="bi bi-facebook"></i></a></li>
                                <li><a href="#"><i className="bi bi-twitter"></i></a></li>
                                <li><a href="#"><i className="bi bi-instagram"></i></a></li>
                                <li><a href="#"><i className="bi bi-linkedin"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col footer-address">
                        <div className="address-col">
                            <i className="bi bi-geo-alt"></i>
                            <p>Flat No.G-2, Plot No.19,<br />
                            Mullai Street, Sakthi Nagar<br />
                            Adambakkam, Chennai - 600088,<br />
                            Tamil Nadu.</p>
                        </div>
                        <div className="address-col">
                            <i className="bi bi-envelope"></i>
                            <p><a href="mailto:inai@inaippl.com">inai@inaippl.com</a></p>
                        </div>
                        <div className="address-col">
                            <i className="bi bi-telephone"></i>
                            <p><a href="tel:+91 99765 42758">99765 42758</a></p>
                        </div>
                    </div>
                    <div className="col footer-form-wrapper">
                        {(() => {
                            if(message){
                                return (
                                    <h3 className="text-center fw-bold text-success">{message}</h3>
                                );
                            }
                            return null;
                        })()}
                        <form onSubmit={ onSubmit }>
                            <div className="row mb-2">
                                <div className="col">
                                    <input className="form-control" name='name' onChange={handleChange} value={form.name} type="text" placeholder="Your Name"
                                        aria-label="Your Name" />
                                    { errorText(filedError.name) }
                                </div>
                                <div className="col">
                                    <input className="form-control" name='email' onChange={handleChange} value={form.email} type="email" placeholder="Your Email"
                                        aria-label="Your Email" />
                                    { errorText(filedError.email) }
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col">
                                    <input className="form-control" name='mobile' onChange={handleChange} value={form.mobile} type="text" placeholder="Your Mobile"
                                        aria-label="Your Mobile" />
                                    { errorText(filedError.mobile) }
                                </div>
                                <div className="col">
                                    <input className="form-control" name='subject' onChange={handleChange} value={form.subject} type="text" placeholder="Subject" aria-label="Subject" />
                                    { errorText(filedError.subject) }
                                </div>
                            </div>
                            <div className="col-12 mb-2">
                                <div className="col">
                                    <textarea className="form-control" name='message' onChange={handleChange} value={form.message} rows="3" placeholder='Message'></textarea>
                                    { errorText(filedError.message) }
                                </div>
                            </div>
                            
                            <div className="row mb-2">
                                <div className="col-6">
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={ Config.captchaSiteKey }
                                    onChange={handleRecaptcha}
                                />
                                { errorText(filedError.recaptcha) }
                                </div>
                            </div>
                            <div className="row mb-2 mt-1">
                                 <div className="col-6">
                                    <div>
                                        <button type="submit" className="button-style">send message</button>
                                    </div>
                                </div>
                            </div>
                           
                        </form>
                    </div>  
                </div>
            </div>
        </section>

        <footer className="footer-links">
            &copy; 2021 INAI, All Rights Reserved.
        </footer>

        <Helmet>
            <script type="text/javascript" src="/assets/js/bootstrap.bundle.min.js"></script>
        </Helmet>
        </>
    )

}

export default Footer