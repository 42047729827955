import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { Link  } from "react-router-dom";
import { AuthTokenVerify, UpdateBearer } from "../../../reusable/AuthUtils";
import { PageToTop } from "../../../reusable/CommonUtils";
import { confirm } from '../../../reusable/Confirmation';

import {
    noPermissionTemplate,
  isThisAdmin,
} from "../../../reusable/PermissoinUtils";

const DepartmentList = ({ history ,setAlertMessage}) => {
  const [dataList, setDataList] = useState([]);
  const [refreshList, setrefreshList] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [noPermission, setNoPermission] = useState(false);
  const [page, setPage] = useState(1);
  const recordsPerPage = 15;
 

  useEffect(() => {

    AuthTokenVerify(history);
    if (!refreshList) return;
    axios
      .get("dept/company_dept/?page=" + page)
      .then((response) => {
        setDataList(response.data.results);
        setPageCount(Math.ceil(response.data.count / recordsPerPage));
        UpdateBearer(history, response);
      })
      .catch((e) => {
        if (e.response.status === 404) {
          setPage(1);
          setrefreshList(true);
        } else if (e.response.status === 403) {
          //permission denied
          setNoPermission(true);
        }
      });
    setrefreshList(false);
  }, [refreshList]);

  const handlePageClick = (data) => {
    setPage(data.selected + 1);
    setrefreshList(true);
  };

  const deleteItem = async(key)=>{
    const result = await  confirm('Are you sure to delete this?');
    if (!result) return;

    setAlertMessage({});

    const pk = dataList[key].pk;
    axios.delete("dept/company_dept/" + pk + "/")
        .then((response) => {
            setAlertMessage({ type: 'success', message: "Department Deleted Successfully!" });
            setrefreshList(true);
        })
        .catch((e) => {
            if (e.response.status === 400) {
                setAlertMessage({ type: 'danger', message: "Error Occurred While Delete!" });
            }
        });
    PageToTop();
  }

  const showPagination = () => {
    if (pageCount <= 1) return;
    return (
      <nav>
        <ReactPaginate
          pageClassName={"page-item"}
          previousClassName={"page-item active"}
          nextClassName={"page-item active"}
          pageLinkClassName={"page-link"}
          previousLinkClassName={"page-link"}
          nextLinkClassName={"page-link"}
          previousLabel={"previous"}
          nextLabel={"next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          forcePage={page - 1}
          pageRangeDisplayed={recordsPerPage}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </nav>
    );
  };

  const getDataList = () => {
    PageToTop();
    const content = [];
    dataList.map((item, key) => {
      content.push(
        <tr key={key}>
          <td>{key + 1 + recordsPerPage * (page - 1)}</td>
          <td>{item.department_read}</td>
          <td>{item.dept_code}</td>
           

          <td>{item.is_active ? "Active" : "Inactive"}</td>
          <td className="text-center">
            {(() => {
              if (isThisAdmin()) {
                return (
                  <Link
                    to={`/department-management-add/${item.pk}`}
                    className="btn btn-primary"
                  >
                    <i className="bi bi-pencil-fill"></i>
                  </Link>
                  
                );
              }

              return (
                <button
                  title="No permission"
                  type="button"
                  className="btn btn-primary action-disabled"
                >
                  <i className="bi bi-pencil-fill"></i>
                </button>
              );
            })()}
            {(() => {
                        if(isThisAdmin( )){
                            return <button onClick={() => deleteItem(key) } type="button" className="btn btn-danger ms-1">
                                <i className="bi bi-trash-fill"></i>
                            </button>
                        }
                        
                        return <button  title="No permission"  type="button" className="btn btn-danger ms-1 action-disabled">
                            <i className="bi bi-trash-fill"></i>
                        </button>
                    })()}
          </td>
        </tr>
      );
    });

    if (content.length < 1) {
      content.push(
        <tr>
          <td colSpan="9" className="text-center">
            No records found
          </td>
        </tr>
      );
    }

    return content;
  };
  if(noPermission){
    return noPermissionTemplate()
}
  return (
    <>
      <div className="mb-3 text-end">
        {(() => {
          if (isThisAdmin()) {
            return (
              <Link className="button-style" to={"/department-management-add"}>
                Add Department
              </Link>
            );
          }
          return (
            <a title="No permission" className="button-style action-disabled">
              Add Department
            </a>
          );
        })()}
      </div>
      <div className="table-responsive row">
        <table className="table align-middle table-bordered table-design">
          <thead>
            <tr>
              <td >Serial No.</td>
              <td>Department Name</td>
              <td>Department Id</td>
              <td>Status</td>
              <td  className="product-action">
                Action
              </td>
            </tr>
          </thead>
          <tbody>{getDataList()}</tbody>
        </table>
      </div>
      {showPagination()}
    </>
  );
};

export default DepartmentList;
