import React, { useEffect, useState } from "react";
import NavigationMenu from "./NavigationMenu";
import Notification from "../../reusable/Notification";
import { EmployeesList, EmployeeEdit } from "./Employees/index";
import { AuthTokenVerify } from "../../reusable/AuthUtils";
import { PageToTop } from "../../reusable/CommonUtils";

const Employees = ({history, rComp}) => {

    const [alertMessage, setAlertMessage] = useState({});

    useEffect(() => {
        AuthTokenVerify(history);
        PageToTop();
    }, []);

    const render = () => {
        let returnComp = null;
        switch (rComp) {
            case "add":
                returnComp = <EmployeeEdit history={history} setAlertMessage={setAlertMessage}/>;
                break;
            default:
                returnComp = <EmployeesList history={history} setAlertMessage={setAlertMessage}/>;
                break;
        }
        return returnComp;
    };

    return (
        <div className="container">
            <div className="form-wrapper">
                <NavigationMenu item={ 7 } />
                <div className="form-panel-wrapper">
                    <Notification {...alertMessage}/>
                    { render() }
                </div>
            </div>
        </div>
    )
};

export default Employees;