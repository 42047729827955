import React, {useState, useEffect} from 'react'
import validator from 'validator';
import axios from 'axios'
import Notification, { errorText, errorBorder } from '../reusable/Notification';
import { AuthTokenVerify, UpdateBearer } from "../reusable/AuthUtils";
import { PageToTop } from '../reusable/CommonUtils';
import { noPermissionTemplate, isPermitted } from "../reusable/PermissoinUtils";

const Invite = ({history}) => {

    const initForm = {
        to_email: '',
        to_company: '',
        message: '',
    }

    const [ alertMessage, setAlertMessage ] = useState({});
    const [ disableSubmit, setDisableSubmit ] = useState(false);
    const [ filedError, setFieldError ] = useState({});
    const [ formData, setFormData ] = useState(initForm);

    useEffect(() => {
        AuthTokenVerify(history);
        PageToTop();
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        setAlertMessage({});
        setFieldError({});
        
        axios.post("connect/invite/", formData)
        .then((response) => {
            UpdateBearer(history, response);
            setAlertMessage({type:'success', message:response.data.message});
            setFormData(initForm);
        })
        .catch((e) => {
            if(e.response.status === 400) {
                setAlertMessage({type:'danger', message:"Error Occured While Submit!"});
                let dict = {};
                for (const [key, value] of Object.entries(e.response.data)) {
                    dict[key] = value.join(', ');
                }
                setFieldError(dict);
                window.scrollTo(0,0);
            }else{
                //hadle other errors
            }

            UpdateBearer(history, e.response);
        });
    }

    const handleChange = (e) => {

        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    if(!isPermitted('site_invite_company_invite_company')){
        return noPermissionTemplate();
    }

    return (
        <div className="container">
            <section className="login-wrapper">
                <form onSubmit={ onSubmit }>
                <Notification {...alertMessage}/>
                    <div className="login-panel">
                        <div className="row">
                            <div className="col">
                                <h2>Invite Company</h2>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col">
                                <input type="text" name="to_company" value={formData.to_company} onChange={ handleChange } className={"form-control " + errorBorder(filedError.to_company) } placeholder="Company *" />
                                { errorText(filedError.to_company) }
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col">
                                <input type="text" name="to_email" value={formData.to_email} onChange={ handleChange } className={"form-control " + errorBorder(filedError.to_email) } placeholder="Email *" />
                                { errorText(filedError.to_email) }
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col">
                                <textarea name="message" className={"form-control " + errorBorder(filedError.message) } onChange={ handleChange } maxLength="200" rows="3" placeholder="Message" value={formData.message}></textarea>
                                { errorText(filedError.message) }
                            </div>
                        </div>
                        <div className="col-12 text-center">
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </div>
                    </div>
                </form>
            </section>
        </div>
    )
}

export default Invite