import React, { useEffect, useState } from "react";
import axios from "axios";
import Notification, { errorText, errorBorder } from "../../reusable/Notification";
import NavigationMenu from "./NavigationMenu";
import AsyncCreatableSelect from 'react-select/async-creatable';
import { components } from "react-select";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Modal, Button } from "react-bootstrap";
import { confirm } from '../../reusable/Confirmation';
import ReactPaginate from 'react-paginate';
import { AuthTokenVerify, UpdateBearer } from "../../reusable/AuthUtils";
import { PageToTop } from "../../reusable/CommonUtils";
import { noPermissionTemplate, isPermitted } from "../../reusable/PermissoinUtils";

const Products = ({ history }) => {

    const Input = props => <components.Input {...props} maxLength={50} />;

    const initForm = {
        product: null,
        product_code: '',
        brand: [],
        keyword: [],
        business_type: '',
        description: '',
        industry: [],
        credential: [],
        certificate: []
    };

    const [formData, setFormData] = useState(initForm);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [addProduct, setAddProduct] = useState(false);
    const [alertMessage, setAlertMessage] = useState({});
    const [filedError, setFieldError] = useState({});
    const [companyProducts, setCompanyProducts] = useState([]);
    const [viewCompanyProduct, setViewCompanyProduct] = useState({});
    const [refreshList, setrefreshList] = useState(true);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [noPermission, setNoPermission] = useState(false);

    const recordsPerPage = 15;

    // for bootsrap model
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        AuthTokenVerify(history);
        PageToTop();
    }, []);

    useEffect(() => {
        if (!refreshList) return;
        axios.get("products/company-products/?page=" + page).then((response) => {
            setCompanyProducts(response.data.results);
            setPageCount(Math.ceil(response.data.count / recordsPerPage));
            UpdateBearer(history, response);
        }).catch((e) => {
            if (e.response.status === 404) {
                setPage(1);
                setrefreshList(true);
            }
            else if (e.response.status === 403) {
                //permission denied
                setNoPermission(true);
            }
        });
        setrefreshList(false);
    }, [refreshList]);

    useEffect(() => {
        setDisableSubmit(false);
    }, [addProduct]);

    const handleChange = (newValue, actionMeta) => {
        if (typeof (actionMeta) !== 'undefined') {
            setFormData({
                ...formData,
                [actionMeta.name]: newValue
            });
        } else {
            setFormData({
                ...formData,
                [newValue.target.name]: newValue.target.value
            });
        }
    };

    const productOptions = (inputValue) => {
        return new Promise(resolve => {
            axios.get("products/product-search/?q=" + inputValue).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                //
             });
        });
    };

    const brandsOptions = (inputValue) => {
        let data = formData.brand.map(brands => { if (!brands.__isNew__) return brands.value }).filter(e => e).join(',');
        return new Promise(resolve => {
            axios.get("products/brands-search/?q=" + inputValue + "&skip=" + data).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                //
             });
        });
    };

    const keywordsOptions = (inputValue) => {
        let data = formData.keyword.map(keywords => { if (!keywords.__isNew__) return keywords.value }).filter(e => e).join(',');
        return new Promise(resolve => {
            axios.get("products/keywords-search/?q=" + inputValue + "&skip=" + data).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                //
             });
        });
    };

    const industriesOptions = (inputValue) => {
        let data = formData.industry.map(industries => { if (!industries.__isNew__) return industries.value }).filter(e => e).join(',');
        return new Promise(resolve => {
            axios.get("products/industries-search/?q=" + inputValue + "&skip=" + data).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                //
             });
        });
    };

    const credentialsOptions = (inputValue) => {
        let data = formData.credential.map(credentials => { if (!credentials.__isNew__) return credentials.value }).filter(e => e).join(',');
        return new Promise(resolve => {
            axios.get("products/credentials-search/?q=" + inputValue + "&skip=" + data).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                //
             });
        });
    };

    const certificatesOptions = (inputValue) => {
        let data = formData.certificate.map(certificates => { if (!certificates.__isNew__) return certificates.value }).filter(e => e).join(',');
        return new Promise(resolve => {
            axios.get("products/certificates-search/?q=" + inputValue + "&skip=" + data).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                //
             });
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setAlertMessage({});
        setFieldError({});
        setDisableSubmit(true);

        if (formData.pk) {
            axios.put("products/company-products/" + formData.pk + "/", formData)
                .then((response) => {
                    setAlertMessage({ type: 'success', message: response.data.message });
                    setFormData(initForm);
                    setDisableSubmit(false);
                    setAddProduct(false);
                    setrefreshList(true);
                })
                .catch((e) => {
                    if (e.response.status === 400) {
                        setAlertMessage({ type: 'danger', message: "Error Occured While Submit!" });
                        let dict = {};
                        for (const [key, value] of Object.entries(e.response.data)) {
                            dict[key] = value.join(', ');
                        }
                        setFieldError(dict);
                    }
                    setDisableSubmit(false);
                });
        } else {
            axios.post("products/company-products/", formData)
                .then((response) => {
                    setAlertMessage({ type: 'success', message: response.data.message });
                    setFormData(initForm);
                    setDisableSubmit(false);
                    setAddProduct(false);
                    setrefreshList(true);
                })
                .catch((e) => {
                    if (e.response.status === 400) {
                        setAlertMessage({ type: 'danger', message: "Error Occured While Submit!" });
                        let dict = {};
                        for (const [key, value] of Object.entries(e.response.data)) {
                            dict[key] = value.join(', ');
                        }
                        setFieldError(dict);
                    }
                    setDisableSubmit(false);
                });
        }
        PageToTop();
    };

    const viewContent = (key) => {
        var item = companyProducts[key];
        item.brands_read =  item.brands.map(brands => { return brands.label }).join(', ');
        item.keywords_read = item.keywords.map(keywords => { return keywords.label }).join(', ');
        item.certificates_read = item.certificates.map(certificates => { return certificates.label }).join(', ');
        item.credentials_read = item.credentials.map(credentials => { return credentials.label }).join(', ');
        item.industries_read = item.industries.map(industries => { return industries.label }).join(', ');
        setViewCompanyProduct(item);
        handleShow();
    };

    const getProducts = () => {
        const content = [];
        companyProducts.map((item, key) => {
            var brands = item.brands.map(brands => { return brands.label }).join(', ');
            var keywords = item.keywords.map(keywords => { return keywords.label }).join(', ');
            var certificates = item.certificates.map(certificates => { return certificates.label }).join(', ');
            var credentials = item.credentials.map(credentials => { return credentials.label }).join(', ');
            var industries = item.industries.map(industries => { return industries.label }).join(', ');

            content.push(<tr key={key} >
                <td>{(key + 1) + (recordsPerPage * (page - 1))}</td>
                <td>{item.product_read}</td>
                <td>{brands}</td>
                <td data-tip={item.description}>{item.description}</td>
                <td data-tip={keywords}>{keywords}</td>
                <td>{item.business_type_read}</td>
                <td>{industries}</td>
                <td>{credentials}</td>
                <td>{certificates}</td>
                <td className="text-center action-icons">

                    {(() => {
                        if(isPermitted('site_my_profile_product_portfolio_view_product')){
                            return <button onClick={() => viewContent(key)} type="button" className="btn btn-success">
                                <i className="bi bi-eye-fill cursor-pointer"></i>
                            </button>
                        }
                        return <button  title="No permission"  type="button" className="btn btn-success action-disabled">
                            <i className="bi bi-eye-fill"></i>
                        </button>
                    })()}
                    {(() => {
                        if(isPermitted('site_my_profile_product_portfolio_modify_product')){
                            return <button onClick={() => editItem(key) } type="button" className="btn btn-primary ms-1">
                                <i className="bi bi-pencil-fill"></i>
                            </button>
                        }

                        return <button  title="No permission"  type="button" className="btn btn-primary action-disabled ms-1">
                            <i className="bi bi-pencil-fill"></i>
                        </button>
                        
                    })()}
                    

                    {(() => {
                        if(isPermitted('site_my_profile_product_portfolio_delete_product')){
                            return <button onClick={() => deleteItem(key) } type="button" className="btn btn-danger ms-1">
                                <i className="bi bi-trash-fill"></i>
                            </button>
                        }
                        
                        return <button  title="No permission"  type="button" className="btn btn-danger ms-1 action-disabled">
                            <i className="bi bi-trash-fill"></i>
                        </button>
                    })()}
                </td>
            </tr>);
        });

        if (content.length < 1) {
            content.push(<tr>
                <td colSpan="10" className="text-center">No records found</td>
            </tr>);
        }

        return content;
    };


    const editItem = (key) => {
        var product = companyProducts[key];
        var editInfo = {
            pk: product.pk,
            product_code: product.product_code,
            keyword: product.keywords,
            brand: product.brands,
            business_type: product.business_type,
            description: product.description,
            industry: product.industries,
            credential: product.credentials,
            certificate: product.certificates
        }
        if (product.product) {
            editInfo.product = { label: product.product_read, value: product.product };
        }

        setFormData(initForm);
        setAlertMessage({});
        setFieldError({});
        setAddProduct(true);
        setFormData(editInfo);
    };

    const deleteItem = async (key) => {
        const result = await confirm('Are you sure to delete this?');
        if (!result) return;

        setAlertMessage({});

        const pk = companyProducts[key].pk;
        axios.delete("products/company-products/" + pk + "/")
            .then((response) => {
                setAlertMessage({ type: 'success', message: "Product Details Deleted Successfully!" });
                setrefreshList(true);
            })
            .catch((e) => {
                if (e.response.status === 400) {
                    setAlertMessage({ type: 'danger', message: "Error Occured While Delete!" });
                }
            });
        PageToTop();
    };

    const handlePageClick = (data) => {
        setPage(data.selected + 1);
        setrefreshList(true);
    };

    const showPagination = () => {
        if (pageCount <= 1) return;
        return <nav>
            <ReactPaginate
                pageClassName={'page-item'}
                previousClassName={'page-item active'}
                nextClassName={'page-item active'}
                pageLinkClassName={'page-link'}
                previousLinkClassName={'page-link'}
                nextLinkClassName={'page-link'}
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageCount}
                forcePage={page - 1}
                pageRangeDisplayed={recordsPerPage}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                activeClassName={'active'}
            />
        </nav>;
    };

    const addProductFun = () => {
        setFormData(initForm);
        setAlertMessage({});
        setFieldError({});
        setAddProduct(true);
    };

    if(noPermission){
        return (
        <div className="container">
            <div className="form-wrapper">
                <NavigationMenu item={ 4 }  />
                <div className="form-panel-wrapper">
                    { noPermissionTemplate() }
                </div>
            </div>
        </div>
        )
    }

    return (
        <div className="container">

            <div className="form-wrapper">
                <NavigationMenu item={4} />
                <div className="form-panel-wrapper">
                    <Notification {...alertMessage} />
                    {!addProduct ? (
                        <>
                            <div className="mb-3 text-end">
                                {(() => {
                                    if(isPermitted('site_my_profile_product_portfolio_add_product')){
                                        return <button className="button-style" type="button" onClick={() => { addProductFun(); }}>Add Product</button>
                                    }
                                    return <button title="No permission" type="button" className="button-style action-disabled">Add Product</button>
                                })()}
                            </div>
                            <div className="table-responsive row">
                                <table className="table align-middle table-bordered table-design">
                                    <thead>
                                        <tr>
                                            <td className="sno-width">S.No</td>
                                            <td className="ref-width">Product</td>
                                            <td className="ref-width">Brand / Make</td>
                                            <td className="subject-width">Description</td>
                                            <td className="ref-width">Keywords</td>
                                            <td>Type of Business</td>
                                            <td>Industry</td>
                                            <td>Key Clients</td>
                                            <td>Certificates</td>
                                            <td className="product-action prno-width">Action</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {getProducts()}
                                    </tbody>
                                </table>
                                {showPagination()}
                                <ReactTooltip className={"tooltip"} />
                            </div>
                        </>
                    ) : (
                        <form onSubmit={onSubmit}>
                            <div className="form-panel">
                                <div className="row row-cols-1 row-cols-lg-2 mb-3 form-elements">
                                    <div className="col-12 form-column form-address-panel">
                                        <div className="row mb-4 row-cols-1 row-cols-lg-2">
                                            <div className="col">
                                                <label className="form-label">Product <sup className="text-danger">*</sup></label>
                                                <AsyncCreatableSelect
                                                    onChange={handleChange}
                                                    loadOptions={productOptions}
                                                    name="product"
                                                    value={formData.product}
                                                    isClearable={true}
                                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, Input }}
                                                    className={errorBorder(filedError.product)}
                                                />
                                                {errorText(filedError.product)}
                                            </div>
                                            <div className="col">
                                                <label className="form-label">Product Code <sup className="text-danger">*</sup></label>
                                                <input className={"form-control " + errorBorder(filedError.product_code)} onChange={handleChange} name="product_code" value={formData.product_code} />
                                                {errorText(filedError.product_code)}
                                            </div>
                                        </div>

                                        <div className="row mb-4 row-cols-1 row-cols-lg-2">
                                            <div className="col">
                                                <label className="form-label">Brand / Make <sup className="text-danger">*</sup></label>
                                                <AsyncCreatableSelect
                                                    onChange={handleChange}
                                                    loadOptions={brandsOptions}
                                                    name="brand"
                                                    isMulti
                                                    value={formData.brand}
                                                    isClearable={true}
                                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, Input }}
                                                    className={errorBorder(filedError.brand)}
                                                />
                                                {errorText(filedError.brand)}
                                            </div>
                                            <div className="col">
                                                <label className="form-label">Keywords</label>
                                                <AsyncCreatableSelect
                                                    onChange={handleChange}
                                                    loadOptions={keywordsOptions}
                                                    name="keyword"
                                                    isMulti
                                                    value={formData.keyword}
                                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, Input }}
                                                    className={errorBorder(filedError.keyword)}
                                                />
                                                {errorText(filedError.keyword)}
                                            </div>
                                        </div>
                                        <div className="row mb-4 row-cols-1 row-cols-lg-2">
                                            <div className="col">
                                                <label className="form-label">Description</label>
                                                <textarea className={"form-control " + errorBorder(filedError.description)} name="description" onChange={handleChange} cols="3">{formData.description}</textarea>
                                                {errorText(filedError.description)}
                                            </div>
                                            <div className="col">
                                                <label className="form-label">Type of Business <sup className="text-danger">*</sup></label>
                                                <div className="mb-3">
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" onChange={handleChange} checked={formData.business_type == 1} name="business_type" type="radio" id="ManufacInfo" value="1" />
                                                        <label className="form-check-label" for="ManufacInfo">Manufacturer</label>
                                                    </div>

                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" onChange={handleChange} checked={formData.business_type == 2} name="business_type" type="radio" id="DealerInfo" value="2" />
                                                        <label className="form-check-label" for="DealerInfo">Dealer</label>
                                                    </div>
                                                    {errorText(filedError.business_type)}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mb-4 row-cols-1 row-cols-lg-3">
                                            <div className="col">
                                                <label className="form-label">Industry</label>
                                                <AsyncCreatableSelect
                                                    onChange={handleChange}
                                                    loadOptions={industriesOptions}
                                                    name="industry"
                                                    value={formData.industry}
                                                    isMulti
                                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, Input }}
                                                    className={errorBorder(filedError.industry)}
                                                />
                                                {errorText(filedError.industry)}
                                            </div>

                                            <div className="col">
                                                <label className="form-label">Key Clients</label>
                                                <AsyncCreatableSelect
                                                    onChange={handleChange}
                                                    loadOptions={credentialsOptions}
                                                    name="credential"
                                                    value={formData.credential}
                                                    isMulti
                                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, Input }}
                                                    className={errorBorder(filedError.credential)}
                                                />
                                                {errorText(filedError.credential)}
                                            </div>
                                            <div className="col">
                                                <label className="form-label">Certificates</label>
                                                <AsyncCreatableSelect
                                                    onChange={handleChange}
                                                    loadOptions={certificatesOptions}
                                                    name="certificate"
                                                    value={formData.certificate}
                                                    isMulti
                                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, Input }}
                                                    className={errorBorder(filedError.certificate)}
                                                />
                                                {errorText(filedError.certificate)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 text-end">
                                <a type="button" onClick={() => { setAddProduct(false); setAlertMessage({}); }} className="button-style">Cancel</a>&nbsp;&nbsp;
                                {formData.pk ? (
                                    <>
                                        <button type="submit" disabled={disableSubmit} className="button-style">Update</button>
                                    </>
                                ) : (
                                    <button type="submit" disabled={disableSubmit} className="button-style">Add Product</button>
                                )}
                            </div>
                        </form>
                    )}
                </div>
            </div>

            <Modal dialogClassName="modal-80" show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Product View #{viewCompanyProduct.pk}</Modal.Title>
                </Modal.Header>
                <Modal.Body><div className="form-panel">
                    <div className="row row-cols-1 row-cols-lg-2 mb-3 form-elements">
                        <div className="col-12 form-column form-address-panel">
                            <div className="row mb-4 row-cols-1 row-cols-lg-2 view-prod">
                                <div className="col">
                                    <label className="form-label">Product: </label>
                                    {viewCompanyProduct.product_read}
                                </div>
                                <div className="col">
                                    <label className="form-label">Product Code: </label>
                                    {viewCompanyProduct.product_code}
                                </div>
                            </div>

                            <div className="row mb-4 row-cols-1 row-cols-lg-2 view-prod">
                                <div className="col">
                                    <label className="form-label">Brand / Make: </label>
                                    {viewCompanyProduct.brands_read}
                                </div>
                                <div className="col">
                                    <label className="form-label">Keywords: </label>
                                    {viewCompanyProduct.keywords_read}
                                </div>
                            </div>
                            <div className="row mb-4 row-cols-1 row-cols-lg-2 view-prod">
                                <div className="col">
                                    <label className="form-label">Description: </label>
                                    {viewCompanyProduct.description}
                                </div>
                                <div className="col">
                                    <label className="form-label">Type of Business: </label>
                                    {viewCompanyProduct.business_type_read}
                                </div>
                            </div>

                            <div className="row mb-4 row-cols-1 row-cols-lg-2 view-prod">
                                <div className="col">
                                    <label className="form-label">Industry: </label>
                                    {viewCompanyProduct.industries_read}
                                </div>

                                <div className="col">
                                    <label className="form-label">Credentials: </label>
                                    {viewCompanyProduct.credentials_read}
                                </div>

                            </div>
                            <div className="row mb-4 row-cols-1 row-cols-lg-2 view-prod">
                                <div className="col">
                                    <label className="form-label">Certificates: </label>
                                    {viewCompanyProduct.certificates_read}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Products;