import React, { useState, useEffect } from "react";
import axios from "axios";
import { AuthTokenVerify, UpdateBearer } from "../../reusable/AuthUtils";
import ReactPaginate from 'react-paginate';
import { PageToTop, tryParseJSONObject } from "../../reusable/CommonUtils";
import { Link, useLocation } from "react-router-dom";
import { noPermissionTemplate, isPermitted } from "../../reusable/PermissoinUtils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import _ from "lodash"
 

const PoSellerSummary = ({history}) => {

    const params = new URLSearchParams(useLocation().search);
    const paramPage = params.get('page') ? params.get('page') : 1;
    const paramFilter = tryParseJSONObject(params.get('filter')) ? JSON.parse(params.get('filter')) : {};

    const [refreshList, setrefreshList] = useState(true);
    const [page, setPage] = useState(paramPage);
    const [pageCount, setPageCount] = useState(0);
    const [noPermission, setNoPermission] = useState(false);
    const [companyErfqList, setCompanyErfqList] = useState([]);
    const [filter, setFilter] = useState(paramFilter);
    const [currencies, setCurrencies] = useState([]);

    const availStatus = {0: 'Created', 1: 'Requested', 2: 'Accepted', 3: 'Rejected'};


    const recordsPerPage = 15;

    useEffect(() => {
        AuthTokenVerify(history);
        PageToTop();
    }, []);

    useEffect(() => {
        axios.get("common/currencies/").then((response) => {
            setCurrencies(response.data);
        }).catch((error) => {
            //
         });
    }, []);

    useEffect(() => {
        history(`/po-seller-summary?page=${page}&filter=${JSON.stringify(filter)}`)
    }, [refreshList]);

    useEffect(() => {
        if(!refreshList) return;

        let filterCopy = _.clone(filter);
        if(filterCopy.po_on_date) {
            filterCopy.po_on_date = format(new Date(filterCopy.po_on_date), "yyyy-MM-dd")
        }
        axios.get(`po/seller-form-list/?page=${page}&filter=${encodeURIComponent(JSON.stringify(filterCopy))}`).then((response) => {
            setCompanyErfqList(response.data.results);
            setPageCount(Math.ceil(response.data.count / recordsPerPage));
            UpdateBearer(history, response);
        }).catch((e) => {
            if(e.response.status === 403) {
                //permission denied
                setNoPermission(true);
            }
        });
    setrefreshList(false);
    }, [refreshList]);


    const filterHandle = (e) => {
        var targetData = '';

        switch (e.target.name) {
            default:
                targetData = e.target.value;
                break;
        }

        setFilter({...filter, [e.target.name] : targetData});
        filterHandleTrigger();
    };

    const filterHandleTrigger = () => {
        setrefreshList(true);
        setPage(1);
    };

    const getList = () => {
        const content = [];
        companyErfqList.map( (row, key) => {
            content.push(<tr key={key} >
                <td>{ (key + 1) + ( recordsPerPage * (page - 1) ) }</td>
                <td>{ row.status_read }</td>
                <td>{ row.reference_no }</td>
                <td>{ row.po_on_date }</td>
                <td>
                    {(() => {
                        if (row.buyer_customer_id) {
                            return <Link to={"/company-profile/" + row.buyer_customer_id}><b><span className="badge  bg-primary">{row.buyer_customer_id}</span></b> {row.buyer_company_name}</Link>
                            
                        }
                    })()} 
                </td>
                <td>{ row.subject }</td>
                <td>{ row.currency_read }</td>
                <td width={120} className="action-icons">
                    {(() => {
                        if(isPermitted('site_order_management_view')){
                            return <Link to={`/po-seller-view/${row.pk}`} className="btn btn-success ms-1">
                                <i className="bi bi-eye-fill cursor-pointer"></i>
                            </Link>
                        }
                        
                        return <button  title="No permission"  type="button" className="btn btn-success ms-1 action-disabled">
                            <i className="bi bi-eye-fill"></i>
                        </button>
                    })()}
                </td>
            </tr>);
        });

        if(content.length < 1){
            content.push(<tr>
                <td colSpan="11" className="text-center">No records found</td>
            </tr>);
        }

        return content;
    };

    const showPagination = () => {
        if (pageCount <= 1) return;
        return <nav>
            <ReactPaginate
                pageClassName={'page-item'}
                previousClassName={'page-item active'}
                nextClassName={'page-item active'}
                pageLinkClassName={'page-link'}
                previousLinkClassName={'page-link'}
                nextLinkClassName={'page-link'}
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageCount}
                forcePage={page - 1}
                pageRangeDisplayed={recordsPerPage}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                activeClassName={'active'}
            />
        </nav>;
    };

    const handlePageClick = (data) => {
        setPage(data.selected + 1);
        setrefreshList(true);
        PageToTop();
    };

    const renderBody = () => {
        return <>
            <div className="title-section mb-4">
                <h2>Order Management System</h2>
            </div>
            <div className="table-responsive row">
                <table className="table align-middle table-bordered table-design">
                    <thead>
                        <tr>
                            <td colSpan={2}>
                                <select name="status" value={filter['status'] ? filter['status'] : ""} onChange={filterHandle} className="form-control">
                                    <option value={""}>--status--</option>
                                    {
                                        Object.keys(availStatus).map((key, index) => ( 
                                            <option value={key}>{ availStatus[key] }</option>
                                        ))
                                    }
                                </select>
                            </td>
                            <td><input type="text" style={{width: 'auto'}} className="form-control" onChange={filterHandle} name="ref_no" value={filter['ref_no'] ? filter['ref_no'] : ""} /></td>
                            <td>
                                <DatePicker
                                    className={"form-control"}
                                    dateFormat="dd/MM/yyyy"
                                    selected={filter.po_on_date}
                                    onChange={(date) => {
                                        setFilter({...filter, 'po_on_date' : date});
                                        filterHandleTrigger();
                                    }}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                />
                            </td>
                            <td><input type="text" style={{width: 'auto'}} className="form-control" onChange={filterHandle} name="buyer" value={filter['buyer']  ? filter['buyer'] : "" }  /></td>
                            <td><input type="text" style={{width: 'auto'}} className="form-control" onChange={filterHandle} name="subject" value={filter['subject']  ? filter['subject'] : "" }  /></td>
                            <td className="po-width">
                                <select name="currency" value={filter['currency'] ? filter['currency'] : ""} onChange={filterHandle} className="form-control">
                                    <option value={""}>--currency--</option>
                                    {
                                        currencies.map((row, key) => ( 
                                            <option value={row.pk}>{ row.code }</option>
                                        ))
                                    }
                                </select>
                            </td>
                            <td class="text-center"><button className="btn btn-danger" onClick={() => { setFilter({}); filterHandleTrigger(); }}><i class="bi bi-bootstrap-reboot"></i></button></td>
                        </tr>
                        <tr>
                            <td className="sno-width">S.No</td>
                            <td className="po-width">Status</td>
                            <td className="po-width">PO No.</td>
                            <td className="po-width">PO Date</td>
                            <td className="subject-width">Client</td>
                            <td className="posub-width">Subject</td>
                            <td>Currency</td>
                            <td className="status-width text-center">Action</td>
                        </tr>
                    </thead>
                    <tbody>
                        { getList() }
                    </tbody>
                </table>
                { showPagination() }
            </div>
        </>;
    };
    if(noPermission){
        return noPermissionTemplate();
    }

    return (
        <div className="container">
        <div className="form-wrapper summary-container">
            { renderBody() }
        </div>
        </div>
    );
};

export default PoSellerSummary;