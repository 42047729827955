import React, { useState, useEffect } from "react";
import AsyncSelect from 'react-select/async';
import axios from "axios";
import NavigationMenu from "./NavigationMenu";
import { AuthTokenVerify, UpdateBearer } from "../../reusable/AuthUtils";
import Notification, { errorText, errorBorder } from '../../reusable/Notification';
import ReactPaginate from 'react-paginate';
import { PageToTop } from "../../reusable/CommonUtils";
import { Link } from "react-router-dom";
import { noPermissionTemplate, isPermitted } from "../../reusable/PermissoinUtils";

const ConnSearch = ({history}) => {
    const initForm = {
        'company': '',
        'advance_search': false,
        'product': null,
        'brand': [],
        'business_type': {},
        'industry': [],
        'credential': [],
        'certificate': []
    };

    const [formData, setFormData] = useState(initForm);
    const [dataList, setDataList] = useState([]);
    const [dataListFetched, setDataListFetched] = useState(false);

    const [filedError, setFieldError] = useState({});
    const [alertMessage, setAlertMessage] = useState({});

    const [refreshList, setrefreshList] = useState(false);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);

    const recordsPerPage = 15;

    useEffect(() => {
        AuthTokenVerify(history);
        PageToTop();
    }, []);

    const clearSearch = () => {
        setFormData(initForm);
    };

    const handleChange = (newValue, actionMeta) => {
        if(typeof(actionMeta) !== 'undefined'){
            setFormData({
                ...formData,
                [actionMeta.name]: newValue
            });
        }else{
            let targetData = '';
            switch (newValue.target.name) {
                case "advance_search":
                    targetData = newValue.target.checked;
                    break;
                case "business_type":
                    targetData = {
                        ...formData.business_type, 
                        [newValue.target.value]: newValue.target.checked
                    };
                    break;
                default:
                    targetData = newValue.target.value;
                    break;
            }
            setFormData({
                ...formData,
                [newValue.target.name]: targetData
            });
        }
    };

    const productOptions = (inputValue) => {
        return new Promise(resolve => {
            axios.get("products/product-search/?q=" + inputValue).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                //
             });
        });
    };

    const brandsOptions = (inputValue) => {
        let data = formData.brand.map( brands => { if(!brands.__isNew__) return brands.value }).filter(e => e).join(',');
        return new Promise(resolve => {
            axios.get("products/brands-search/?q=" + inputValue + "&skip=" + data).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                //
             });
        });
    };

    const industriesOptions = (inputValue) => {
        let data = formData.industry.map( industries => { if(!industries.__isNew__) return industries.value }).filter(e => e).join(',');
        return new Promise(resolve => {
            axios.get("products/industries-search/?q=" + inputValue + "&skip=" + data).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                //
             });
        });
    };

    const credentialsOptions = (inputValue) => {
        let data = formData.credential.map( credentials => { if(!credentials.__isNew__) return credentials.value }).filter(e => e).join(',');
        return new Promise(resolve => {
            axios.get("products/credentials-search/?q=" + inputValue + "&skip=" + data).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                //
             });
        });
    };

    const certificatesOptions = (inputValue) => {
        let data = formData.certificate.map(certificates => { if(!certificates.__isNew__) return certificates.value }).filter(e => e).join(',');
        return new Promise(resolve => {
            axios.get("products/certificates-search/?q=" + inputValue + "&skip=" + data).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                //
             });
        });
    };

    const handlePageClick = (data) => {
        setPage(data.selected + 1);
        setrefreshList(true);
    };

    const showPagination = () => {
        if (pageCount <= 1) return;
        return <nav>
            <ReactPaginate
                pageClassName={'page-item'}
                previousClassName={'page-item active'}
                nextClassName={'page-item active'}
                pageLinkClassName={'page-link'}
                previousLinkClassName={'page-link'}
                nextLinkClassName={'page-link'}
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageCount}
                forcePage={page - 1}
                pageRangeDisplayed={recordsPerPage}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                activeClassName={'active'}
            />
        </nav>;
    };

    useEffect(() => {
        if(!refreshList) return;
        formData['page'] = page;
        axios.get("connect/connsearch/", {
            params: {
                page: page,
                company: formData.company,
                advance_search: formData.advance_search ? 1 : 0,
                product: JSON.stringify(formData.product),
                brand: JSON.stringify(formData.brand),
                business_type: JSON.stringify(formData.business_type),
                industry: JSON.stringify(formData.industry),
                credential: JSON.stringify(formData.credential),
                certificate: JSON.stringify(formData.certificate)
            }
        }).then((response) => {
            setDataList(response.data.results);
            setPageCount(Math.ceil(response.data.count / recordsPerPage));
            UpdateBearer(history, response);
            setDataListFetched(true);
        })
        .catch((e) => {
            if(e.response.status === 400) {
                setAlertMessage({type:'danger', message:"Error Occured While Submit!"});
                const dict = {};
                for (const [key, value] of Object.entries(e.response.data)) {
                    dict[key] = value.join(', ');
                }
                setFieldError(dict);
            }else if(e.response.status === 404) {
                setPage(1);
                setrefreshList(true);
            }
            else if(e.response.status === 403) {
                //permission denied
                history('/');
            }
        });
    setrefreshList(false);
    }, [refreshList]);

    const onSubmit = (e) => {
        e.preventDefault();
        setFieldError({});
        setAlertMessage({});
        setDataList([]);
        setrefreshList(true);
        setPageCount(0);
        setDataListFetched(false);
    };

    const sendRequest = (key) => {

        setAlertMessage({});

        const newDataList = [...dataList];

        axios.post("connect/connsearch/", {
            company: newDataList[key].pk
        }).then((response) => {
            newDataList[key]['requested'] = true;
            setDataList(newDataList);
            UpdateBearer(history, response);
        }).catch((e) => {
            setAlertMessage({type:'danger', message:e.response.data.message});
            UpdateBearer(history, e.response);
            PageToTop();
        });
    };

    const connSearchList = () => {
        const content = [];

        if(dataListFetched && dataList.length < 1){
            content.push(
                <a className="list-group-item list-group-item-action flex-column align-items-start">
                    <h5 className="mb-1 text-center">No Records Found</h5>
                </a>
            );
            return content;
        }

        dataList.map( (item, key) => {
            let button = <span>
                <button 
                    type="button" 
                    onClick={ () => { sendRequest(key) } } 
                    className="btn btn-primary "
                >Request</button>
            </span>;

            if(item.requested){
                button = <span>
                    <button 
                        type="button" 
                        className="btn btn-warning "
                    >Requested</button>
                </span>;
            }

            content.push(
                <a className="list-group-item list-group-item-action flex-column align-items-start">
                    <div className="d-flex w-100 justify-content-between">
                        <Link to={"/company-profile/" + item.customer_id} target={'_blank'} className="mb-1"><b><span className="badge  bg-primary">{ item.customer_id }</span></b> &nbsp;{ item.company_name }</Link>
                        { button }
                    </div>
                </a>
            );
        });

        

        return content;
    };

    if(!isPermitted('site_my_connect_search_request')){
        return (<div className="container">
            <div className="form-wrapper">
                <NavigationMenu item={ 4 }/>
                <div className="form-panel-wrapper">
                    { noPermissionTemplate() }
                </div>
            </div>
        </div>)
    }

    return (
        <div className="container">
            <div className="form-wrapper">
                <NavigationMenu item={ 4 }/>
                <div className="form-panel-wrapper">
                <Notification {...alertMessage}/>
                    <form onSubmit={ onSubmit }>
                        <div className="row mb-4 row-cols-1 row-cols-lg-3">
                                <div className="col"></div>
                                <div className="col">
                                    <label className="form-label">Company Name / Company ID</label>
                                    <input type="text" className={"form-control " + errorBorder(filedError.company) } name="company" onChange={handleChange} value={formData.company} />
                                    { errorText(filedError.company) }
                                </div>
                                <div className="col"></div>
                            </div>
                            <div className="row mb-4 row-cols-1 row-cols-lg-3">
                                <div className="col">
                                    <div className="form-group form-check">
                                        <input type="checkbox" checked={formData.advance_search} className="form-check-input" id="advance_search" name="advance_search" onClick={handleChange}/>
                                        <label className="form-check-label" for="advance_search"><b>Advance Search</b></label>
                                    </div>
                                </div>
                            </div>
                        {(() => {
                        if (formData.advance_search){
                            return (
                                <>
                                <div className="row mb-4 row-cols-1 row-cols-lg-3">
                                    <div className="col">
                                        <label className="form-label">Product Name <sup className="text-danger">*</sup></label>
                                        <AsyncSelect
                                            onChange={handleChange}
                                            loadOptions={productOptions}
                                            name="product"
                                            isClearable={true}
                                            value={formData.product}
                                            className={errorBorder(filedError.product) }
                                            components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null}}
                                        />
                                        { errorText(filedError.product) }
                                    </div>
                                    <div className="col">
                                        <label className="form-label">Brand / Make</label>
                                        <AsyncSelect
                                            onChange={handleChange}
                                            loadOptions={brandsOptions}
                                            name="brand"
                                            isMulti
                                            value={formData.brand}
                                            components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null}}
                                        />
                                    </div>
                                    <div className="col">
                                        <label className="form-label">Type of Business</label>
                                        <div className="mb-3">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" name="business_type" type="checkbox" checked={formData.business_type["1"] ? true: false} id="ManufacInfo" onChange={handleChange} value="1" />
                                                <label className="form-check-label" for="ManufacInfo">Manufacturer</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" name="business_type" type="checkbox" checked={formData.business_type["2"] ? true: false} id="DealerInfo" onChange={handleChange} value="2" />
                                                <label className="form-check-label" for="DealerInfo">Dealer</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-4 row-cols-1 row-cols-lg-3">
                                    <div className="col">
                                        <label className="form-label">Industry</label>
                                        <AsyncSelect
                                            onChange={handleChange}
                                            loadOptions={industriesOptions}
                                            name="industry"
                                            value={formData.industry}
                                            isMulti
                                            components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null}}
                                        />
                                    </div>
                                    <div className="col">
                                        <label className="form-label">Key Clients</label>
                                        <AsyncSelect
                                            onChange={handleChange}
                                            loadOptions={credentialsOptions}
                                            name="credential"
                                            value={formData.credential}
                                            isMulti
                                            components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null}}
                                        />
                                    </div>
                                    <div className="col">
                                        <label className="form-label">Certificates</label>
                                        <AsyncSelect
                                            onChange={handleChange}
                                            loadOptions={certificatesOptions}
                                            name="certificate"
                                            value={formData.certificate}
                                            isMulti
                                            components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null}}
                                        />
                                    </div>
                                </div>
                                
                                </>
                            )
                        }
                        return null;
                        })()}
                        <div className="cta-btn text-center">
                            <button type="button" onClick={clearSearch} className="btn btn-warning button-secondary">Clear</button>&nbsp;
                            <button type="submit" className="btn btn-primary button-style" >Search</button>
                        </div>
                    </form>

                    <div className="list-group mt-5">
                        { connSearchList() }
                    </div>
                    <br />
                    { showPagination() }
                </div>
            </div>
       </div>
    );
};
export default ConnSearch;