import React, { useState, useEffect } from "react";
import { confirm } from '../../reusable/Confirmation';
import axios from "axios";
import Notification, { errorText, errorBorder }  from "../../reusable/Notification";
import NavigationMenu from "../Profile/NavigationMenu";
import { AuthTokenVerify, UpdateBearer } from "../../reusable/AuthUtils";
import { PageToTop, tryParseJSONObject } from "../../reusable/CommonUtils";
import { Link, useLocation } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import { noPermissionTemplate, isPermitted } from "../../reusable/PermissoinUtils";
import _, { words, wrap } from "lodash";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Select from 'react-select';

const AddressBook = ({history}) => {

    const params = new URLSearchParams(useLocation().search);
    const paramPage = params.get('page') ? params.get('page') : 1;
    const paramFilter = tryParseJSONObject(params.get('filter')) ? JSON.parse(params.get('filter')) : {};
    const initForm = {
        company_name: '',
        plot_or_door_no: '',
        street_name: '',
        city: '',
        contact_person: '',
        mobile_number: '',
        gstin_number: '',
        pincode: '',
        land_mark: '',
        map_location: '',
        email: '',
        country: '',
        state: '',
    }

    const [ AddressBook, setAddressBook ] = useState([]);
    const [ alertMessage, setAlertMessage ] = useState({});
    const [ disableSubmit, setDisableSubmit ] = useState(false);
    const [ filedError, setFieldError ] = useState({});
    const [ formData, setFormData ] = useState(initForm);
    const [ countries, setCountries ] = useState([]);
    const [ states, setStates ] = useState([]);
    const [noPermission, setNoPermission] = useState(false);

    const [refreshList, setrefreshList] = useState(false);
    const [page, setPage] = useState(paramPage);
    const [pageCount, setPageCount] = useState(0);
    const [filter, setFilter] = useState(paramFilter);

    const recordsPerPage = 15;

    const callListPage = () => {
        axios.get(`/address-book/?page=${page}&filter=${JSON.stringify(filter)}`).then((response) => {
            setAddressBook(response.data.results);
            setPageCount(Math.ceil(response.data.count / recordsPerPage));
            UpdateBearer(history, response);
        }).catch((e) => {
            if(e.response.status === 403) {
                //permission denied
                setNoPermission(true);
            }
        });
    };
    
    useEffect(() => {

        AuthTokenVerify(history);

        callListPage();

        axios.get("common/countries/").then((response) => {
            setCountries(response.data)
        }).catch((error) => {
            //
         });
         PageToTop();
    }, []);

    useEffect(() => {
        AuthTokenVerify(history);
        callListPage();
        setrefreshList(false);
    }, [refreshList]);

    useEffect(() => {
        setStates([]);
        if(!formData.country) return;
        axios.get("common/states/" + formData.country + "/").then((response) => {
            let stateArr = [];
            response.data.map(item => {
                stateArr.push({"value":item.id, "label": item.state});
            });
            setStates(stateArr);
        }).catch((error) => {
            //
         });
    }, [formData.country]);

    const getCountries = () => {
        const content = [<option value="0">-select-</option>];
        countries.map(item => {
            content.push(<option key={item.id} value={item.id}>{item.country}</option>);
        });
        return content;
    };

    const updateTable = (fieldName, targetData) => {
        let formCopy = {...formData, [fieldName]: targetData};
        setFormData(formCopy);
        
        formCopy.is_validate = 1;
        formCopy.validate_field = fieldName
        
        axios.post("address-book/", formCopy)
        .then((response) => {
            setFieldError({});
        })
        .catch((error) => {
            if(error.response.status === 400) {
                let dict = {};
                for (const [key, value] of Object.entries(error.response.data)) {
                    if(key == fieldName) {
                        dict[key] = value.join(', ');
                    }
                }
                setFieldError(dict);
            }
            setDisableSubmit(false);
        });
    }

    const handleStateChange = (e) => {
        updateTable('state', e.value);
    };

    const handleChange = (e) => {

        var targetData = '';
        var targetName = e.target.name;
        switch (targetName) {
            case "country":
                targetData = parseInt(e.target.value) ? parseInt(e.target.value) : '';
                updateTable(e.target.name, targetData);
                break;
            default:
                targetData = e.target.value;
                updateTable(e.target.name, targetData);
                break;
        }
    };

    const filterHandle = (e) => {
        var targetData = '';
        var targetName = e.target.name;

        switch (e.target.name) {
            default:
                targetData = e.target.value;
                break;
        }
        let formCopy = _.clone(formData);
        formCopy.is_validate = 1;
        formCopy.validate_field = targetName
        axios.post("address-book/", formCopy)
        .then((response) => {
        })
        .catch((error) => {
            if(error.response.status === 400) {
                let dict = {};
                for (const [key, value] of Object.entries(error.response.data)) {
                    if(key == targetName) {
                        dict[key] = value.join(', ');
                    }
                }
                setFieldError(dict);
            }
            setDisableSubmit(false);
        });

        setFilter({...filter, [e.target.name] : targetData});
        filterHandleTrigger();
    };

    const filterHandleTrigger = () => {
        setrefreshList(true);
        PageToTop(0, 700);
        setPage(1);
    };

    const clearAddForm = () => {
        setAlertMessage({});
        setFieldError({});
        setFormData(initForm);
    };

    const editItem = (key) => {
        setAlertMessage({});
        setFieldError({});
        setFormData({...AddressBook[key], ['index']: key});
        PageToTop(0, 0);
    };

    const deleteItem = async (key) => {
        const result = await confirm('Are you sure to delete this?');
        if(!result) return;

        clearAddForm();
        const pk = AddressBook[key].pk;
        axios.delete("address-book/" + pk + "/")
            .then((response) => {
                if(response.status === 200){
                    setAlertMessage({type:'success', message:"Addressbook Details Deleted Successfully!"});
                    const newAddressbooks = [...AddressBook];
                    newAddressbooks.splice(key, 1);
                    setAddressBook(newAddressbooks);
                    PageToTop(0, 700);
                }
            })
            .catch((e) => {
                if(e.response.status === 400) {
                    setAlertMessage({type:'danger', message:"Error Occured While Delete!"});
                    PageToTop(0, 700);
                }
            });
    };

    const onSubmit = (e) => {

        e.preventDefault();
        setAlertMessage({});
        setFieldError({});
        setDisableSubmit(true);

        formData.is_validate = 0;
        if(formData.pk){
            axios.put("address-book/" + formData.pk + "/", formData)
            .then((response) => {
                if(response.status === 200){
                    const newAddressbooks = [...AddressBook];
                    newAddressbooks[formData.index] = response.data;
                    setAddressBook(newAddressbooks);
                    setFormData(initForm);
                    setrefreshList(true);
                    PageToTop(0, 700);
                    setAlertMessage({type:'success', message:"Addressbook Details Updated Successfully!"});
                    setDisableSubmit(false);
                    PageToTop(0, 700);
                }
            })
            .catch((e) => {
                if(e.response.status === 400) {
                    setAlertMessage({type:'danger', message:"Error Occured While Submit!"});
                    const dict = {};
                    for (const [key, value] of Object.entries(e.response.data)) {
                        dict[key] = value.join(', ');
                    }
                    setFieldError(dict);
                }

                setDisableSubmit(false);
                PageToTop(0, 700);
            });
        }else{
            axios.post("address-book/", formData)
            .then((response) => {
                if(response.status === 200){
                    setFormData(initForm);
                    setrefreshList(true);
                    PageToTop(0, 700);
                    setAlertMessage({type:'success', message:"Addressbook Details Added Successfully!"});
                    setDisableSubmit(false);
                    PageToTop(0, 700);
                }
            })
            .catch((e) => {
                if(e.response.status === 400) {
                    setAlertMessage({type:'danger', message:"Error Occured While Submit!"});
                    let dict = {};
                    for (const [key, value] of Object.entries(e.response.data)) {
                        dict[key] = value.join(', ');
                    }
                    setFieldError(dict);
                }
                setDisableSubmit(false);
                PageToTop(0, 700);
            });
        }
    };

    const showPagination = () => {
        if (pageCount <= 1) return;
        return <nav>
            <ReactPaginate
                pageClassName={'page-item'}
                previousClassName={'page-item active'}
                nextClassName={'page-item active'}
                pageLinkClassName={'page-link'}
                previousLinkClassName={'page-link'}
                nextLinkClassName={'page-link'}
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageCount}
                forcePage={page - 1}
                pageRangeDisplayed={recordsPerPage}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                activeClassName={'active'}
            />
        </nav>;
    };

    const handlePageClick = (data) => {
        setPage(data.selected + 1);
        setrefreshList(true);
        PageToTop(0, 700);
    };

    const renderBody = () => {
        const content = [];
        AddressBook.map( (item, key) => {
            content.push(<tr key={key} >
                <td>{ (key+ 1) + ((page - 1) * recordsPerPage) }</td>
                <td>{ item.company_name }</td>
                <td style={{maxWidth: '50px', overflow: 'auto' }}>
                <br/>{ item.street_name },
                <br/>{ item.city } - { item.pincode },
                <br/>{ item.state_read },
                <br/>{ item.country_read }
                {(() => {
                    if(item.land_mark) {
                        return <>
                            <br/>{ item.land_mark }
                        </>
                    }
                })()}
                {(() => {
                    if(item.map_location) {
                        return <>
                            <br/>
                            <OverlayTrigger trigger="click" rootClose placement="left" overlay={<Popover id="popover-trigger-click-root-close" title="Popover right"><strong>{item.map_location}</strong></Popover>}>
                                <i class="bi bi-geo-alt-fill"></i>
                            </OverlayTrigger>
                        </>
                    }
                })()}
                </td>
                <td>{ item.mobile_number }</td>
                <td>{ item.contact_person }</td>
                <td>{ item.email }</td>
                <td>{ item.gstin_number }</td>
                <td className="text-center">
                    {(() => {
                        if(isPermitted('site_my_profile_branch_details_modify_branch')){
                            return <button onClick={() => editItem(key) } type="button" className="btn btn-primary">
                                <i className="bi bi-pencil-fill"></i>
                            </button>
                        }

                        return <button  title="No permission"  type="button" className="btn btn-primary action-disabled">
                            <i className="bi bi-pencil-fill"></i>
                        </button>
                        
                    })()}

                    
                    {(() => {
                        if(isPermitted('site_my_profile_branch_details_delete_branch')){
                            return <button onClick={() => deleteItem(key) } type="button" className="btn btn-danger ms-1">
                                <i className="bi bi-trash-fill"></i>
                            </button>
                        }
                        
                        return <button  title="No permission"  type="button" className="btn btn-danger ms-1 action-disabled">
                            <i className="bi bi-trash-fill"></i>
                        </button>
                    })()}
                </td>
            </tr>);
        });

        if(content.length < 1){
            content.push(<tr>
                <td colSpan="5" className="text-center">No records found</td>
            </tr>);
        }

        return content;
    };

    if(noPermission){
        return (
        <div className="container">
            <div className="form-wrapper">
                <NavigationMenu item={ 10 }  />
                <div className="form-panel-wrapper">
                    { noPermissionTemplate() }
                </div>
            </div>
        </div>
        )
    }

    return (
        <div className="container">
            <div className="form-wrapper">
                <NavigationMenu item={ 10 }  />
                <div className="form-panel-wrapper">
                    <Notification {...alertMessage}/>
                    <fieldset disabled={!isPermitted('site_my_profile_branch_details_add_branch') && !formData.pk}>
                        <form onSubmit={ onSubmit }>
                            <div className="form-panel">
                                <div className="row row-cols-1 row-cols-lg-2 mb-3 form-elements">
                                    <div className="col-12 form-column form-address-panel">
                                        <div className="row mb-4 row-cols-1 row-cols-lg-3">
                                            <div className="col">
                                                <label className="form-label">Company Name <sup className="text-danger">*</sup></label>
                                                <input type="text" name="company_name" value={formData.company_name} className={"form-control " + errorBorder(filedError.company_name) } onChange={handleChange} />
                                                { errorText(filedError.company_name) }
                                            </div>
                                            <div className="col">
                                                <label className="form-label">Plot No / Door No <sup className="text-danger">*</sup></label>
                                                <input type="text" name="plot_or_door_no" value={formData.plot_or_door_no} className={"form-control " + errorBorder(filedError.plot_or_door_no) } onChange={handleChange} />
                                                { errorText(filedError.plot_or_door_no) }
                                            </div>
                                            <div className="col">
                                                <label className="form-label">Street Name <sup className="text-danger">*</sup></label>
                                                <input type="text" name="street_name" value={formData.street_name} className={"form-control " + errorBorder(filedError.street_name) } onChange={handleChange} />
                                                { errorText(filedError.street_name) }
                                            </div>
                                        </div>
                                        <div className="row mb-4 row-cols-1 row-cols-lg-3">
                                            <div className="col">
                                                <label className="form-label">City <sup className="text-danger">*</sup></label>
                                                <input type="text" name="city" value={formData.city} className={"form-control " + errorBorder(filedError.city) } onChange={handleChange} />
                                                { errorText(filedError.city) }
                                            </div>
                                            <div className="col">
                                                <label className="form-label">Country <sup className="text-danger">*</sup></label>
                                                <select className={"form-select " + errorBorder(filedError.country) } value={formData.country} name="country" onChange={handleChange}>
                                                    { getCountries() }
                                                </select>
                                                { errorText(filedError.country) }
                                            </div>
                                            <div className="col">
                                                <label className="form-label">State <sup className="text-danger">*</sup></label>
                                                <Select
                                                    value = {
                                                        states.filter(option => 
                                                        option.value === formData.state)
                                                    }
                                                    onChange={handleStateChange}
                                                    options={states}
                                                />
                                                { errorText(filedError.state) }
                                            </div>
                                        </div>
                                        <div className="row mb-4 row-cols-1 row-cols-lg-3">
                                            <div className="col">
                                                <label className="form-label">Pincode <sup className="text-danger">*</sup></label>
                                                <input type="text" name="pincode" value={formData.pincode} className={"form-control " + errorBorder(filedError.pincode) } onChange={handleChange} />
                                                { errorText(filedError.pincode) }
                                            </div>
                                            <div className="col">
                                                <label className="form-label">Landmark</label>
                                                <input type="text" name="land_mark" value={formData.land_mark} className={"form-control " + errorBorder(filedError.land_mark) } onChange={handleChange} />
                                                { errorText(filedError.land_mark) }
                                            </div>
                                            <div className="col">
                                                <label className="form-label">Map Location</label>
                                                <input type="text" name="map_location" value={formData.map_location} className={"form-control " + errorBorder(filedError.map_location) } onChange={handleChange} />
                                                { errorText(filedError.map_location) }
                                            </div>
                                        </div>
                                        <div className="row mb-4 row-cols-1 row-cols-lg-3">
                                            <div className="col">
                                                <label className="form-label">Contact person <sup className="text-danger">*</sup></label>
                                                <input type="text" name="contact_person" value={formData.contact_person} className={"form-control " + errorBorder(filedError.contact_person) } onChange={handleChange} />
                                                { errorText(filedError.contact_person) }
                                            </div>
                                            <div className="col">
                                                <label className="form-label">Email <sup className="text-danger">*</sup></label>
                                                <input type="text" name="email" value={formData.email} className={"form-control " + errorBorder(filedError.email) } onChange={handleChange} />
                                                { errorText(filedError.email) }
                                            </div>
                                            <div className="col">
                                                <label className="form-label">Mobile Number <sup className="text-danger">*</sup></label>
                                                <input type="text" name="mobile_number" value={formData.mobile_number} className={"form-control " + errorBorder(filedError.mobile_number) } onChange={handleChange} />
                                                { errorText(filedError.mobile_number) }
                                            </div>
                                        </div>
                                        <div className="row row-cols-1 row-cols-lg-3">
                                            <div className="col">
                                                <label className="form-label">GSTIN Number</label>
                                                <input type="text" name="gstin_number" value={formData.gstin_number} className={"form-control " + errorBorder(filedError.gstin_number) } onChange={handleChange} />
                                                { errorText(filedError.gstin_number) }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 text-end">
                            {(() => {
                                if(formData.pk){
                                    return <>
                                        <a type="button" onClick={() => clearAddForm()} className="button-style">Cancel</a>&nbsp;&nbsp;
                                        <button type="submit" disabled={ disableSubmit } className="button-style">Update</button>
                                    </>
                                }else{
                                    if(isPermitted('site_my_profile_branch_details_add_branch')){
                                        return <button type="submit" disabled={ disableSubmit } className="button-style">Add Addressbook</button>
                                    }
                                    return <button title="No permission" type="button" className="button-style action-disabled">Add Addressbook</button>
                                }
                                
                            })()}
                            </div>
                        </form>
                    </fieldset>
                    <div className="table-responsive row">
                        <table className="table align-middle table-bordered table-design">
                            <thead>
                            <tr>
                                <td></td>
                                <td><input type="text" style={{width: 'auto'}} className="form-control" onChange={filterHandle} name="company_name" value={filter['company_name']  ? filter['company_name'] : ""} /></td>
                                <td><input type="text" style={{width: 'auto'}} className="form-control" onChange={filterHandle} name="address" value={filter['address']  ? filter['address'] : ""} /></td>
                                <td><input type="text" style={{width: '6.5625rem'}} className="form-control" onChange={filterHandle} name="mobile_number" value={filter['mobile_number']  ? filter['mobile_number'] : ""} /></td>
                                <td><input type="text" style={{width: '10rem'}} className="form-control" onChange={filterHandle} name="contact_person" value={filter['contact_person']  ? filter['contact_person'] : ""} /></td>
                                <td><input type="text" style={{width: 'auto'}} className="form-control" onChange={filterHandle} name="email" value={filter['email']  ? filter['email'] : ""} /></td>
                                <td><input type="text" style={{width: 'auto'}} className="form-control" onChange={filterHandle} name="gstin_number" value={filter['gstin_number']  ? filter['gstin_number'] : ""} /></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td width="40">S.No</td>
                                <td>Company Name</td>
                                <td width="50" className="address-column">Address</td>
                                <td>Mobile Number</td>
                                <td>Contact Person</td>
                                <td>Email</td>
                                <td>GSTIN Number</td>
                                <td style={{minWidth: "6.5625rem"}}>Action</td>
                            </tr>
                            </thead>
                            <tbody>
                                { renderBody() }
                            </tbody>
                        </table>
                        { showPagination() }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddressBook;