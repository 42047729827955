import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { AuthTokenVerify, UpdateBearer } from "../../reusable/AuthUtils";
import Notification, {
  errorBorder,
  errorText,
} from "../../reusable/Notification";
import { PageToTop, returnOnlyDefined } from "../../reusable/CommonUtils";

import { confirm } from "../../reusable/Confirmation";
import { useParams, Link } from "react-router-dom";

const EquipmentAdd = ({ history, setShowLoader }) => {
  const { id } = useParams();

  const initDocument = {
    file_name: "",
    document: "",
  };

  
  const initFieldError = {
    ems_form: {},
  };

  const initForm = {
    documents: [initDocument],
    name: "",
    type: "",
    make: "",
    serial_no: "",
    part_no: "",
    item_code: "",
    parent_eqp: "",
    department: "",
    sub_department:"",
    equipment_drawing: 0,
  };

  const equipment_type = [
    { pk: 0, name: "Main Equipment" },
    { pk: 1, name: "Spare Parts" },
  ];

  const [formData, setFormData] = useState(initForm);
  const [parentEquipment, setParentEquipment] = useState([]);
  const [equipmentCategory, setEquipmentCategory] = useState([]);
  const [equipmentSubDepartment, setEquipmentSubDepartment] = useState([]);
  const [mapDepartment,setMapDepartment] = useState([]);
  const [filedError, setFieldError] = useState(initFieldError);
  const [alertMessage, setAlertMessage] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [updateID, setUpdateID] = useState(id ?? false);

  const fileInput = useRef([]);
  
 

  useEffect(() => {
    axios
    .get("dept/company_emp_dept/")
   .then((response) => {
     setEquipmentCategory(response.data.departments);
     setEquipmentSubDepartment(response.data.department_map)
     setMapDepartment(response.data.department_map);
   })
   .catch((error) => {});
   
    axios
      .get("ems/equipment/")
      .then((response) => {
        setParentEquipment(response.data.results);
        // UpdateBearer(history, response);
      })
      .catch((error) => {});
  }, []);

  useEffect(  () => {
   AuthTokenVerify(history);
  

    if (id) {
      axios
        .get(`ems/equipment/${id}`)
        .then((response) => {
          let data = response.data;
 
          setSubdepartment(data.department)
          setFormData({ ...data });
 
        })
        .catch((e) => {
          //
        });
    }
  }, []);

  const setSubdepartment = async(pk)=>{
  
        
       const result = mapDepartment.filter((item)=> item.parent_dept == pk)
        
       setEquipmentSubDepartment(result)
         
        
      //  equipmentSubDepartment
  }

  const removeDocument = async (index) => {
    const result = await confirm("Are you sure to delete this?");
    if (!result) return;
    const temp_list = formData.documents;
    temp_list.splice(index, 1);
    fileInput.current[index] = "";
    setFormData({ ...formData, documents: temp_list });
  };

  const addDocument = () => {
    const temp_list = formData.documents;
    temp_list.push(initDocument);
    setFormData({ ...formData, documents: temp_list });
  };

  const handleDocumentChange = (e, index) => {
    var targetData = "";

    switch (e.target.name) {
      case "document":
        targetData = e.target.files[0];
        break;
      default:
        targetData = e.target.value;
        break;
    }

    var documents = formData.documents;
    documents[index][e.target.name] = targetData;
    setFormData({ ...formData, documents: documents });
  };

  const renderEquipmentType = () => {
    const content = [<option value="">-select-</option>];
    equipment_type.map((item) => {
      content.push(<option value={item.pk}>{item.name}</option>);
    });
    return content;
  };

  const renderParentEquipment = () => {
    const content = [<option value="">-select-</option>];
    parentEquipment.map((item) => {
      content.push(<option value={item.pk}>{item.name}</option>);
    });
    return content;
  };

  const renderEquipmentCategory = () => {
    const content = [<option value="">-select-</option>];
    equipmentCategory.map((item) => {
       content.push(<option value={item.pk}>{item.department_read}</option>);
    });
    return content;
  };

  const renderEquipmentSubDepartment = () => {
    const content = [<option value="">-select-</option>];
    equipmentSubDepartment.map((item) => {
     
      return  content.push(<option value={item.sub_dept}>{item.sub_dept_read}</option>);
    
    });
    return content;
  };

  const handleChange = (e) => {
    let targetData = e.target.value;
    let targetName = e.target.name;
    
    if(targetName=='department'){
      setSubdepartment(targetData)

    }

    let updateData = { ...formData, [targetName]: targetData };

    if(targetName=='department'){
       updateData = {...updateData,sub_department:''}
    }else if(targetName =='type'){
      updateData = {...updateData,parent_eqp:''}
    }
    setFormData(updateData);
     
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setFieldError({});
    setDisableSubmit(true);
    setAlertMessage({});

    // file handling and form data making
    let fData = new FormData();
    Object.entries(formData).forEach(([k, v]) => {
      if (k == "documents") {
        let doc_names = {};
        let docs_pk = [];
        v.map((item, index) => {
          if (item.pk) {
            docs_pk.push(item.pk);
          } else {
            doc_names[index] = item.file_name;
            fData.append("doc_files_" + index, item.document);
          }
        });
        if (docs_pk.length > 0) {
          fData.append("docs_pk", JSON.stringify(docs_pk));
        }
        fData.append("doc_names", JSON.stringify(doc_names));
      } else {
        if (v == null ) {
          fData.append(k, "");
        } else {
          fData.append(k, v);
        }
      }
    });

    axios({
      method: "post",
      url: updateID ? `ems/equipment/${updateID}/` : `ems/equipment/`,
      data: fData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        setShowLoader(false);
        setAlertMessage({ type: "success", message: response.data.message });
        UpdateBearer(history, response);
        setDisableSubmit(false);
        PageToTop();
         if(!updateID){
          setFormData(initForm);
          fileInput.current[0].value = "";
         }
          
         
      })
      .catch((e) => {
        setShowLoader(false);
        UpdateBearer(history, e.response);
        
        if (e.response.status === 400) {
          setAlertMessage({
            type: "danger",
            message: "Error Occured While Submit!",
          });
         

          let dict = {};
        

          for (const [key, value] of Object.entries(e.response.data)) {
            if (key ==="ems_form") {
              dict["ems_form"] = {};
              if (Object.keys(value).length > 0) {
                for (const [sub_key, sub_value] of Object.entries(value)) {
                
                    dict["ems_form"][sub_key] = sub_value;
                
                  
                }
              }
            } else {
              dict[key] = value.join(", ");
            }
          }
          
     
          setFieldError({
             ...dict
          });
        
        }
        setDisableSubmit(false);
        PageToTop();
      });

   
  };

  const renderDocuments = ( ) => {
    const content = [];
    formData.documents.map((item, index) => {
      let fetched_doc = item.pk;
      content.push(
        <div className="row mb-3 row-cols-1 row-cols-lg-3 single-col">
          {(() => {
            if (fetched_doc) {
              return (
                <>
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      name="file_name"
                      value={item.file_name}
                    />
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      name="file_name"
                      value={item.document}
                    />
                  </div>
                </>
              );
            }

            return (
              <>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    name="file_name"
                    value={item.file_name}
                   
                    onChange={(e) => handleDocumentChange(e, index)}
                  />
                  {errorText(
                    returnOnlyDefined(
                      filedError,
                      "object.ems_form[" + index + "].file_name"
                    )
                  )}
                </div>

                <div className="col">
                  <input
                    type="file"
                    
                    className="form-control"
                    ref={(element) => {
                      fileInput.current[index] = element;
                    }}
                    name="document"
                    onChange={(e) => handleDocumentChange(e, index)}
                  />
                  {errorText(
                    returnOnlyDefined(
                      filedError,
                      "object.ems_form[" + index + "].document"
                    )
                  )}
                </div>
              </>
            );
          })()}

          <div className="col-1 plus-icon">
            {(() => {
              return (
                <span>
                  <i
                    className="bi bi-dash-circle"
                    onClick={() =>  removeDocument(index) }
                  ></i>
                  &nbsp;
                  <i
                    className="bi bi-plus-circle"
                    onClick={() =>  addDocument() }
                  ></i>
                </span>
              );
            })()}
          </div>
        </div>
      );
    });
    return content;
  };

  return (
    <>
      <div className="container">
        <div className="form-wrapper">
          <div className="form-panel-wrapper">
            <Notification {...alertMessage} />
            <form onSubmit={onSubmit}>

              <div className="row mb-4 row-cols-1 row-cols-lg-3">
                <div className="col">
                  <label className="form-label">
                    Equipment Name<sup className="text-danger">*</sup>
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className={"form-control " + errorBorder(filedError.name)}
                  />
                  {errorText(filedError.name)}
                </div>

                <div className="col">
                  <label className="form-label">
                    Equipment Type<sup className="text-danger">*</sup>
                  </label>
                  <select
                    name="type"
                    value={formData.type}
                    onChange={handleChange}
                    className={"form-select " + errorBorder(filedError.type)}
                  >
                    {renderEquipmentType()}
                  </select>
                  {errorText(filedError.type)}
                </div>

                <div className="col">
                  <label className="form-label">Parent Equipment</label>
                  <select
                    name="parent_eqp"
                    disabled={!(formData.type == "1")}
                    value={formData.parent_eqp}
                    onChange={handleChange}
                    className={
                      "form-select " + errorBorder(filedError.parent_eqp)
                    }
                  >
                    {renderParentEquipment()}
                  </select>
                  {errorText(filedError.parent_eqp)}
                </div>
              </div>

              <div className="row mb-4 row-cols-1 row-cols-lg-3">
                <div className="col">
                  <label className="form-label">
                    Equipment Department<sup className="text-danger">*</sup>
                  </label>
                  <select
                    name="department"
                    value={formData.department}
                    onChange={handleChange}
                    className={
                      "form-select " + errorBorder(filedError.department)
                    }
                  >
                    {renderEquipmentCategory()}
                  </select>
                  {errorText(filedError.department)}
                </div>

                <div className="col">
                  <label className="form-label">
                    Equipment Sub Department<sup className="text-danger">*</sup>
                  </label>
                  <select
                    name="sub_department"
                    value={formData.sub_department}
                    onChange={handleChange}
                    className={
                      "form-select " + errorBorder(filedError.sub_department)
                    }
                  >
                    {renderEquipmentSubDepartment()}
                  </select>
                  {errorText(filedError.sub_department)}
                </div>

                <div className="col">
                  <label className="form-label">
                    Equipment Make<sup className="text-danger">*</sup>
                  </label>
                  <input
                    name="make"
                    type="text"
                    value={formData.make}
                    onChange={handleChange}
                    className={"form-control " + errorBorder(filedError.make)}
                  />
                  {errorText(filedError.make)}
                </div>

                
              </div>

              <div className="row mb-4 row-cols-1 row-cols-lg-3">
              <div className="col">
                  <label className="form-label">
                    Part Number<sup className="text-danger">*</sup>
                  </label>
                  <input
                    type="text"
                    name="part_no"
                    value={formData.part_no}
                    onChange={handleChange}
                    className={
                      "form-control " + errorBorder(filedError.part_no)
                    }
                  />
                  {errorText(filedError.part_no)}
                </div>
                <div className="col">
                  <label className="form-label">
                    Serial Number<sup className="text-danger">*</sup>
                  </label>
                  <input
                    type="text"
                    name="serial_no"
                    value={formData.serial_no}
                    onChange={handleChange}
                    className={
                      "form-control " + errorBorder(filedError.serial_no)
                    }
                  />
                  {errorText(filedError.serial_no)}
                </div>

                <div className="col">
                  <label className="form-label">
                   Item code <sup className="text-danger">*</sup>
                  </label>
                  <input
                    name="item_code"
                    type="text"
                    value={formData.item_code}
                    onChange={handleChange}
                    className={
                      "form-control " + errorBorder(filedError.item_code)
                    }
                  />
                  {errorText(filedError.item_code)}
                </div>
                
              </div>

              <div className="form-panel">
                <div className="row row-cols-1 row-cols-lg-2 mb-3 form-elements">
                  <div className="col-12 form-column form-address-panel">
                    <div className="row mb-2 row-cols-1 row-cols-lg-3 single-col">
                      <div className="col">
                        <label className="form-label">Equipment Name Plate / Equipment Drawing Files</label>
                      </div>
                      <div className="col">
                        <label className="form-label">
                          Documents  (.pdf, .jpg, .jpeg, .png, .gif, .doc, .docx, .xls, .xlsx)
                        </label>
                      </div>
                      <div className="col-1 plus-icon">
                        <span>
                          <i
                            className="bi bi-plus-circle"
                            onClick={() =>
                               addDocument()
                                 
                            }
                          ></i>
                        </span>
                      </div>
                    </div>
                    {renderDocuments( )}
                  </div>
                </div>
              </div>

              <div className="  mb-4 text-end">
                {/* <button
                  type="submit"
                  disabled={disableSubmit}
                  className="btn btn-primary button-style"
                >
                  Submit
                </button> */}
                <Link
                  className="button-style"
                  type="button"
                  to={"/ems-summary"}
                >
                  Cancel
                </Link>
                &nbsp;&nbsp;
                {formData.pk ? (
                  <>
                    <button
                      type="submit"
                      disabled={disableSubmit}
                      className="button-style"
                    >
                      Update
                    </button>
                  </>
                ) : (
                  <button
                    type="submit"
                    disabled={disableSubmit}
                    className="button-style"
                  >
                    {id ? "Edit Equipment" : "Add Equipment"}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default EquipmentAdd;
