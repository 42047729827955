import React, { useState, useEffect } from "react";
import { confirm } from '../../reusable/Confirmation';
import axios from "axios";
import Notification, { errorText, errorBorder }  from "../../reusable/Notification";
import NavigationMenu from "./NavigationMenu";
import { AuthTokenVerify, UpdateBearer } from "../../reusable/AuthUtils";
import { PageToTop } from "../../reusable/CommonUtils";
import { noPermissionTemplate, isPermitted } from "../../reusable/PermissoinUtils";

const BranchDetails = ({history}) => {

    const initForm = {
        street_name: '',
        city: '',
        contact_person: '',
        phone_number: '',
        pincode: '',
        email: '',
        country: '',
        state: '',
    }

    const [ branches, setBranches ] = useState([]);
    const [ alertMessage, setAlertMessage ] = useState({});
    const [ disableSubmit, setDisableSubmit ] = useState(false);
    const [ filedError, setFieldError ] = useState({});
    const [ formData, setFormData ] = useState(initForm);
    const [ countries, setCountries ] = useState([]);
    const [ states, setStates ] = useState([]);
    const [noPermission, setNoPermission] = useState(false);
    
    useEffect(() => {

        AuthTokenVerify(history);

        axios.get("user/company-branch/").then((response) => {
            setBranches(response.data);
            UpdateBearer(history, response);
        }).catch((e) => {
            if(e.response.status === 403) {
                //permission denied
                setNoPermission(true);
            }
        });

        axios.get("common/countries/").then((response) => {
            setCountries(response.data)
        }).catch((error) => {
            //
         });

        PageToTop();
        
    }, []);

    useEffect(() => {
        setStates([]);
        if(!formData.country) return;
        axios.get("common/states/" + formData.country + "/").then((response) => {
            setStates(response.data)
        }).catch((error) => {
            //
         });
    }, [formData.country]);

    const getCountries = () => {
        const content = [<option value="0">-select-</option>];
        countries.map(item => {
            content.push(<option value={item.id}>{item.country}</option>);
        });
        return content;
    };

    const getStates = () => {
        const content = [<option value="0">-select-</option>];
        states.map(item => {
            content.push(<option value={item.id}>{item.state}</option>);
        });
        return content;
    };

    const handleChange = (e) => {

        var targetData = '';

        switch (e.target.name) {
            case "country":
                targetData = parseInt(e.target.value) ? parseInt(e.target.value) : '';
                break;
            case "state":
                targetData = parseInt(e.target.value) ? parseInt(e.target.value) : '';
                break;
            default:
                targetData = e.target.value;
                break;
        }

        setFormData({
            ...formData,
            [e.target.name]: targetData
        });
    };

    const clearAddForm = () => {
        setAlertMessage({});
        setFieldError({});
        setFormData(initForm);
    };

    const editItem = (key) => {
        setAlertMessage({});
        setFieldError({});
        setFormData({...branches[key], ['index']: key});
    };

    const deleteItem = async (key) => {
        const result = await confirm('Are you sure to delete this?');
        if(!result) return;

        clearAddForm();
        const pk = branches[key].pk;
        axios.delete("user/company-branch/" + pk + "/")
            .then((response) => {
                if(response.status === 200){
                    setAlertMessage({type:'success', message:"Branch Details Deleted Successfully!"});
                    const newBranches = [...branches];
                    newBranches.splice(key, 1);
                    setBranches(newBranches);
                }
            })
            .catch((e) => {
                if(e.response.status === 400) {
                    setAlertMessage({type:'danger', message:"Error Occured While Delete!"});
                }
            });
        PageToTop();
    };

    const onSubmit = (e) => {

        e.preventDefault();
        setAlertMessage({});
        setFieldError({});
        setDisableSubmit(true);

        if(formData.pk){
            axios.put("user/company-branch/" + formData.pk + "/", formData)
            .then((response) => {
                if(response.status === 200){
                    const newBranches = [...branches];
                    newBranches[formData.index] = response.data;
                    setBranches(newBranches);
                    setFormData(initForm);
                    setAlertMessage({type:'success', message:"Branch Details Updated Successfully!"});
                    setDisableSubmit(false);
                }
            })
            .catch((e) => {
                if(e.response.status === 400) {
                    setAlertMessage({type:'danger', message:"Error Occured While Submit!"});
                    const dict = {};
                    for (const [key, value] of Object.entries(e.response.data)) {
                        dict[key] = value.join(', ');
                    }
                    setFieldError(dict);
                }

                setDisableSubmit(false);
            });
        }else{
            axios.post("user/company-branch/", formData)
            .then((response) => {
                if(response.status === 200){
                    setBranches([...branches, response.data]);
                    setFormData(initForm);
                    setAlertMessage({type:'success', message:"Branch Details Added Successfully!"});
                    setDisableSubmit(false);
                }
            })
            .catch((e) => {
                if(e.response.status === 400) {
                    setAlertMessage({type:'danger', message:"Error Occured While Submit!"});
                    let dict = {};
                    for (const [key, value] of Object.entries(e.response.data)) {
                        dict[key] = value.join(', ');
                    }
                    setFieldError(dict);
                }
                setDisableSubmit(false);
            });
        }
        PageToTop();
    };

    const renderBody = () => {
        const content = [];

        branches.map( (item, key) => {
            content.push(<tr key={key} >
                <td>{ item.street_name },
                <br/>{ item.city } - { item.pincode },
                <br/>{ item.state_read },
                <br/>{ item.country_read }</td>
                <td>{ item.phone_number }</td>
                <td>{ item.contact_person }</td>
                <td>{ item.email }</td>
                <td className="text-center">
                    {(() => {
                        if(isPermitted('site_my_profile_branch_details_modify_branch')){
                            return <button onClick={() => editItem(key) } type="button" className="btn btn-primary">
                                <i className="bi bi-pencil-fill"></i>
                            </button>
                        }

                        return <button  title="No permission"  type="button" className="btn btn-primary action-disabled">
                            <i className="bi bi-pencil-fill"></i>
                        </button>
                        
                    })()}

                    
                    {(() => {
                        if(isPermitted('site_my_profile_branch_details_delete_branch')){
                            return <button onClick={() => deleteItem(key) } type="button" className="btn btn-danger ms-1">
                                <i className="bi bi-trash-fill"></i>
                            </button>
                        }
                        
                        return <button  title="No permission"  type="button" className="btn btn-danger ms-1 action-disabled">
                            <i className="bi bi-trash-fill"></i>
                        </button>
                    })()}
                </td>
            </tr>);
        });

        if(content.length < 1){
            content.push(<tr>
                <td colSpan="5" className="text-center">No records found</td>
            </tr>);
        }

        return content;
    };

    if(noPermission){
        return (
        <div className="container">
            <div className="form-wrapper">
                <NavigationMenu item={ 3 }  />
                <div className="form-panel-wrapper">
                    { noPermissionTemplate() }
                </div>
            </div>
        </div>
        )
    }

    return (
        <div className="container">
            <div className="form-wrapper">
                <NavigationMenu item={ 3 } />
                <div className="form-panel-wrapper">
                    <Notification {...alertMessage}/>
                    <fieldset disabled={!isPermitted('site_my_profile_branch_details_add_branch') && !formData.pk}>
                        <form onSubmit={ onSubmit }>
                            <div className="form-panel">
                                <div className="row row-cols-1 row-cols-lg-2 mb-3 form-elements">
                                    <div className="col-12 form-column form-address-panel">
                                        <div className="row mb-4 row-cols-1 row-cols-lg-3">
                                            <div className="col">
                                                <label className="form-label">Street Name <sup className="text-danger">*</sup></label>
                                                <input type="text" name="street_name" value={formData.street_name} className={"form-control " + errorBorder(filedError.street_name) } onChange={handleChange} />
                                                { errorText(filedError.street_name) }
                                            </div>
                                            <div className="col">
                                                <label className="form-label">City <sup className="text-danger">*</sup></label>
                                                <input type="text" name="city" value={formData.city} className={"form-control " + errorBorder(filedError.city) } onChange={handleChange} />
                                                { errorText(filedError.city) }
                                            </div>
                                            <div className="col">
                                                <label className="form-label">Country <sup className="text-danger">*</sup></label>
                                                <select className={"form-select " + errorBorder(filedError.country) } value={formData.country} name="country" onChange={handleChange}>
                                                    { getCountries() }
                                                </select>
                                                { errorText(filedError.country) }
                                            </div>
                                        </div>
                                        <div className="row mb-4 row-cols-1 row-cols-lg-3">
                                            <div className="col">
                                                <label className="form-label">State <sup className="text-danger">*</sup></label>
                                                <select className={"form-select " + errorBorder(filedError.state) } value={formData.state} name="state" onChange={handleChange}>
                                                    { getStates() }
                                                </select>
                                                { errorText(filedError.state) }
                                            </div>
                                            <div className="col">
                                                <label className="form-label">Pincode <sup className="text-danger">*</sup></label>
                                                <input type="text" name="pincode" value={formData.pincode} className={"form-control " + errorBorder(filedError.pincode) } onChange={handleChange} />
                                                { errorText(filedError.pincode) }
                                            </div>
                                            <div className="col">
                                                <label className="form-label">Phone Number <sup className="text-danger">*</sup></label>
                                                <input type="text" name="phone_number" value={formData.phone_number} className={"form-control " + errorBorder(filedError.phone_number) } onChange={handleChange} />
                                                { errorText(filedError.phone_number) }
                                            </div>
                                        </div>
                                        <div className="row row-cols-1 row-cols-lg-3">
                                            <div className="col">
                                                <label className="form-label">Contact person <sup className="text-danger">*</sup></label>
                                                <input type="text" name="contact_person" value={formData.contact_person} className={"form-control " + errorBorder(filedError.contact_person) } onChange={handleChange} />
                                                { errorText(filedError.contact_person) }
                                            </div>
                                            <div className="col">
                                                <label className="form-label">Email <sup className="text-danger">*</sup></label>
                                                <input type="text" name="email" value={formData.email} className={"form-control " + errorBorder(filedError.email) } onChange={handleChange} />
                                                { errorText(filedError.email) }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 text-end">
                            {(() => {
                                if(formData.pk){
                                    return <>
                                        <a type="button" onClick={() => clearAddForm()} className="button-style">Cancel</a>&nbsp;&nbsp;
                                        <button type="submit" disabled={ disableSubmit } className="button-style">Update</button>
                                    </>
                                }else{
                                    if(isPermitted('site_my_profile_branch_details_add_branch')){
                                        return <button type="submit" disabled={ disableSubmit } className="button-style">Add Branch</button>
                                    }
                                    return <button title="No permission" type="button" className="button-style action-disabled">Add Branch</button>
                                }
                                
                            })()}
                            </div>
                        </form>
                    </fieldset>
                    <div className="table-responsive row">
                        <table className="table align-middle table-bordered table-design">
                            <thead>
                            <tr>
                                <td>Address</td>
                                <td>Phone Number</td>
                                <td>Contact Person</td>
                                <td>Email</td>
                                <td>Action</td>
                            </tr>
                            </thead>
                            <tbody>
                                { renderBody() }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BranchDetails;