
const PageNotFound = () => {

    return (
        <main id="main">
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" style={{paddingTop : '20px'}}>
                            <div className="error-template">
                                <h1>
                                    Oops!</h1>
                                <h2>
                                    404 Not Found</h2>
                                <div className="error-details">
                                    Sorry, an error has occured, Requested page not found!
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
    
}

export default PageNotFound